import { Button, Tooltip, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { apiService } from "@services/api";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { converterJsonToFormDataTermoApreensaoLiberacao } from "../../../functions/converterJsonToFormDataTermoApreensaoLiberacao";
import { useActionsTAL } from "../../../hooks/useActionsTAL";
import { Buffer } from "buffer";
import { IAnexos } from "@interfaces/IAnexos";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { useTAL } from "../../../hooks/useTAL";

export function ButtonEnviarBackupTermoApreensaoLiberacao() {
  const { atualizarTermo, adicionarTermoByFiltro, listaTermos } =
    useActionsTAL();
  const { getAnexo } = useTAL();

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeUpload = async (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const keyMessage = "termo";

    try {
      const anexo = await getAnexosFormatadosBase64(info.fileList);
      const json = Buffer.from(anexo[0].arquivo, "base64").toString();

      //SLICE NA POSICAO 14 PARA REMOVER CARACTERES ESPECIAIS
      let jsonObject = JSON.parse(json.slice(14));

      if (!jsonObject.tif.id) {
        const params = new URLSearchParams();
        params.append("numero", jsonObject.tif.numero);
        await apiService.tif.listar(params.toString()).then((data) => {
          if (data.content.length > 0) {
            jsonObject = {
              ...jsonObject,
              tif: {
                ...jsonObject.tif,
                id: data.content[0].id,
              },
            };
          }
        });
      }

      if (!jsonObject.tif.id) {
        openNotification({
          type: "info",
          message: `Não foi possivel enviar Termo de ${jsonObject.tipoTermo.toLowerCase()}.`,
          description:
            "Favor sincronizar o TIF antes de salvar esse termo. Caso já tenha sincronizado, procure o administrador do sistema.",
        });

        return;
      }

      const anexosFile = jsonObject.anexos.map((anexo: IAnexos) => {
        return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
      });

      message.loading({
        duration: 0,
        key: keyMessage,
        content: "Enviando Termo...",
      });

      await apiService.termoApreensaoLiberacao
        .enviar(
          converterJsonToFormDataTermoApreensaoLiberacao(jsonObject, anexosFile)
        )
        .then(async (res) => {
          const termoInApp = listaTermos.find(
            (termo) => termo.numero === res.data.numero
          );

          if (termoInApp && res.data) {
            atualizarTermo({ ...termoInApp, id: res.data.id });
            message.destroy(keyMessage);
            openNotification({
              type: "success",
              message: "Termo de Apreensão/Liberação enviado com sucesso",
            });
            return;
          }

          const responseTermoApreensaoLiberacao =
            await apiService.termoApreensaoLiberacao.byId(res.data.id);

          const dadosAnexos = responseTermoApreensaoLiberacao.anexos.map(
            (anexo) => {
              return {
                nomeUpload: anexo.nomeUpload,
                nomeArquivo: anexo.nomeArquivo,
              };
            }
          );

          if (dadosAnexos.length <= 0) {
            adicionarTermoByFiltro({
              ...responseTermoApreensaoLiberacao,
              anexos: [],
            });
          } else {
            const anexos = await getAnexo(dadosAnexos);
            // console.log(anexos);

            adicionarTermoByFiltro({
              ...responseTermoApreensaoLiberacao,
              anexos: anexos,
            });
          }
          message.destroy(keyMessage);
          openNotification({
            type: "success",
            message: "Termo de Apreensão/Liberação enviado com sucesso",
          });
        })
        .catch((erro) => {
          message.destroy(keyMessage);
          openNotification({
            type: "error",
            message:
              "Não foi possivel enviar backup do Termo de Apreensão/Liberação",
            description: erro.response.data.detail,
          });
        });
    } catch (error) {
      openNotification({
        type: "error",
        message:
          "Não foi possivel enviar backup do Termo de Apreensão/Liberação",
        description:
          "Por favor verifique se o backup foi selecionado corretamente, caso o erro persista entre em contato com o suporte.",
      });
    }
  };

  return (
    <>
      <Tooltip
        title="Enviar backup do Termo de Apreensão/Liberação"
        placement="left"
      >
        <Upload
          customRequest={dummyRequest}
          onChange={handleChangeUpload}
          fileList={[]}
        >
          <Button disabled={!navigator.onLine}>
            <UploadOutlined />
          </Button>
        </Upload>
      </Tooltip>
    </>
  );
}

import { IFormAtividades } from "@interfaces/IFormAtividades";
import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { IRespostaAtividade } from "@interfaces/IRespostaAtividade";
import { ordenarPerguntasAtividade } from "./ordenarPerguntasAtividade";

export const estruturarPerguntasImpressaoTif = (
  form: IFormAtividades,
  respostas: IRespostaAtividade[]
) => {
  const respostasOrganizadas: any[] = [];

  for (let i = 0; i < respostas.length; i++) {
    if (
      respostasOrganizadas.find(
        (res) => res.atividade.id === respostas[i].atividade.id
      )
    ) {
      continue;
    } else {
      const auxRespostas: any[] = [];

      for (let j = 0; j < respostas.length; j++) {
        if (respostas[j].atividade.id === respostas[i].atividade.id) {
          auxRespostas.push(respostas[j]);
        }
      }

      const auxAtv = {
        ...form.atividade,
        questoes: form.atividade.questoes.filter((quest) => {
          if (
            auxRespostas.find((res) => res.pergunta.id === quest.pergunta.id)
          ) {
            return quest;
          }

          return null;
        }),
      };

      respostasOrganizadas.push({
        atividade: auxAtv,
        respostas: auxRespostas,
      });
    }
  }

  let perguntasOrdenadas = ordenarPerguntasAtividade(
    respostasOrganizadas[0].atividade.questoes
  );

  const perguntasComNumeracao: IPerguntaAtividade[] = [];

  //PERGUNTAS INDEPENDENTES
  perguntasOrdenadas.forEach((perg) => {
    if (perg.pergunta.antecessora === null) {
      perguntasComNumeracao.push({
        ...perg,
        indPergunta: `${perguntasComNumeracao.length + 1}`,
      });
    }
  });

  //VERIFICANDO SE SO POSSUI PERGUNTAS INDEPENDENTES NA LISTAGEM EX:1,2
  if (perguntasOrdenadas.length === perguntasComNumeracao.length) {
    return perguntasComNumeracao;
  }
  // perguntasOrdenadas = ordenarPerguntasAtividade(perguntasOrdenadas);

  //PEGANDO PERGUNTAS DEPENDENTES EX: 1.1, 1.2
  for (let i = 0; i < perguntasOrdenadas.length; i++) {
    if (!perguntasOrdenadas[i].pergunta.antecessora) {
      continue;
    } else {
      const isPresenteNaListaPerguntasNumeradas = perguntasComNumeracao.some(
        (perg) =>
          perg.pergunta.id === perguntasOrdenadas[i].pergunta.antecessora.id
      );

      if (isPresenteNaListaPerguntasNumeradas) {
        const perguntaPai = perguntasComNumeracao.find(
          (perg) =>
            perg.pergunta.id === perguntasOrdenadas[i].pergunta.antecessora.id
        );

        const qntdPerguntasArmazenadas = perguntasComNumeracao.filter(
          (perg) => perg.pergunta.antecessora?.id === perguntaPai?.pergunta.id
        ).length;

        perguntasComNumeracao.push({
          ...perguntasOrdenadas[i],
          indPergunta: `${perguntaPai?.indPergunta}.${
            qntdPerguntasArmazenadas + 1
          }`,
        });
      }
    }
  }

  if (perguntasOrdenadas.length === perguntasComNumeracao.length) {
    return perguntasComNumeracao;
  }

  // perguntasOrdenadas = ordenarPerguntasAtividade(perguntasOrdenadas);
  //PEGANDO PERGUNTAS DEPENDENTES EX: 1.1.1, 1.1.2
  for (let i = 0; i < perguntasOrdenadas.length; i++) {
    if (!perguntasOrdenadas[i].pergunta.antecessora) {
      continue;
    } else {
      const isPresenteNaListaPerguntasNumeradas = perguntasComNumeracao.some(
        (perg) =>
          perg.pergunta.id === perguntasOrdenadas[i].pergunta.antecessora.id
      );

      if (isPresenteNaListaPerguntasNumeradas) {
        const perguntaPai = perguntasComNumeracao.find(
          (perg) =>
            perg.pergunta.id === perguntasOrdenadas[i].pergunta.antecessora.id
        );

        perguntasComNumeracao.push({
          ...perguntasOrdenadas[i],
          indPergunta: `${perguntaPai?.indPergunta}.${
            perguntasComNumeracao.length + 1
          }`,
        });
      }
    }
  }

  if (perguntasOrdenadas.length === perguntasComNumeracao.length) {
    return perguntasComNumeracao;
  }

  return perguntasComNumeracao;
};

import { Modal, Select } from "antd";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { openNotification } from "@components/Notifications";
import { useDispatch } from "react-redux";
import { termoColetaAction } from "@store/actions/termoColetaAction";

interface IModalEditDestinoTermoColetaProps {
  isOpen: boolean;
  onClose: () => void;
  termoSelected: ITermoColetaAmostras | null;
}

type Destino = "" | "AMBOS" | "LASGAL";

export function ModalEditDestinoTermoColeta({
  isOpen,
  onClose,
  termoSelected,
}: IModalEditDestinoTermoColetaProps) {
  const [destino, setDestino] = useState<Destino>("");
  const appDispatch = useDispatch();
  const { updateAssinatura } = termoColetaAction;

  const handleSubmit = async () => {
    if (destino !== termoSelected?.destino && termoSelected?.id) {
      const item = {
        destino: destino,
        statusAssinatura: termoSelected.statusAssinatura,
        assinaturaFiscalizado: termoSelected.assinaturaFiscalizado,
        nomeAssinante: termoSelected.nomeAssinante,
        funcaoAssinante: termoSelected.funcaoAssinante,
        cpfAssinante: termoSelected.cpfAssinante,
      };

      appDispatch(updateAssinatura(item, termoSelected.id, termoSelected));

      openNotification({
        type: "success",
        message: "Destino atualizado com sucesso!",
      });
    }

    onClose();
  };

  useEffect(() => {
    if (
      termoSelected?.destino === "AMBOS" ||
      termoSelected?.destino === "LASGAL"
    ) {
      setDestino(termoSelected.destino);
    }
    //eslint-disable-next-line
  }, [isOpen]);

  return (
    <Modal
      title={
        <h1 className={styles["title-modal"]}>
          Editar Termo de Coleta {termoSelected?.termoColetaAmostra}
        </h1>
      }
      open={isOpen}
      onCancel={onClose}
      afterClose={onClose}
      onOk={handleSubmit}
      cancelText="Cancelar"
      okText="Salvar"
      cancelButtonProps={{
        danger: true,
      }}
    >
      <div className={styles.container}>
        <label className={styles["label-destino"]}>Destino:</label>
        <Select
          className={styles["select-destino"]}
          onChange={(e) => setDestino(e)}
          value={destino}
          options={[
            {
              label: "LASGAL",
              value: "LASGAL",
            },
            {
              label: "AMBOS",
              value: "AMBOS",
            },
          ]}
        />
      </div>
    </Modal>
  );
}

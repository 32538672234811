import { Button, Checkbox, DatePicker, Form, FormInstance, Modal } from "antd";
import { ESPECIFICACOES } from "../../constants/ESPECIFICACOES";
import { IFormAtividades } from "@interfaces/IFormAtividades";
import styles from "./style.module.css";
import { atividadeAction } from "@store/actions/atividadeAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { openNotification } from "@components/Notifications";

interface IModalEditarAtividadeProps {
  isOpen: boolean;
  form: FormInstance;
  handleClose: () => void;
  atividadeSelected: IFormAtividades;
  isSelectNotification: boolean;
  setIsSelectNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalEditarAtividade({
  isOpen,
  form,
  handleClose,
  atividadeSelected,
  isSelectNotification,
  setIsSelectNotification,
}: IModalEditarAtividadeProps) {
  const appDispatch = useDispatch();
  const { updateAtividade } = atividadeAction;

  const handleSubmit = (values: any) => {
    const especificacoes = values.especificacoes.join(", ") ?? "";
    const prazo = values.prazo
      ? moment(values.prazo).locale("pt-br").format().slice(0, 10)
      : "";

    const atvEditada: IFormAtividades = {
      ...atividadeSelected,
      especificacoes,
      prazo,
    };

    appDispatch(updateAtividade(atvEditada));

    openNotification({
      type: "success",
      message: "Atividade editada com sucesso!",
    });

    handleClose();
  };

  const errorSubmit = () => {};

  const handleChangeEspecificacao = (checkedValue: CheckboxValueType[]) => {
    if (checkedValue.some((especificacao) => especificacao === "Notificação")) {
      setIsSelectNotification(true);
    } else {
      form.setFieldValue("prazo", "");
      setIsSelectNotification(false);
    }
  };

  return (
    <Form
      form={form}
      id="editar-atividade-form"
      onFinish={handleSubmit}
      onFinishFailed={errorSubmit}
    >
      <Modal
        title={
          <>
            <h1 className={styles["titulo-modal"]}>
              Editar Atividade: {atividadeSelected.atividade.nome}
            </h1>
            <h3 className={styles["subtitulo-modal"]}>
              Programa: {atividadeSelected.programa.label}
            </h3>
          </>
        }
        width={1000}
        maskClosable={false}
        keyboard={true}
        open={isOpen}
        onCancel={handleClose}
        footer={[
          <Button key="cancelar" onClick={handleClose}>
            Cancelar
          </Button>,
          <Button
            key="salvar"
            type="primary"
            htmlType="submit"
            form="editar-atividade-form"
          >
            Salvar Edição
          </Button>,
        ]}
      >
        <Form.Item name="especificacoes" label="Especificações">
          <Checkbox.Group
            disabled={atividadeSelected.atividade.id === 41}
            options={ESPECIFICACOES}
            onChange={handleChangeEspecificacao}
          />
        </Form.Item>

        <Form.Item
          name="prazo"
          label="Prazo p/ adequação: "
          rules={[
            {
              required: isSelectNotification,
              message: "Selecione um prazo",
            },
          ]}
        >
          <DatePicker
            placeholder="Prazo"
            format={"DD/MM/YYYY"}
            disabled={!isSelectNotification}
          />
        </Form.Item>
      </Modal>
    </Form>
  );
}

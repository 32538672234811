import { Button, Checkbox, FormInstance, Input, Typography } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import { IAssinante } from "@interfaces/IAssinante";
import { masks } from "@functions/mascaras";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import ReactSignatureCanvas from "react-signature-canvas";
import { IServidorForm } from "@interfaces/IServidorForm";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { ModalAssinatura } from "@components/ModalAssinatura";
import { useServidores } from "@hooks/useServidores";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { INITIAL_VALUES_ASSINATURA } from "@constants/INITIAL_VALUES_ASSINATURA";

interface IAssinaturasTIFProps {
  fiscalizado: IAssinante;
  setFiscalizado: React.Dispatch<React.SetStateAction<IAssinante>>;
  motivoNaoAssinatura: CheckboxValueType[];
  setMotivoNaoAssinatura: React.Dispatch<
    React.SetStateAction<CheckboxValueType[]>
  >;
  servidores: IServidorForm[];
  setServidores: React.Dispatch<React.SetStateAction<IServidorForm[]>>;
  form: FormInstance;
  ativarModalInformarNumTif: () => void;
}

export function AssinaturasTIF({
  setFiscalizado,
  fiscalizado,
  motivoNaoAssinatura,
  setMotivoNaoAssinatura,
  servidores,
  setServidores,
  form,
  ativarModalInformarNumTif,
}: IAssinaturasTIFProps) {
  const { servidoresSelecionados } = useServidores();
  const refAssinante = useRef<any>(null);
  const refServidores = useRef<any>(null);
  const { cpfCnpjMask } = masks;
  const [isOpenModalFiscalizado, setIsOpenModalFiscalizado] = useState(false);
  const [isOpenMOdalServidores, setIsOpenModalServidores] = useState(false);
  const [pointsArray, setPointsArray] = useState(null);
  const [idServidorSelected, setIdServidorSelected] = useState<number>();

  const handleCloseModalServidores = async () => {
    setIsOpenModalServidores(false);

    const aux = servidores.map((serv) => {
      if (serv.servidor.id === idServidorSelected) {
        return {
          ...serv,
          assinatura: refServidores.current.isEmpty()
            ? ""
            : refServidores.current.getTrimmedCanvas().toDataURL("image/png"),
        };
      }

      return serv;
    });

    setServidores(aux);

    cleanSignatureFields(refServidores.current);
  };

  const cleanSignatureFields = (
    signature: ReactSignatureCanvas | null | undefined
  ) => {
    if (signature !== null && signature !== undefined) {
      signature.clear();
    }
  };

  const handleChangeFiscalizadoAusente = (
    checkedValue: CheckboxValueType[]
  ) => {
    setMotivoNaoAssinatura(checkedValue);

    if (checkedValue[0] === "RECUSADO") {
      cleanSignatureFields(fiscalizado.assinatura);
    }

    if (checkedValue[0] === "AUSENTE") {
      cleanSignatureFields(fiscalizado.assinatura);
      setFiscalizado(INITIAL_VALUES_ASSINATURA);
      return;
    }

    setFiscalizado({
      ...fiscalizado,
      nomeAssinante: form.getFieldValue("fiscalizadoNome"),
      cpfAssinante: form.getFieldValue("fiscalizadoCpf"),
    });

    if (checkedValue[0] === ASSINATURA_FISICA) {
      ativarModalInformarNumTif();
      cleanSignatureFields(fiscalizado.assinatura);
      const auxServ = servidores.map((serv) => {
        return {
          ...serv,
          assinatura: "",
        };
      });

      setServidores(auxServ);

      return;
    }
  };

  const handleOpenModalServidores = () => {
    setIsOpenModalServidores(true);
    cleanSignatureFields(refServidores.current);
    setTimeout(() => {
      if (pointsArray) {
        refServidores.current.fromData(pointsArray);
      }
    });
  };

  const handleOpenModal = (assinante: string) => {
    if (assinante === "FISCALIZADO") {
      setIsOpenModalFiscalizado(true);
    }
  };

  const handleCloseModal = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsOpenModalFiscalizado(false);
    setFiscalizado({
      ...fiscalizado,
      assinatura: refAssinante.current,
    });
  };

  useEffect(() => {
    const aux = servidoresSelecionados.map((serv) => {
      return {
        assinatura: "",
        servidor: serv.servidor,
      };
    });

    setServidores(aux);
  }, [servidoresSelecionados]);

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          padding: "50px 15px 15px 15px",
        }}
      >
        <Checkbox.Group
          style={{
            display: "flex",
            flexDirection: "row",
            width: 250,
          }}
          onChange={handleChangeFiscalizadoAusente}
          value={motivoNaoAssinatura}
        >
          <Checkbox value={ASSINATURA_FISICA}>Assinatura Física</Checkbox>
        </Checkbox.Group>
      </div>
      <AssinaturaContainer>
        {servidores.map((serv, ind) => {
          return (
            <div className="servidor-signature-form" key={ind}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="container-img-assinatura">
                  {serv.assinatura && (
                    <img
                      src={serv.assinatura}
                      alt={`assinatura servidor: ${serv.servidor.nome}`}
                      style={{
                        width: "90%",
                        height: "90%",
                      }}
                    />
                  )}
                </div>

                <Typography style={{ textAlign: "center" }}>
                  Assinatura {serv.servidor.nome}
                </Typography>
                <Button
                  disabled={motivoNaoAssinatura[0] === ASSINATURA_FISICA}
                  type="primary"
                  onClick={() => {
                    handleOpenModalServidores();
                    setIdServidorSelected(serv.servidor.id);
                  }}
                >
                  Clique aqui para assinar
                </Button>
              </div>
            </div>
          );
        })}

        <div className="fiscalizado-signature-form">
          <div>
            <div className="container-img-assinatura">
              {fiscalizado.assinatura && (
                <img
                  src={fiscalizado.assinatura
                    .getTrimmedCanvas()
                    .toDataURL("image/png")}
                  alt="assinatura Fiscalizado"
                  style={{
                    width: "90%",
                    height: "90%",
                  }}
                />
              )}
            </div>

            <Typography style={{ textAlign: "center" }}>
              Assinatura Fiscalizado
            </Typography>
            <Button
              disabled={motivoNaoAssinatura.length > 0}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                handleOpenModal("FISCALIZADO");
              }}
            >
              Clique aqui para assinar
            </Button>

            <div style={{ display: "flex", paddingTop: 10 }}>
              <Checkbox.Group
                onChange={handleChangeFiscalizadoAusente}
                value={motivoNaoAssinatura}
              >
                <Checkbox value="AUSENTE">Ausente</Checkbox>
              </Checkbox.Group>

              <Checkbox.Group
                value={motivoNaoAssinatura}
                onChange={handleChangeFiscalizadoAusente}
              >
                <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
              </Checkbox.Group>
            </div>

            <div
              className="dados-assinante-tif"
              style={{
                display: "flex",
                gap: 10,
                maxWidth: 600,
              }}
            >
              <div>
                <span>Nome:</span>
                <Input
                  disabled={
                    motivoNaoAssinatura.length > 0 &&
                    !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                      motivoNaoAssinatura[0].toString()
                    )
                  }
                  name="nome_assinante"
                  value={fiscalizado?.nomeAssinante}
                  onChange={(e) =>
                    setFiscalizado({
                      ...fiscalizado,
                      nomeAssinante: e.target.value,
                    })
                  }
                />
              </div>

              <div>
                <span>CPF:</span>
                <Input
                  disabled={
                    motivoNaoAssinatura.length > 0 &&
                    !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                      motivoNaoAssinatura[0].toString()
                    )
                  }
                  name="cpf_assinante"
                  value={fiscalizado?.cpfAssinante}
                  onChange={(e) => {
                    setFiscalizado({
                      ...fiscalizado,
                      cpfAssinante: cpfCnpjMask(e.target.value),
                    });
                  }}
                />
              </div>

              <div>
                <span>Função:</span>
                <Input
                  disabled={
                    motivoNaoAssinatura.length > 0 &&
                    !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                      motivoNaoAssinatura[0].toString()
                    )
                  }
                  name="funcao_assinante"
                  value={fiscalizado?.funcaoAssinante}
                  onChange={(e) =>
                    setFiscalizado({
                      ...fiscalizado,
                      funcaoAssinante: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </AssinaturaContainer>

      <ModalAssinatura
        isOpen={isOpenModalFiscalizado}
        handleClose={handleCloseModal}
        cleanSignature={cleanSignatureFields}
        refAssinante={refAssinante}
      />

      {/**MODAL SERVIDORES */}

      <ModalAssinatura
        isOpen={isOpenMOdalServidores}
        handleClose={handleCloseModalServidores}
        cleanSignature={cleanSignatureFields}
        refAssinante={refServidores}
        nomeAssinante={
          servidores.find((serv) => serv.servidor.id === idServidorSelected)
            ?.servidor.nome || "Servidor"
        }
      />
    </Fragment>
  );
}

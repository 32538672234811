import { Button, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import styles from "./style.module.css";
import { ITID } from "@interfaces/ITid";
import { apiService } from "@services/api";
import { HeaderImpressaoTID } from "./components/HeaderImpressaoTID";
import { BodyImpressaoTID } from "./components/BodyImpressaoTID";
import { FooterImpressaoTID } from "./components/FooterImpressaoTID";
import { IAnexos } from "@interfaces/IAnexos";
import { TIPO_IMAGENS } from "@constants/TIPO_IMAGENS";
import { ImagesImpressaoTID } from "./components/ImagesImpressaoTID";
import { LoadingOutlined } from "@ant-design/icons";
import { TitleTermo } from "@styles/TitleTermo";
import { useActionsTID } from "../../hooks/useActionsTID";

export function ViewImpressaoTID() {
  const { termoId } = useParams();
  const navigate = useNavigate();
  const { listaTIDs: listaTermos } = useActionsTID();
  const componentRef = useRef<any>(null);
  const [anexosImages, setAnexosImages] = useState<IAnexos[]>([]);
  const [termoSelected, setTermoSelected] = useState<ITID>();

  const [loading, setLoading] = useState(false);

  const procurarTermoPorId = async (id: number) => {
    setLoading(true);
    const tid = listaTermos.filter((termo) => termo.id === id)[0];

    if (tid) {
      const anexosTypeImage =
        tid.anexos.filter((anexo) =>
          TIPO_IMAGENS.includes(
            anexo.nomeArquivo.split(".")[
              anexo.nomeArquivo.split(".").length - 1
            ]
          )
        ) || [];

      setTermoSelected(tid);
      setAnexosImages(anexosTypeImage);
    } else {
      const aux = await apiService.tid.byId(id);

      if (aux.anexos.length > 0) {
        const dadosAnexos = aux.anexos.map((anexo) => {
          return {
            nomeUpload: anexo.nomeUpload,
            nomeArquivo: anexo.nomeArquivo,
          };
        });

        let dataUrl: IAnexos[] = [];
        dadosAnexos.forEach(async (infoAnexo) => {
          const { data } = await apiService.tid.getAnexo(infoAnexo.nomeUpload);

          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = function () {
            let base64data = reader.result;

            if (
              base64data &&
              base64data.toString() &&
              TIPO_IMAGENS.includes(
                infoAnexo.nomeArquivo.split(".")[
                  infoAnexo.nomeArquivo.split(".").length - 1
                ]
              )
            ) {
              dataUrl = [
                ...dataUrl,
                {
                  nomeArquivo: infoAnexo.nomeArquivo,
                  nomeUpload: infoAnexo.nomeUpload,
                  arquivo: base64data.toString(),
                },
              ];

              setAnexosImages(dataUrl);
            }
          };
        });
      }

      setTermoSelected(aux);
    }

    setLoading(false);
  };

  const procurarTermoPorNumero = (numTermo: string | undefined) => {
    setLoading(true);
    const tid = listaTermos.find(
      (tif) => tif.numero === numTermo?.replaceAll("_", "/")
    );

    setTermoSelected(tid);
    const anexosTypeImage =
      tid?.anexos.filter((anexo) =>
        TIPO_IMAGENS.includes(
          anexo.nomeArquivo.split(".")[anexo.nomeArquivo.split(".").length - 1]
        )
      ) || [];

    setAnexosImages(anexosTypeImage);
    setLoading(false);
  };

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: "@page { size: A4 !important;}",
    documentTitle: `Termo ${termoSelected?.numero}`,
    suppressErrors: true,
    preserveAfterPrint: true,
  });

  useEffect(() => {
    const isID = parseInt(termoId || "");

    if (termoId?.match("_")) {
      procurarTermoPorNumero(termoId);
    } else {
      procurarTermoPorId(isID);
    }
    //eslint-disable-next-line
  }, []);

  if (loading && !termoSelected) {
    return (
      <>
        <div className={styles["container-loading"]}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </>
    );
  }

  return (
    <>
      <TitleTermo>
        Impressão Termo{" "}
        {termoSelected?.tipoTermo === "INTERDICAO"
          ? "Interdição"
          : "Desinterdição"}
      </TitleTermo>

      {termoSelected && (
        <>
          <div className={styles["container-button"]}>
            <Button type="primary" onClick={() => handlePrint()}>
              Imprimir
            </Button>

            <Button onClick={() => navigate("/TID/listagem")}>Voltar</Button>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={styles["container-impressao"]} ref={componentRef}>
              <table style={{ width: 780 }} className="tid-impressao-font">
                <HeaderImpressaoTID isFirstPage tid={termoSelected} />
                <BodyImpressaoTID tid={termoSelected} />
                <FooterImpressaoTID tid={termoSelected} />
              </table>

              {anexosImages.map((anexo, ind) => {
                return (
                  <ImagesImpressaoTID
                    key={ind}
                    termo={termoSelected}
                    anexo={anexo}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}

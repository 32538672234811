import { useDispatch, useSelector } from "react-redux";
import { IEmpresaAgrotoxico } from "@interfaces/IEmpresaAgrotoxico";
import { empresaAgrotoxicoAction } from "@store/actions/empresaAgrotoxicoAction";
import { getEmpresasAgrotoxicos } from "@store/feature/empresaAgrotoxicoSlice";

export const useEmpresasAgrotoxico = () => {
  const { addEmpresas, removeEmpresas } = empresaAgrotoxicoAction;
  const appDispatch = useDispatch();
  const listaEmpresasAgrotoxico = useSelector(getEmpresasAgrotoxicos);

  const adicionarEmpresasAgrotoxico = (
    empresas: IEmpresaAgrotoxico[],
    persistedAt: number
  ) => {
    appDispatch(addEmpresas(empresas, persistedAt));
  };

  const removerEmpresasAgrotoxico = () => {
    appDispatch(removeEmpresas());
  };

  return {
    listaEmpresasAgrotoxico,
    adicionarEmpresasAgrotoxico,
    removerEmpresasAgrotoxico,
  };
};

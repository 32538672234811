import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";

const ordenarPerguntas = (perguntas: IPerguntaTermoVistoria[]) => {
  return perguntas.sort((a, b) => {
    if (a.order > b.order) return 1;
    return -1;
  });
};

export const getPerguntasEnumeradas = (
  termoVistoria: ITermoVistoria
): IPerguntaTermoVistoria[] => {
  try {
    if (!termoVistoria.formulario || !termoVistoria.formulario.ativo) return [];
    let perguntas: IPerguntaTermoVistoria[] = [];

    const idPerguntasRespondidas = termoVistoria.respostas.map((pergunta) => {
      return pergunta.pergunta.id;
    });

    let perguntasIndependentes =
      termoVistoria.formulario.formularioPerguntas.filter(
        (pergunta) => !pergunta.pergunta.antecessora
      );

    // ordenar perguntas
    perguntasIndependentes = ordenarPerguntas(perguntasIndependentes);

    //FILTRAR PERGUNTAS NAO RESPONDIDAS
    perguntasIndependentes = perguntasIndependentes.filter((pergunta) => {
      return idPerguntasRespondidas.includes(pergunta.pergunta.id);
    });

    //ENUMERANDO PERGUNTAS INDEPENDENTES
    perguntas = perguntasIndependentes.map((res, ind) => {
      return {
        ...res,
        indPergunta: (ind + 1).toString(),
      };
    });

    if (perguntas.length === termoVistoria.respostas.length) return perguntas;

    //PEGAR PERGUNTAS DEPENDENTES
    let perguntasRestantes =
      termoVistoria.formulario.formularioPerguntas.filter(
        (res) =>
          !perguntas.find((value) => value.pergunta.id === res.pergunta.id)
      );

    perguntas.forEach((value) => {
      let pergDependentes = perguntasRestantes.filter(
        (aux) => aux.pergunta.antecessora?.id === value.pergunta.id
      );

      if (pergDependentes.length > 0) {
        pergDependentes = ordenarPerguntas(pergDependentes);

        //FILTRAR PERGUNTAS NAO RESPONDIDAS
        pergDependentes = pergDependentes.filter((pergunta) => {
          return idPerguntasRespondidas.includes(pergunta.pergunta.id);
        });

        const resultado = pergDependentes.map((aux, ind) => {
          return {
            ...aux,
            indPergunta: `${value.indPergunta}.${ind + 1}`,
          };
        });

        perguntas = ordenarPerguntas(perguntas.concat(resultado));
      }
    });

    if (perguntas.length === termoVistoria.respostas.length) return perguntas;

    //PEGAR PERGUNTAS DEPENDENTES DAS DEPENDENTES
    perguntasRestantes = termoVistoria.formulario.formularioPerguntas.filter(
      (res) => !perguntas.find((value) => value.pergunta.id === res.pergunta.id)
    );

    perguntas.forEach((value) => {
      let pergDependentes = perguntasRestantes.filter(
        (aux) => aux.pergunta.antecessora?.id === value.pergunta.id
      );

      if (pergDependentes.length > 0) {
        pergDependentes = ordenarPerguntas(pergDependentes);

        //FILTRAR PERGUNTAS NAO RESPONDIDAS
        pergDependentes = pergDependentes.filter((pergunta) => {
          return idPerguntasRespondidas.includes(pergunta.pergunta.id);
        });

        const resultado = pergDependentes.map((aux, ind) => {
          return {
            ...aux,
            indPergunta: `${value.indPergunta}.${ind + 1}`,
          };
        });

        perguntas = ordenarPerguntas(perguntas.concat(resultado));
      }
    });

    if (perguntas.length === termoVistoria.respostas.length) return perguntas;

    //PEGAR PERGUNTAS DEPENDENTES DAS DEPENDENTES
    perguntasRestantes = termoVistoria.formulario.formularioPerguntas.filter(
      (res) => !perguntas.find((value) => value.pergunta.id === res.pergunta.id)
    );

    perguntas.forEach((value) => {
      let pergDependentes = perguntasRestantes.filter(
        (aux) => aux.pergunta.antecessora?.id === value.pergunta.id
      );

      if (pergDependentes.length > 0) {
        pergDependentes = ordenarPerguntas(pergDependentes);

        //FILTRAR PERGUNTAS NAO RESPONDIDAS
        pergDependentes = pergDependentes.filter((pergunta) => {
          return idPerguntasRespondidas.includes(pergunta.pergunta.id);
        });

        const resultado = pergDependentes.map((aux, ind) => {
          return {
            ...aux,
            indPergunta: `${value.indPergunta}.${ind + 1}`,
          };
        });

        perguntas = ordenarPerguntas(perguntas.concat(resultado));
      }
    });

    return perguntas;
  } catch (error) {
    console.log(error);
    return [];
  }
};

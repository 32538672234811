import { Button, Dropdown, List, Menu, Modal, Tooltip } from "antd";
import {
  DeleteOutlined,
  SnippetsOutlined,
  EditOutlined,
  CloseOutlined,
  UploadOutlined,
  EyeOutlined,
  PrinterOutlined,
  MenuOutlined,
  MinusOutlined,
  PaperClipOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useState } from "react";
import { ITif } from "@interfaces/ITif";
import { useActionsTif } from "../../../hooks/useActionsTif";
import { openNotification } from "@components/Notifications";
import { useNavigate } from "react-router-dom";
import { apiService } from "@services/api";
import { usePermission } from "@hooks/usePermission";
import styles from "../../style.module.css";
import { ModalPostAnexosTIF } from "../ModalPostAnexosTIF";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { converterTIFtoFormData } from "../../../functions/converterTIFtoFormData";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";
import { ModalVisualizarAnexos } from "@components/ModalVisualizarAnexos";

const CODIGO_TERMO = "TIF";

export function ItemListaTIF({
  tif,
  pageControl,
}: {
  tif: ITif;
  pageControl: number;
}) {
  const navigate = useNavigate();
  const { isAllowed } = usePermission();
  const { removerTif, atualizarTif, listaTif } = useActionsTif();
  const [modal, contextHolder] = Modal.useModal();
  const [isOpenModalAnexos, setIsOpenModalAnexos] = useState(false);
  const [isTifCancel, setIsTifCancel] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalEnviarAnexo, setIsOpenModalEnviarAnexo] = useState(false);
  const [spaceUsedAnexosTIF, setSpaceUsedAnexosTIF] = useState(0);

  // REMOVER TIF DA APLICAÇÃO
  const handleRemoveTifInApp = () => {
    removerTif(tif.numero);

    openNotification({
      type: "success",
      message: `TIF ${tif.numero} removido com sucesso!`,
    });
  };

  // VISUALIZAR TIF
  const handleShowTif = () => {
    if (!tif.id) return;
    navigate(`/TIF/listagem/visualizar/${tif.id}`);
    localStorage.setItem("TIF:pageSelect", pageControl.toString());
  };

  //EDITAR ANTES ENVIO
  const handleEditTIF = () => {
    navigate(`/TIF/edit/${tif.numero.replaceAll("/", "_")}`);
    localStorage.setItem("TIF:pageSelect", pageControl.toString());
  };

  //CANCELAR TIF
  const handleCancelTIF = async () => {
    if (!tif.id) return;

    setIsTifCancel(true);
  };

  //VISUALIZAR ANEXOS
  const handleShowAnexos = () => {
    setIsOpenModalAnexos(true);
  };

  //EXCLUIR TIF (QUANDO NAO FOI ENVIADO AINDA)
  const handleDeleteTIF = () => {
    setIsDelete(true);
    modal.confirm({
      title: `Tem certeza que deseja Excluir o TIF ${tif.numero}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      cancelText: "Não",
      onOk: () => {
        const tifRemove = listaTif.find((termo) => termo.numero === tif.numero);
        if (!tifRemove) return;

        removerTif(tif.numero);

        openNotification({
          type: "success",
          message: `TIF ${tif.numero} excluído com sucesso!`,
        });
        setIsDelete(false);
      },
      onCancel: () => setIsDelete(false),
    });
  };

  //ENVIAR ANEXOS POS ENVIO DO TIF
  const handlePostAnexos = () => {
    if (!tif.id) return;
    setIsOpenModalEnviarAnexo(true);
    tif.anexosTIF.forEach(async (anexo) => {
      dataURLtoFile(anexo.arquivo, anexo.nomeArquivo).then((res) => {
        // console.log(res);
        setSpaceUsedAnexosTIF((space) => space + res.size);
      });
    });
  };

  const dataURLtoFile = (url: string, filename: string, mimeType?: any) => {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  //ENVIO DO TIF CASO REDUX NAO TENHA FEITO ISSO
  const handlePostTif = async () => {
    const anexos = tif.anexosTIF.map((anexo) => {
      return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
    });

    setIsLoading(true);
    await apiService.tif
      .enviar(converterTIFtoFormData(tif, anexos))
      .then(async (res) => {
        await apiService.tif.byId(res.data.id).then((data) => {
          atualizarTif({ ...data, anexosTIF: tif.anexosTIF });
        });

        openNotification({
          type: "success",
          message: "TIF salvo com sucesso!",
          description: "Termo salvo na base de dados.",
        });
      })
      .catch((erro) => {
        console.log(erro);

        openNotification({
          type: "error",
          message: "Não foi possivel salvar o TIF.",
          description: `Erro: ${erro.response.data.detail}`,
        });
      });

    setIsLoading(false);
  };

  // IMPRIMIR TIF
  const handlePrinterTif = () => {
    navigate(`/TIF/listagem/impressao/${tif.numero.replaceAll("/", "_")}`);
    localStorage.setItem("TIF:pageSelect", pageControl.toString());
    return;
  };

  //EDITAR POS ENVIO
  const handleEditTIFposEnvio = () => {
    if (!tif.id) return;

    navigate(`/TIF/edit/atividades-assinatura/${tif.id}`);
    localStorage.setItem("TIF:pageSelect", pageControl.toString());
  };

  return (
    <>
      {contextHolder}
      <List.Item
        style={{
          border: isTifCancel || isDelete ? "1px solid #ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{
                opacity: tif.id ? 1 : 0.7,
                color: isTifCancel || isDelete ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              TIF {tif.numero} {tif.nomeFiscalizado}{" "}
              {tif.id ? "" : "(Pendente)"}
            </span>
          }
          description={
            <span className="subtitulo-card">
              Data Fiscalização:
              {moment(tif.dataFiscalizacao).locale("pt-br").format("L")} ID:
              {tif.id ? tif.id : ""}
              <br />
              Propriedade: {tif.nomePropriedade}
            </span>
          }
        />

        <div className={styles["pc-menu-list"]}>
          {!tif.id && navigator.onLine && (
            <Button disabled={isLoading} onClick={() => handlePostTif()}>
              {isLoading ? <SyncOutlined spin={isLoading} /> : "Enviar"}
            </Button>
          )}

          {tif.anexosTIF && tif.anexosTIF.length > 0 && (
            <Tooltip title="Anexos" destroyTooltipOnHide>
              <Button onClick={handleShowAnexos}>
                <PaperClipOutlined />
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Imprimir" destroyTooltipOnHide>
            <Button onClick={handlePrinterTif}>
              <PrinterOutlined />
            </Button>
          </Tooltip>

          {navigator.onLine && tif.id && (
            <Tooltip title="Visualizar" destroyTooltipOnHide>
              <Button
                onClick={handleShowTif}
                disabled={!isAllowed("visualizar", CODIGO_TERMO)}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
          )}

          <Tooltip title="Editar" destroyTooltipOnHide>
            <Button
              disabled={!!tif.id && !isAllowed("editar", CODIGO_TERMO)}
              onClick={(e) => {
                if (tif.id) {
                  handleEditTIFposEnvio();
                } else {
                  handleEditTIF();
                }
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>

          {tif.id && navigator.onLine && (
            <Tooltip title="Enviar Anexos" destroyTooltipOnHide>
              <Button onClick={() => handlePostAnexos()}>
                <UploadOutlined />
              </Button>
            </Tooltip>
          )}

          {tif.id ? (
            <Tooltip title="Cancelar" destroyTooltipOnHide>
              <Button
                disabled={
                  !navigator.onLine || !isAllowed("excluir", CODIGO_TERMO)
                }
                danger
                onClick={handleCancelTIF}
              >
                <CloseOutlined />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Excluir">
              <Button danger onClick={handleDeleteTIF}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
          )}

          {tif.id && (
            <Tooltip title="Remover do uso offline" destroyTooltipOnHide>
              <Button danger onClick={handleRemoveTifInApp}>
                <MinusOutlined />
              </Button>
            </Tooltip>
          )}
        </div>

        <div className={styles["mobile-menu-list"]}>
          {!tif.id && navigator.onLine && (
            <Button onClick={handlePostTif}>Enviar</Button>
          )}

          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={() => {
              if (tif.id) {
                return (
                  <Menu
                    items={[
                      {
                        key: 0,
                        label: "Anexos",
                        icon: <SnippetsOutlined />,
                        disabled: !(tif.anexosTIF && tif.anexosTIF.length > 0),
                        onClick: () => {
                          handleShowAnexos();
                        },
                      },
                      {
                        key: 1,
                        label: "Imprimir",
                        icon: <PrinterOutlined />,
                        onClick: (e) => handlePrinterTif(),
                      },
                      {
                        key: 2,
                        label: "Editar",
                        disabled: !isAllowed("editar", CODIGO_TERMO),
                        icon: <EditOutlined />,
                        onClick: (e) => handleEditTIFposEnvio(),
                      },
                      {
                        key: 3,
                        disabled: !navigator.onLine,
                        label: "Enviar Anexos",
                        icon: <UploadOutlined />,
                        onClick: () => handlePostAnexos(),
                      },
                      {
                        key: 4,
                        label: "Visualizar",
                        icon: <EyeOutlined />,
                        disabled:
                          !navigator.onLine ||
                          !isAllowed("visualizar", CODIGO_TERMO),
                        onClick: () => handleShowTif(),
                      },
                      {
                        key: 5,
                        label: "Cancelar",
                        danger: true,
                        icon: <CloseOutlined />,
                        disabled:
                          !navigator.onLine ||
                          !isAllowed("excluir", CODIGO_TERMO),
                        onClick: () => handleCancelTIF(),
                      },
                      {
                        key: 6,
                        label: "Remover do uso Offline",
                        icon: <MinusOutlined />,
                        danger: true,
                        onClick: () => handleRemoveTifInApp(),
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Menu
                    items={[
                      {
                        key: 0,
                        label: "Anexos",
                        icon: <SnippetsOutlined />,
                        disabled: !(tif.anexosTIF && tif.anexosTIF.length > 0),
                        onClick: () => handleShowAnexos(),
                      },
                      {
                        key: 1,
                        label: "Imprimir",
                        icon: <PrinterOutlined />,
                        onClick: (e) => handlePrinterTif(),
                      },
                      {
                        key: 2,
                        label: "Editar",
                        icon: <EditOutlined />,
                        onClick: (e) => handleEditTIF(),
                      },
                      {
                        key: 3,
                        label: "Excluir",
                        danger: true,
                        icon: <DeleteOutlined />,
                        onClick: () => handleDeleteTIF(),
                      },
                    ]}
                  />
                );
              }
            }}
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      {/**MODAL ENVIAR ANEXOS */}
      <ModalPostAnexosTIF
        isOpenModal={isOpenModalEnviarAnexo}
        setIsOpenModal={setIsOpenModalEnviarAnexo}
        tifSelected={tif}
        spaceUsed={spaceUsedAnexosTIF}
        setSpaceUsed={setSpaceUsedAnexosTIF}
      />

      {/**MODAL DE ANEXOS */}
      <ModalVisualizarAnexos
        anexos={tif.anexosTIF}
        isOpenModal={isOpenModalAnexos}
        setIsOpenModal={setIsOpenModalAnexos}
      />

      {/**MODAL DE CANCELAMENTO DO TIF */}
      <ModalCancelarTermo
        isOpen={isTifCancel}
        setIsOpen={setIsTifCancel}
        apiName="tif"
        idTermo={tif.id}
        numTermo={tif.numero}
        onRemoveTermo={removerTif}
        titleTermo="TIF"
        reloadFilter={async () => {}}
      />
    </>
  );
}

import { useDispatch, useSelector } from "react-redux";
import { getEstabelecimentos } from "@store/feature/estabelecimentoSlice";
import { estabelecimentosAction } from "@store/actions/estabelecimentoAction";
import { IEstabelecimento } from "@interfaces/IEstabelecimento";
import { apiService } from "@services/api";

type DadosEstabelecimento = {
  municipioId: number;
  estabelecimentos: IEstabelecimento[];
};

export const useEstabelecimento = () => {
  const listaEstabelecimentoSincronizados = useSelector(getEstabelecimentos);
  const appDispatch = useDispatch();
  const {
    removeEstabelecimentos,
    updateEstabelecimentos,
    addEstabelecimentos,
  } = estabelecimentosAction;

  const sincronizarEstabelecimentos = (
    dados: DadosEstabelecimento,
    createdAt: number
  ) => {
    appDispatch(addEstabelecimentos(dados, createdAt));
  };

  const atualizarEstabelecimentos = (
    dados: DadosEstabelecimento,
    updatedAt: number
  ) => {
    appDispatch(updateEstabelecimentos(dados, updatedAt));
  };

  const removerEstabelecimentos = (municipioId: number) => {
    appDispatch(removeEstabelecimentos(municipioId));
  };

  const buscarEstabelecimentoPeloCnpj = async (cnpj: string) => {
    if (navigator.onLine) {
      const estabelecimentosOnline = await apiService.estabelecimentos.byCnpj(
        cnpj
      );

      return estabelecimentosOnline;
    } else {
      const estabelecimentosOffline: IEstabelecimento[] = [];

      listaEstabelecimentoSincronizados.forEach((item) => {
        item.estabelecimentos.forEach((estab) => {
          if (estab.cpfCnpj === cnpj) {
            estabelecimentosOffline.push(estab);
          }
        });
      });

      return estabelecimentosOffline;
    }
  };

  return {
    listaEstabelecimentoSincronizados,
    sincronizarEstabelecimentos,
    atualizarEstabelecimentos,
    removerEstabelecimentos,
    buscarEstabelecimentoPeloCnpj,
  };
};

import { Fragment } from "react";
import { IFitoAmostrasPDF } from "@interfaces/IFitoAmostrasPDF";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import moment from "moment";
import { ContainerAmostraPDF } from "../../../styles/ContainerAmostraPDF";

export function FitoTabelaAmostrasImpressao({
  listaAmostras,
  termo,
}: {
  listaAmostras: IFitoAmostrasPDF[];
  termo: ITermoColetaAmostras;
}) {
  return (
    <>
      <tbody>
        <tr>
          <td>
            <div
              style={{
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
              }}
            >
              <div>
                {/**TITULO */}
                <div
                  className="fito-impressao-termo-background"
                  style={{ display: "flex" }}
                >
                  <div
                    style={{
                      width: "50%",
                      borderRight: "1px solid black",
                      paddingLeft: 5,
                      borderBottom: "1px solid black",
                    }}
                  >
                    Ficha de Coleta de Amostras Nº (Nº/ULE/Ano)
                  </div>

                  <div
                    style={{
                      width: "50%",
                      paddingLeft: 5,
                      borderBottom: "1px solid black",
                    }}
                  >
                    Número do Termo de Inspeção/Fiscalização/Notificação:
                  </div>
                </div>
                {/**CONTEUDO */}
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "50%",
                      borderRight: "1px solid black",
                      paddingLeft: 5,
                      borderBottom: "1px solid black",
                    }}
                  >
                    {termo?.termoColetaAmostra}
                  </div>

                  <div
                    style={{
                      width: "50%",
                      paddingLeft: 5,
                      borderBottom: "1px solid black",
                    }}
                  >
                    {termo?.termoFiscalizacao}
                  </div>
                </div>
              </div>

              {/**TITULO DOS ELEMENTOS DA TABELA DE AMOSTRAS */}
              <div
                className="fito-impressao-termo-background"
                style={{
                  display: "flex",
                  textAlign: "center",
                  height: 50,
                  borderBottom: "1px solid black",
                }}
              >
                <ContainerAmostraPDF width="10%" hasBorder>
                  Amostra nº
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="15%" hasBorder>
                  Latitude
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="15%" hasBorder>
                  Longitude
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Data de Plantio
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Cultivar
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Idade/Estádio Fenológico
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Quantidade coletada
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Material Coletado
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%">Praga(s)</ContainerAmostraPDF>
              </div>

              {/**ELEMENTOS DA TABELA */}
              <div style={{ minHeight: 125, borderBottom: "1px solid black" }}>
                {listaAmostras?.map((amostra, ind) => {
                  const pragas = termo.especie?.pragasLsv
                    ?.filter(
                      (praga) => praga.parte.nome === amostra.partePlanta
                    )
                    .map((praga) => {
                      return praga.praga.nomeComum;
                    })
                    .join(", ");

                  return (
                    <Fragment key={ind}>
                      <div
                        style={{
                          display: "flex",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <ContainerAmostraPDF width="10%" hasBorder>
                          {ind + 11}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="15%" hasBorder>
                          {amostra.latitude}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="15%" hasBorder>
                          {amostra.longitude}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {moment(amostra.dataPlantio).format("L")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.cultivar}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.estadio}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.qtdColetada}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.partePlanta}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%">
                          {pragas}
                        </ContainerAmostraPDF>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}

import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  List,
  Modal,
  Spin,
} from "antd";
import { Fragment, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getProdutores } from "@store/feature/propriedadeSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import "./style.css";
import { masks } from "@functions/mascaras";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { apiService } from "@services/api";
import { IMunicipio } from "@interfaces/IMunicipio";
import { openNotification } from "@components/Notifications";
import { IAssinante } from "@interfaces/IAssinante";
import { useMunicipio } from "@hooks/useMunicipio";
import { IFormValuesTif } from "@interfaces/IFormValuesTif";

interface ISearchFiscalizadoByNameProps {
  handleSearchFiscalizado: (value: string) => void;
  form: FormInstance<IFormValuesTif>;
  setMunicipioFiscalizadoOptions: React.Dispatch<
    React.SetStateAction<IMunicipio[]>
  >;
  handleResetRegistro: () => void;
  setFiscalizadoSignature: React.Dispatch<React.SetStateAction<IAssinante>>;
  fiscalizadoSignature: IAssinante;
}

export function SearchFiscalizadoByName({
  handleSearchFiscalizado,
  form,
  setMunicipioFiscalizadoOptions,
  handleResetRegistro,
  setFiscalizadoSignature,
  fiscalizadoSignature,
}: ISearchFiscalizadoByNameProps) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [produtoresLista, setProdutoresLista] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const produtores = useSelector(getProdutores);
  const { municipiosBrasil } = useMunicipio();
  const { cpfCnpjMask, telefoneMask, cepMask } = masks;

  const handleOpenModal = () => setIsOpenModal(true);

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setProdutoresLista([]);
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // const { value } = e.target;
  };

  const handleSearchName = async (nome: string) => {
    try {
      setLoading(true);
      if (navigator.onLine) {
        const params = new URLSearchParams();
        params.append("nome", nome);

        const pessoas = await apiService.pessoas(params.toString());
        setProdutoresLista(pessoas);
      } else {
        const pessoas = produtores.filter((produtor) =>
          produtor.nome.includes(nome.toUpperCase())
        );

        setProdutoresLista(pessoas);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      openNotification({
        type: "error",
        message: "Erro ao buscar fiscalizado",
        description: "Não foi possivel buscar fiscalizado(s)",
      });
      setLoading(false);
    }
  };

  const loadMoreData = () => {};

  const handleSelectFiscalizado = (
    e: React.MouseEvent<HTMLElement>,
    fiscalizado: any
  ) => {
    e.preventDefault();
    if (navigator.onLine) {
      form.setFieldsValue({
        fiscalizadoCpf: cpfCnpjMask(fiscalizado.cpfCnpj),
        fiscalizadoNome: fiscalizado.nome,
        emailFiscalizado: fiscalizado.email || "",
        telefoneFiscalizado: telefoneMask(fiscalizado.endereco.telefone || ""),
        inscricaoEstadualFiscalizado: fiscalizado.inscricaoEstadual || "",
        cepFiscalizado: cepMask(fiscalizado.endereco.cep || "") || "",
        municipioFiscalizadoId: fiscalizado.endereco.municipio.id || "",
        ufFiscalizado: fiscalizado.endereco.municipio.uf.id || "",
        enderecoFiscalizado: `${fiscalizado.endereco.logradouro},${fiscalizado.endereco.numero},${fiscalizado.endereco.bairro}`,
      });

      setMunicipioFiscalizadoOptions(
        municipiosBrasil.filter(
          (municipio) =>
            municipio.uf.id === fiscalizado.endereco.municipio.uf.id
        )
      );

      setFiscalizadoSignature({
        ...fiscalizadoSignature,
        nomeAssinante: fiscalizado.nome,
        cpfAssinante: cpfCnpjMask(fiscalizado.cpfCnpj),
        funcaoAssinante: "",
      });

      handleSearchFiscalizado(fiscalizado.cpfCnpj);
    } else {
      form.setFieldValue("fiscalizadoCpf", cpfCnpjMask(fiscalizado.cpf));
      handleSearchFiscalizado(fiscalizado.cpf);
    }

    handleCloseModal();
  };

  const listaMemoized = useMemo(() => {
    return (
      <Fragment>
        <InfiniteScroll
          dataLength={produtoresLista.length}
          next={loadMoreData}
          hasMore={produtoresLista.length < 50}
          loader={null}
          endMessage={<Divider plain>Final da lista de produtores</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={produtoresLista}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={
                    <button
                      className="button-nome-fiscalizado"
                      onClick={(e) => handleSelectFiscalizado(e, item)}
                    >
                      {item.nome}
                    </button>
                  }
                  description={item.email}
                />
                <div>{navigator.onLine ? item.cpfCnpj : item.cpf}</div>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </Fragment>
    );
    // eslint-disable-next-line
  }, [produtoresLista]);

  return (
    <div>
      <Form.Item label="Buscar pelo nome">
        <Button
          onClick={() => {
            if (!form.getFieldValue("atuado")) {
              openNotification({
                type: "error",
                message: "Erro ao buscar fiscalizado",
                description: "Preencha qual o tipo de atuação",
              });
              return;
            }

            handleResetRegistro();
            handleOpenModal();
          }}
        >
          <SearchOutlined />
        </Button>
      </Form.Item>

      <Modal
        title={
          <div style={{ textAlign: "center" }}>
            Buscar Fiscalizado pelo nome
          </div>
        }
        open={isOpenModal}
        onCancel={handleCloseModal}
        footer={<Button onClick={handleCloseModal}>Voltar</Button>}
        width={1000}
        destroyOnClose={true}
        keyboard
        maskClosable={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <Input.Search
            autoFocus
            placeholder="Digite o nome do fiscalizado"
            onChange={handleChange}
            onSearch={handleSearchName}
            type="primary"
            enterButton="Buscar"
          />
          <div
            style={{
              height: 400,
              overflow: "auto",
              padding: "0 16px",
              border: "1px solid rgba(140, 140, 140, 0.35)",
            }}
          >
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: 400,
                  width: "100%",
                }}
              >
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 45 }} spin />}
                />
              </div>
            ) : (
              listaMemoized
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

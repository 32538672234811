import { Button, Dropdown, List, Menu, Tooltip, message } from "antd";
import {
  MenuOutlined,
  PrinterOutlined,
  EyeOutlined,
  EditOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import styles from "../../style.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePermission } from "@hooks/usePermission";
import { useNavigate } from "react-router-dom";
import { openNotification } from "@components/Notifications";
import { useActionsTermoColeta } from "../../../hooks/useActionsTermoColeta";
import { apiService } from "@services/api";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { FITOSSANITARIA, OFICIAL } from "../../../constants";
import { IOficialAmostrasPDF } from "@interfaces/IOficialAmosrasPDF";
import { IFitoAmostrasPDF } from "@interfaces/IFitoAmostrasPDF";
import { masks } from "@functions/mascaras";
import { FitoImpressao } from "../../../components/ImpressaoTermoColeta/FitoImpressao";
import { OficialImpressao } from "../../../components/ImpressaoTermoColeta/OficialImpressao";
import { ModalEditDestinoTermoColeta } from "../ModalEditDestinoTermoColeta";
import { useReactToPrint } from "react-to-print";
import { IContentListagemTCA } from "@interfaces/IListagemTermoColeta";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";

const CODIGO_TERMO = "TermoColeta";

export function ItemListaFiltroTermoColeta({
  item,
  pageControl,
}: {
  item: IContentListagemTCA;
  pageControl: number;
}) {
  const navigate = useNavigate();
  const fitoRef = useRef(null);
  const oficialTermoRef = useRef(null);
  const { isAllowed } = usePermission();
  const {
    adicionarTermoColetaByFiltro,
    listaFiltroTermos,
    listaTermos,
    aplicarFiltro,
    removerTermoColeta,
  } = useActionsTermoColeta();
  const [isCancel, setIsCancel] = useState(false);
  const [isOpenModalEditDestino, setIsOpenModalEditDestino] = useState(false);
  const [termoSelected, setTermoSelected] =
    useState<ITermoColetaAmostras | null>(null);
  const [oficialAmostras, setOficialAmostras] = useState<IOficialAmostrasPDF[]>(
    []
  );
  const [fitoAmostras, setFitoAmostras] = useState<IFitoAmostrasPDF[]>([]);

  const { coordMask } = masks;
  const [isDownload, setIsDownload] = useState(false);

  //VISUALIZAR
  const handleViewTermo = async () => {
    if (!item.id) return;
    navigate(`/termo-coleta/listagem/visualizar/${item.id}`);
    localStorage.setItem("termoColeta:pageSelect", pageControl.toString());
  };

  //BAIXAR TERMO DO FILTRO
  const handleDownloadTermoColetaInFilter = async () => {
    const termo = listaTermos.find((termoColeta) => termoColeta.id === item.id);

    if (termo) {
      openNotification({
        type: "info",
        message: "Não foi possivel baixar Termo de Coleta",
        description: "Termo já adicionado no SISDEV-Moblie",
      });
      return;
    }

    message.loading({
      key: "download-termo-coleta",
      duration: 0,
      content: "Baixando Termo de Coleta...",
    });
    setIsDownload(true);

    try {
      const responseTermo = await apiService.termoColeta.byId(item.id);
      adicionarTermoColetaByFiltro(responseTermo);
      openNotification({
        type: "success",
        message: `Termo de coleta ${responseTermo.termoColetaAmostra} baixado com sucesso!`,
      });

      setIsDownload(false);
      message.destroy("download-termo-coleta");
    } catch (error: any) {
      console.log(error);
      setIsDownload(false);
      message.destroy("download-termo-coleta");
      openNotification({
        type: "error",
        message: "Não foi possivel baixar Termo de coleta",
        description: error.response.data.detail,
      });
    }
  };

  //CANCELAR
  const handleCancelTermo = async () => {
    if (!item.id) return;

    await apiService.termoColeta
      .byId(item.id)
      .then((res) => {
        setIsCancel(true);
        setTermoSelected(res);
      })
      .catch((erro) => {
        console.log(erro);
        // window.location.reload();
      });
  };

  //IMPRIMIR
  const handlePrintedTermo = async () => {
    try {
      await apiService.termoColeta.byId(item.id).then((data) => {
        setTermoSelected(data);

        if (data.tipoColeta === OFICIAL) {
          const auxOficial = data.itens.map((amostra) => {
            return {
              numAmostra: amostra.nrAmostraLasgal,
              cultivar: amostra.cultivar?.nome,
              categoria: amostra.categoria?.nome,
              lote: amostra.lote,
              qtdEmbalagens: amostra.qtdEmbalagens,
              valorEmbalagens: amostra.valorEmbalagem,
              pesoEmbalagens: amostra.pesoEmbalagem,
              validadeTesteGerminacao: amostra.validadeTesteGerminacao,
              pureza: amostra.pureza,
              germinacao: amostra.germinacao,
              tetrazolio: amostra.tetrazolio,
              sementesDuras: amostra.sementesDura,
              outrosFatores: amostra.valorOutrosFatores,
            };
          });

          setOficialAmostras(auxOficial);
        } else {
          const auxFito = data.itens.map((amostra) => {
            return {
              numAmostra: amostra.nrAmostraLsv,
              latitude: `${coordMask(amostra.latGrau.toString())}°${coordMask(
                amostra.latMin.toString()
              )}'${coordMask(amostra.latSeg.toString())}"`,
              longitude: `${coordMask(amostra.longGrau.toString())}°${coordMask(
                amostra.longMin.toString()
              )}'${coordMask(amostra.longSeg.toString())}"`,
              dataPlantio: amostra.dataPlantio,
              cultivar: amostra.cultivar?.nome,
              estadio: amostra.estadio?.nome,
              qtdColetada: amostra.qtdEmbalagens,
              partePlanta: amostra.partePlanta?.nome,
              pragas: "",
            };
          });

          setFitoAmostras(auxFito);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //Abrir modal de editar destino do termo
  const handleOpenModalEditarDestino = async () => {
    if (!item.id) return;

    try {
      const data = await apiService.termoColeta.byId(item.id);

      setIsOpenModalEditDestino(true);
      setTermoSelected(data);
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel editar esse termo de coleta",
      });
    }
  };

  //Fechar Modal de editar destino no termo de coleta
  const handleCloseModalEditarDestino = () => {
    setIsOpenModalEditDestino(false);
    setTermoSelected(null);
  };

  const reloadFilter = async () => {
    const params = new URLSearchParams(listaFiltroTermos[0].params);

    params.append("page", (pageControl - 1).toString());

    await apiService.termoColeta
      .listar(params.toString())
      .then((res) => {
        if (res.content.length > 0) {
          aplicarFiltro([
            {
              filtros: listaFiltroTermos[0].filtros,
              params: listaFiltroTermos[0].params.toString(),
              termos: res.content,
              numberPages: res.totalPages,
            },
          ]);
        }
      })
      .catch((erro) => {
        console.log(erro);
        openNotification({
          type: "error",
          message: "Não foi possivel atualizar o filtro",
        });
      });

    localStorage.setItem("termoColeta:pageSelect", pageControl.toString());
  };

  const handleAfterPrint = useCallback(() => {
    setTermoSelected(null);
    // tslint:disable-line no-console
  }, []);

  const handlePrint = useReactToPrint({
    contentRef:
      termoSelected?.tipoColeta === FITOSSANITARIA ? fitoRef : oficialTermoRef,
    documentTitle: `Termo de coleta ${termoSelected?.termoColetaAmostra}`,
    pageStyle: `@page { size: landscape A3 !important;}`,
    suppressErrors: true,
    preserveAfterPrint: true,
    onAfterPrint: handleAfterPrint,
  });

  useEffect(() => {
    if (fitoAmostras.length > 0 || oficialAmostras.length > 0) {
      handlePrint();
    }
    //eslint-disable-next-line
  }, [fitoAmostras, oficialAmostras]);

  return (
    <>
      <List.Item
        style={{
          border: isCancel ? "1px solid #ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{
                color: isCancel ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              Termo de Coleta {item.termoColetaAmostra}{" "}
              {item.situacao === "CANCELADA" ? "(Cancelado)" : ""}
            </span>
          }
          description={
            <span className="subtitulo-card">
              Tipo de coleta: {item.tipoColeta} ID:
              {item.id ? item.id : ""}
              <br />
              Data Coleta: {item.dataColeta}
              <br />
              {item.propriedade
                ? `Propriedade: ${item.propriedade?.nome}`
                : item.produtor
                ? `Estabelecimento: ${item.produtor?.nome}`
                : ""}
            </span>
          }
        />

        <div className={styles["pc-menu-lista-termos"]}>
          <Tooltip title="Baixar para uso offline" destroyTooltipOnHide>
            <Button
              disabled={
                !navigator.onLine ||
                item.situacao === "CANCELADA" ||
                !item.statusAssinatura ||
                isDownload
              }
              onClick={() => handleDownloadTermoColetaInFilter()}
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Imprimir" destroyTooltipOnHide>
            <Button
              disabled={
                !navigator.onLine || !item.statusAssinatura || isDownload
              }
              onClick={(e) => handlePrintedTermo()}
            >
              <PrinterOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Visualizar" destroyTooltipOnHide>
            <Button
              disabled={
                item.situacao === "CANCELADA" ||
                !navigator.onLine ||
                !isAllowed("visualizar", CODIGO_TERMO) ||
                !item.statusAssinatura ||
                isDownload
              }
              onClick={(e) => {
                handleViewTermo();
              }}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Editar" destroyTooltipOnHide>
            <Button
              disabled={
                item.situacao === "CANCELADA" ||
                !navigator.onLine ||
                ["EXTERNO", "LSV"].includes(item.destino) ||
                !isAllowed("editar", CODIGO_TERMO) ||
                !item.statusAssinatura ||
                isDownload
              }
              onClick={(e) => {
                handleOpenModalEditarDestino();
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Cancelar" destroyTooltipOnHide>
            <Button
              disabled={
                item.situacao === "CANCELADA" ||
                !navigator.onLine ||
                !isAllowed("excluir", CODIGO_TERMO) ||
                !item.statusAssinatura ||
                isDownload
              }
              danger
              onClick={(e) => handleCancelTermo()}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </div>

        <div className={styles["mobile-menu-lista-termos"]}>
          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={
              <Menu
                items={[
                  {
                    key: 0,
                    label: "Baixar",
                    disabled:
                      !navigator.onLine ||
                      item.situacao === "CANCELADA" ||
                      !item.statusAssinatura ||
                      isDownload,
                    icon: <DownloadOutlined />,
                    onClick: () => handleDownloadTermoColetaInFilter(),
                  },
                  {
                    key: 1,
                    label: "Imprimir",
                    disabled:
                      !navigator.onLine || !item.statusAssinatura || isDownload,
                    icon: <PrinterOutlined />,
                    onClick: (e) => handlePrintedTermo(),
                  },

                  {
                    key: 2,
                    label: "Visualizar",
                    icon: <EyeOutlined />,
                    onClick: async (e) => {
                      handleViewTermo();
                    },
                    disabled:
                      item.situacao === "CANCELADA" ||
                      !navigator.onLine ||
                      !item.id ||
                      !isAllowed("visualizar", CODIGO_TERMO) ||
                      !item.statusAssinatura ||
                      isDownload,
                  },

                  {
                    key: 3,
                    label: "Editar",
                    icon: <EditOutlined />,
                    disabled:
                      item.situacao === "CANCELADA" ||
                      !navigator.onLine ||
                      ["EXTERNO", "LSV"].includes(item.destino) ||
                      !isAllowed("editar", CODIGO_TERMO) ||
                      !item.statusAssinatura ||
                      isDownload,
                    onClick: () => {
                      handleOpenModalEditarDestino();
                    },
                  },
                  {
                    key: 4,
                    icon: <CloseOutlined />,
                    label: "Cancelar",
                    danger: true,
                    disabled:
                      item.situacao === "CANCELADA" ||
                      !navigator.onLine ||
                      !isAllowed("excluir", CODIGO_TERMO) ||
                      !item.statusAssinatura ||
                      isDownload,
                    onClick: (e) => handleCancelTermo(),
                  },
                ]}
              />
            }
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      {/**MODAL CANCELAMENTO TERMO COLETA */}
      {termoSelected && (
        <ModalCancelarTermo
          isOpen={isCancel}
          setIsOpen={setIsCancel}
          apiName="termoColeta"
          idTermo={termoSelected.id}
          numTermo={termoSelected.termoColetaAmostra}
          onRemoveTermo={removerTermoColeta}
          titleTermo="Termo de Coleta"
          isFilterApplied
          reloadFilter={reloadFilter}
        />
      )}

      <ModalEditDestinoTermoColeta
        isOpen={isOpenModalEditDestino}
        onClose={handleCloseModalEditarDestino}
        termoSelected={termoSelected}
      />

      <div style={{ display: "none" }}>
        {termoSelected?.tipoColeta === FITOSSANITARIA && (
          <FitoImpressao
            ref={fitoRef}
            termo={termoSelected}
            listaAmostras={fitoAmostras}
            marcaDagua=""
          />
        )}

        {termoSelected?.tipoColeta === OFICIAL && (
          <OficialImpressao
            ref={oficialTermoRef}
            termo={termoSelected}
            listaAmostras={oficialAmostras}
            marcaDagua=""
          />
        )}
      </div>
    </>
  );
}

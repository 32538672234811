import { IAtividadeTIF } from "@interfaces/IAtividadeTIF";

export const desorganizarListaAtividade = (atividades: IAtividadeTIF[]) => {
  const res = atividades.map((atv) => {
    const auxText: string[] = [];

    if (atv.coleta) {
      auxText.push("Coleta");
    }

    if (atv.denuncia) {
      auxText.push("Atendimento à denuncia");
    }

    if (atv.desinterdicao) {
      auxText.push("Desinterdição");
    }

    if (atv.notificacao) {
      auxText.push("Notificação");
    }

    return {
      key: atv.id,
      programa: {
        id: atv.areaAtividade.id,
        label: atv.areaAtividade.nome,
        value: atv.areaAtividade.nome,
      },
      atividade: {
        id: atv.atividade.id,
        nome: atv.atividade.nome,
        questoes: atv.atividade.questoes,
      },
      prazo: atv.prazoAdequacao,
      especificacoes: auxText.join(","),
      armadilha: atv.armadilha,
    };
  });

  return res;
};

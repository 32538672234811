import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { openNotification } from "@components/Notifications";
import { IMunicipio } from "@interfaces/IMunicipio";
import { IPropriedade } from "@interfaces/IPropriedade";
import { masks } from "@functions/mascaras";
import { IAssinante } from "@interfaces/IAssinante";
import { SearchFiscalizadoByName } from "./components/SearchFiscalizadoByName";
import { EnvironmentOutlined } from "@ant-design/icons";
import {
  FISCALIZACAO_FRONTEIRA,
  TIPOS_ATUADO,
  TIPOS_REPRESENTANTES,
  TIPOS_TERMO,
} from "../../constants";
import locale from "antd/lib/date-picker/locale/pt_BR";
import { IFormValuesTif } from "@interfaces/IFormValuesTif";
import { useMunicipio } from "@hooks/useMunicipio";
import { usePropriedade } from "@hooks/usePropriedade";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { ITif } from "@interfaces/ITif";
import { useDadosTif } from "@hooks/useDadosTif";

interface Props {
  form: FormInstance<IFormValuesTif>;
  isValidDocument: boolean;
  setIsValidDocument: React.Dispatch<React.SetStateAction<boolean>>;
  typeDocument: string;
  setTypeDocument: React.Dispatch<React.SetStateAction<string>>;
  fiscalizadoSignature: IAssinante;
  setFiscalizadoSignature: React.Dispatch<React.SetStateAction<IAssinante>>;
  setTypePropEstab: React.Dispatch<React.SetStateAction<string>>;
  typePropEstab: string;
  tifEdit?: ITif;
  setIdEstabelecimento: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  idEstabelecimento: number | undefined;
  setMunicipioFiscalizacaoId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}

export function RegistroTIF({
  form,
  isValidDocument,
  setIsValidDocument,
  typeDocument,
  setTypeDocument,
  fiscalizadoSignature,
  setFiscalizadoSignature,
  setTypePropEstab,
  typePropEstab,
  tifEdit,
  setIdEstabelecimento,
  idEstabelecimento,
  setMunicipioFiscalizacaoId,
}: Props) {
  //VARIAVEIS
  const { buscarEstabelecimentoPeloCnpj } = useEstabelecimento();
  const { buscarPropriedadePeloCpfCnpj } = usePropriedade();
  const { municipiosBrasil, municipiosMt, listaUfs } = useMunicipio();
  const { atividadesTif } = useDadosTif();
  const { cepMask, cpfCnpjMask, coordMask, renasemMask, rgMask, telefoneMask } =
    masks;

  const [labelPropOrEstab, setLabelPropOrEstab] = useState("Propriedade");
  const [statusError, setStatusError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isPropOrEstabSelected, setIsPropOrEstabSelected] = useState(false);
  const [isDisableRelacao, setIsDisableRelacao] = useState(false);
  const [propriedadesOptions, setPropriedadeOptions] = useState<IPropriedade[]>(
    []
  );
  const [municipioFiscalizadoOptions, setMunicipioFiscalizadoOptions] =
    useState<IMunicipio[]>([]);

  //FUNCAO PRINCIPAL DO COMPONENT
  const handleSearchFiscalizado = (value: string) => {
    const atuado = form.getFieldValue("atuado");
    const cpfCnpj = value ? value.replace(/[^\d]+/g, "") : "";

    //VALIDACOES
    if (!value) return;

    if (!atuado) {
      openNotification({
        type: "error",
        message: "Erro ao buscar fiscalizado",
        description: "Preencha o tipo de atuação.",
      });
      setStatusError(true);
      return;
    }

    //VERIFICANDO SE O FORMATO DE DOCUMENTO DIGITADO É UM CPF OU CNPJ
    if (!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) {
      openNotification({
        type: "error",
        message: "Erro ao buscar fiscalizado",
        description: "Digite um CPF/CNPJ válido para prosseguir.",
      });
      setStatusError(true);
      return;
    }
    if (atuado === "estabelecimento" && cpf.isValid(cpfCnpj)) {
      openNotification({
        type: "error",
        message: "Erro ao buscar fiscalizado",
        description: "CNPJ inválido! Verifique o tipo de atuação.",
      });
      setIsValidDocument(false);
      setStatusError(true);
      return;
    }

    if (atuado === "profissional" && cnpj.isValid(cpfCnpj)) {
      openNotification({
        type: "error",
        message: "Erro ao buscar fiscalizado",
        description: "CPF inválido! Verifique o tipo de atuação.",
      });
      setIsValidDocument(false);
      setStatusError(true);
      return;
    }

    if (atuado === "propriedade") {
      buscaPropriedade(cpfCnpj);
    }

    if (atuado === "estabelecimento") {
      buscaEstabelecimento(cpfCnpj);
    }

    if (atuado === "profissional") {
      buscaPropriedade(cpfCnpj);
    }

    if (atuado === "transito") {
      buscaPropriedadeOuEstabelecimento(cpfCnpj);
    }

    // CPF/CNPJ ESTA PREENCHIDO CORRETAMENTE E O ATUADO ESTA SELECIONADO
    setIsValidDocument(true);

    // HABILITANDO CAMPOS
    setStatusError(false);
    setTypeDocument(cpfCnpj.length === 14 ? "cnpj" : "cpf");
  };

  const buscaPropriedadeOuEstabelecimento = async (cpfCnpj: string) => {
    try {
      const propriedades = await buscaPropriedade(cpfCnpj, true);

      if (!propriedades || propriedades.length === 0) {
        const estabelecimentos = await buscaEstabelecimento(cpfCnpj, true);
        if (!estabelecimentos || estabelecimentos.length === 0) {
          openNotification({
            type: "info",
            message:
              "Não foi encontrado nenhum registro de Propriedades/Estabelecimentos desse CPF/CNPJ",
          });
        }
      }
    } catch (error: any) {
      openNotification({
        type: "error",
        message:
          "Não foi possivel encontrar Propriedades/Estabelecimentos desse CPF/CNPJ",
        description: `Erro ${error?.response?.status}:${error?.response?.data}`,
      });
    }
  };

  const buscaPropriedade = async (cpfCnpj: string, isTransito?: boolean) => {
    try {
      setIsLoading(true);
      const propriedades = await buscarPropriedadePeloCpfCnpj(cpfCnpj);
      setIsLoading(false);
      if (propriedades.length > 0) {
        const { produtores } = propriedades.filter((propriedade) => {
          return propriedade.produtores.filter(
            (produtor) => produtor.cpf === cpfCnpj
          );
        })[0];

        setMunicipioFiscalizadoOptions(
          municipiosBrasil.filter(
            (municipio) =>
              municipio.uf.id === produtores[0].endereco.municipio.uf.id
          )
        );

        //SETANDO CAMPOS NO FORMULARIO
        form.setFieldsValue({
          fiscalizadoNome: produtores[0].nome,
          renasemFiscalizado: produtores[0].renasem || "",
          enderecoFiscalizado: `${produtores[0].endereco.logradouro},${produtores[0].endereco.numero},${produtores[0].endereco.bairro}`,
          ufFiscalizado: produtores[0].endereco.municipio.uf.id,
          municipioFiscalizadoId: produtores[0].endereco.municipio.id,
          emailFiscalizado: produtores[0].email,
          telefoneFiscalizado: telefoneMask(
            produtores[0].endereco.telefone || ""
          ),
          cepFiscalizado: cepMask(produtores[0].endereco.cep || ""),
          inscricaoEstadualFiscalizado: produtores[0].rg,
        });

        setFiscalizadoSignature({
          ...fiscalizadoSignature,
          nomeAssinante: produtores[0].nome,
          cpfAssinante: cpfCnpjMask(produtores[0].cpf),
          funcaoAssinante: "Produtor",
        });

        setPropriedadeOptions(propriedades);
        setTypePropEstab("propriedade");
        return propriedades;
      } else {
        if (!isTransito) {
          openNotification({
            type: "info",
            message: "Propriedade não encontrada",
          });
        }

        return [];
      }
    } catch (error: any) {
      setIsLoading(false);
      if (!isTransito) {
        openNotification({
          type: "error",
          message: "Não foi possivel buscar propriedade pelo CPF/CNPJ",
          description: `Erro ${error?.response?.status}:${error?.response?.data}`,
        });
      }

      return;
    }
  };

  const buscaEstabelecimento = async (
    cpfCnpj: string,
    isTransito?: boolean
  ) => {
    try {
      setIsLoading(true);
      const estabelecimentos = await buscarEstabelecimentoPeloCnpj(cpfCnpj);
      setIsLoading(false);
      if (estabelecimentos.length > 0) {
        const municipioLocalizadoMt = municipiosMt.find(
          (municipio) =>
            municipio.id === estabelecimentos[0].endereco.municipio.id
        );

        if (municipioLocalizadoMt) {
          setMunicipioFiscalizacaoId(estabelecimentos[0].endereco.municipio.id);
        }

        form.setFieldsValue({
          fiscalizadoNome: estabelecimentos[0].nome,
          enderecoFiscalizado: `${estabelecimentos[0].endereco.logradouro},${estabelecimentos[0].endereco.numero},${estabelecimentos[0].endereco.bairro}`,
          ufFiscalizado: estabelecimentos[0].endereco.municipio.uf.id,
          municipioFiscalizadoId: estabelecimentos[0].endereco.municipio.id,
          emailFiscalizado: estabelecimentos[0].email,
          telefoneFiscalizado: telefoneMask(
            estabelecimentos[0].endereco.telefone || ""
          ),
          cepFiscalizado: cepMask(estabelecimentos[0].endereco.cep || ""),
          municipioFiscalizacaoId: municipioLocalizadoMt
            ? estabelecimentos[0].endereco.municipio.id
            : undefined,
          cepPropriedade: cepMask(estabelecimentos[0].endereco.cep || ""),
          enderecoPropriedade: `${estabelecimentos[0].endereco.logradouro},${estabelecimentos[0].endereco.numero},${estabelecimentos[0].endereco.bairro}`,
          latGrauPropriedade: coordMask(
            estabelecimentos[0].coordenadaGeografica?.latGrau?.toString() || ""
          ),
          latMinPropriedade: coordMask(
            estabelecimentos[0].coordenadaGeografica?.latMin?.toString() || ""
          ),
          latSegPropriedade: coordMask(
            estabelecimentos[0].coordenadaGeografica?.latSeg?.toString() || ""
          ),
          longGrauPropriedade: coordMask(
            estabelecimentos[0].coordenadaGeografica?.longGrau?.toString() || ""
          ),
          longMinPropriedade: coordMask(
            estabelecimentos[0].coordenadaGeografica?.longMin?.toString() || ""
          ),
          longSegPropriedade: coordMask(
            estabelecimentos[0].coordenadaGeografica?.longSeg?.toString() || ""
          ),
        });

        setFiscalizadoSignature({
          ...fiscalizadoSignature,
          nomeAssinante: estabelecimentos[0].nome,
          cpfAssinante: cpfCnpjMask(estabelecimentos[0].cpfCnpj),
          funcaoAssinante: "Estabelecimento",
        });
        // setEstabelecimentosOptions(estabelecimentos);
        setIdEstabelecimento(estabelecimentos[0].id);

        setMunicipioFiscalizadoOptions(
          municipiosBrasil.filter(
            (municipio) =>
              municipio.uf.id === estabelecimentos[0].endereco.municipio.uf.id
          )
        );
        return estabelecimentos;
      } else {
        if (!isTransito) {
          openNotification({
            type: "info",
            message: "Estabelecimento não encontrado",
          });
        }

        return [];
      }
    } catch (error: any) {
      setIsLoading(false);
      if (!isTransito) {
        openNotification({
          type: "error",
          message: "Não foi possivel buscar Estabelecimento pelo CNPJ",
          description: `Erro ${error.toString()}`,
        });
      }

      return;
    }
  };

  const handleChangeUF = (value: number | undefined) => {
    form.setFieldValue("municipioFiscalizadoId", "");
    if (!value) {
      setMunicipioFiscalizadoOptions([]);
      return;
    }

    const municipios = municipiosBrasil.filter(
      (municipio) => municipio.uf.id === value
    );
    setMunicipioFiscalizadoOptions(municipios);
  };

  const handleCompleteGeoLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition((location) => {
        let latDeg, latMin, latSeg, longDeg, longMin, longSeg;

        let valLat = Math.abs(location.coords.latitude);
        let valLong = Math.abs(location.coords.longitude);

        latDeg = Math.floor(valLat);
        latMin = Math.floor((valLat - latDeg) * 60);
        latSeg =
          Math.round((valLat - latDeg - latMin / 60) * 3600 * 1000) / 1000;

        longDeg = Math.floor(valLong);
        longMin = Math.floor((valLong - longDeg) * 60);
        longSeg =
          Math.round((valLong - longDeg - longMin / 60) * 3600 * 1000) / 1000;

        form.setFieldsValue({
          latGrauPropriedade: coordMask(latDeg.toString() || ""),
          latMinPropriedade: coordMask(latMin.toString() || ""),
          latSegPropriedade: coordMask(latSeg.toString() || ""),
          longGrauPropriedade: coordMask(longDeg.toString() || ""),
          longMinPropriedade: coordMask(longMin.toString() || ""),
          longSegPropriedade: coordMask(longSeg.toString() || ""),
        });
      });
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel utilizar localização atual",
      });
    }
  };

  const clearFields = () => {
    setMunicipioFiscalizacaoId(undefined);
    form.resetFields([
      "fiscalizadoNome",
      "inscricaoEstadualFiscalizado",
      "renasemFiscalizado",
      "enderecoFiscalizado",
      "ufFiscalizado",
      "municipioFiscalizadoId",
      "municipioFiscalizacaoId",
      "emailFiscalizado",
      "telefoneFiscalizado",
      "cepFiscalizado",
      "cepPropriedade",
      "latGrauPropriedade",
      "latMinPropriedade",
      "latSegPropriedade",
      "longGrauPropriedade",
      "longMinPropriedade",
      "longSegPropriedade",
      "enderecoPropriedade",
      "propriedadeId",
      "estabelecimentoId",
      "propEstab",
    ]);
  };

  const handleResetFieldsForm = () => {
    setIsValidDocument(false);
    setStatusError(false);
    setIsPropOrEstabSelected(false);
    setTypeDocument("");
    setTypePropEstab("");
    setIdEstabelecimento(undefined);

    setPropriedadeOptions([]);
    // setEstabelecimentosOptions([]);

    clearFields();

    setFiscalizadoSignature({
      ...fiscalizadoSignature,
      nomeAssinante: "",
      cpfAssinante: "",
      funcaoAssinante: "",
    });
  };

  const handleChangeButtonRadio = (e: RadioChangeEvent) => {
    const { value } = e.target;

    if (value === "estabelecimento") {
      setLabelPropOrEstab("Estabelecimento");
    } else if (value === "propriedade") {
      setLabelPropOrEstab("Propriedade");
    } else {
      setLabelPropOrEstab("transito/profissional");
    }

    if (value === "profissional") {
      setIsDisableRelacao(true);
      form.setFieldValue("tipoRepresentante", "");
    } else {
      setIsDisableRelacao(false);
    }

    handleResetFieldsForm();
  };

  const handleChangeInputCpfCnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (
      !cpf.isValid(value.replace(/[^\d]+/g, "")) ||
      !cnpj.isValid(value.replace(/[^\d]+/g, ""))
    ) {
      handleResetFieldsForm();
    }

    if (form.getFieldValue("atuado") === "estabelecimento") {
      form.setFieldValue("fiscalizadoCpf", cpfCnpjMask(value, "cnpj"));
    } else {
      form.setFieldValue("fiscalizadoCpf", cpfCnpjMask(value));
    }
  };

  //FUNCAO QUE LIDA COM MUDANÇA DE ESCOLHA DE PROPRIEDADES
  //PARA ASSIM MUDAR OS CAMPOS DE MUNICIPIO DA PROPRIEDADE E PROPRIEDADE/ESTABELECIMENTO
  const handleChangePropriedade = (value: number) => {
    if (!value) {
      setIsPropOrEstabSelected(false);
      setMunicipioFiscalizacaoId(undefined);
      form.resetFields([
        "municipioFiscalizacaoId",
        "latGrauPropriedade",
        "latMinPropriedade",
        "latSegPropriedade",
        "longGrauPropriedade",
        "longMinPropriedade",
        "longSegPropriedade",
        "enderecoPropriedade",
        "cepPropriedade",
      ]);

      return;
    }

    const propriedadeSelected = propriedadesOptions.find(
      (prop) => prop.id === value
    );

    if (propriedadeSelected) {
      setIsPropOrEstabSelected(true);
      setMunicipioFiscalizacaoId(propriedadeSelected.municipio.id);
      form.setFieldsValue({
        municipioFiscalizacaoId: propriedadeSelected.municipio.id,
        latGrauPropriedade: propriedadeSelected.coordenadaGeografica
          ? coordMask(
              propriedadeSelected.coordenadaGeografica.latGrau?.toString() || ""
            )
          : "",
        latMinPropriedade: propriedadeSelected.coordenadaGeografica
          ? coordMask(
              propriedadeSelected.coordenadaGeografica.latMin?.toString() || ""
            )
          : "",
        latSegPropriedade: propriedadeSelected.coordenadaGeografica
          ? coordMask(
              propriedadeSelected.coordenadaGeografica.latSeg?.toString() || ""
            )
          : "",
        longGrauPropriedade: propriedadeSelected.coordenadaGeografica
          ? coordMask(
              propriedadeSelected.coordenadaGeografica.longGrau?.toString() ||
                ""
            )
          : "",
        longMinPropriedade: propriedadeSelected.coordenadaGeografica
          ? coordMask(
              propriedadeSelected.coordenadaGeografica.longMin?.toString() || ""
            )
          : "",
        longSegPropriedade: propriedadeSelected.coordenadaGeografica
          ? coordMask(
              propriedadeSelected.coordenadaGeografica.longSeg?.toString() || ""
            )
          : "",
        enderecoPropriedade: propriedadeSelected.viaAcesso,
        propEstab: "",
      });
    }
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (e.key === "Enter") {
      document.getElementById(id)?.focus();
    }
  };

  const handleCompleteEdit = async () => {
    if (!tifEdit) return;
    // setando municipio do fiscalizado
    const auxMunicipio = municipiosBrasil.filter(
      (municipio) => municipio.uf.id === tifEdit.municipioFiscalizado?.uf.id
    );

    setMunicipioFiscalizadoOptions(auxMunicipio);
    setIsDisableRelacao(tifEdit.caracteristicaFiscalizado === "PROFISSIONAL");
    setIsPropOrEstabSelected(
      !!(tifEdit.propriedade || tifEdit.estabelecimento)
    );

    if (tifEdit.estabelecimento) {
      setIdEstabelecimento(tifEdit.estabelecimento.id);
    } else {
      const props = await buscarPropriedadePeloCpfCnpj(
        tifEdit.cpfCnpjFiscalizado
      );
      setPropriedadeOptions(props);

      if (tifEdit.propriedade) {
        form.setFieldValue("propriedadeId", tifEdit.propriedade.id);
      } else {
        form.setFieldValue("propEstab", tifEdit.nomePropriedade);
      }
    }

    if (
      ["PROPRIEDADE", "ESTABELECIMENTO"].includes(
        tifEdit.caracteristicaFiscalizado
      )
    ) {
      setTypePropEstab(tifEdit.caracteristicaFiscalizado.toLowerCase());
    } else {
      setTypePropEstab("transito/profissional");
    }
  };

  useEffect(() => {
    handleCompleteEdit();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Form.Item
        name="termo"
        label="Termo"
        rules={[{ required: true, message: "Escolha alguma opção" }]}
      >
        <Checkbox.Group options={TIPOS_TERMO} />
      </Form.Item>

      <Form.Item
        name="fiscalizacaoFronteira"
        label="Fiscalização Fronteira"
        rules={[
          {
            required: true,
            message: "Selecione se houve Fiscalização fronteira",
          },
        ]}
      >
        <Radio.Group>
          {FISCALIZACAO_FRONTEIRA.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Atuado"
        name="atuado"
        rules={[{ required: true, message: "Escolha alguma opção" }]}
      >
        <Radio.Group onChange={handleChangeButtonRadio}>
          {TIPOS_ATUADO.map((at, ind) => (
            <Radio key={ind} value={at.value}>
              {at.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Relação"
        name="tipoRepresentante"
        rules={[
          { required: !isDisableRelacao, message: "Escolha alguma Relação" },
        ]}
      >
        <Radio.Group disabled={isDisableRelacao}>
          {TIPOS_REPRESENTANTES.map((representante, ind) => (
            <Radio key={ind} value={representante.value}>
              {representante.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <SearchFiscalizadoByName
        handleSearchFiscalizado={handleSearchFiscalizado}
        form={form}
        setMunicipioFiscalizadoOptions={setMunicipioFiscalizadoOptions}
        handleResetRegistro={handleResetFieldsForm}
        fiscalizadoSignature={fiscalizadoSignature}
        setFiscalizadoSignature={setFiscalizadoSignature}
      />

      <Form.Item
        label="CPF/CNPJ do Fiscalizado"
        name="fiscalizadoCpf"
        rules={[
          { required: true, message: "Digite o CPF/CNPJ do fiscalizado" },
        ]}
      >
        <Input.Search
          onSearch={handleSearchFiscalizado}
          onChange={handleChangeInputCpfCnpj}
          status={statusError ? "error" : ""}
          maxLength={18}
          allowClear
          enterButton="Buscar"
          placeholder="Digite o CPF/CNPJ"
          loading={isLoading}
          onKeyDown={(e) => handleKeyPress(e, "fiscalizadoNome")}
        />
      </Form.Item>

      <Form.Item
        label="Nome do Fiscalizado"
        name="fiscalizadoNome"
        rules={[
          {
            required: typeDocument === "cpf",
            message: "Digite o nome do fiscalizado",
          },
        ]}
      >
        <Input
          style={{ minWidth: "190px" }}
          disabled={!isValidDocument}
          onKeyDown={(e) => handleKeyPress(e, "inscricaoEstadualFiscalizado")}
        />
      </Form.Item>

      <Form.Item label="RG do Fiscalizado" name="inscricaoEstadualFiscalizado">
        <Input
          onKeyDown={(e) => handleKeyPress(e, "renasemFiscalizado")}
          maxLength={12}
          style={{ minWidth: "190px" }}
          disabled={!isValidDocument}
          onChange={(e) => {
            const { value } = e.target;
            form.setFieldValue("inscricaoEstadual", rgMask(value));
          }}
        />
      </Form.Item>

      <Form.Item label="RENASEM do Fiscalizado" name="renasemFiscalizado">
        <Input
          onKeyDown={(e) => handleKeyPress(e, "enderecoFiscalizado")}
          style={{ minWidth: "190px" }}
          disabled={!isValidDocument}
          maxLength={13}
          onChange={(e) => {
            form.setFieldValue(
              "renasemFiscalizado",
              renasemMask(e.target.value).toUpperCase()
            );
          }}
        />
      </Form.Item>

      <Form.Item label="Endereço do Fiscalizado" name="enderecoFiscalizado">
        <Input
          style={{ minWidth: "190px" }}
          disabled={!isValidDocument}
          onKeyDown={(e) => handleKeyPress(e, "ufFiscalizado")}
        />
      </Form.Item>

      <Form.Item
        label="UF"
        name="ufFiscalizado"
        rules={[{ required: true, message: "Selecione uma UF" }]}
      >
        <Select
          style={{ minWidth: "190px" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          disabled={!isValidDocument}
          onChange={handleChangeUF}
          allowClear
        >
          {listaUfs?.map((uf) => (
            <Select.Option key={uf.id} value={uf.id}>
              {uf.sigla}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Município do Fiscalizado"
        name="municipioFiscalizadoId"
        rules={[{ required: true, message: "Selecione um municipio" }]}
      >
        <Select
          style={{ minWidth: "190px" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          disabled={!isValidDocument}
          allowClear
        >
          {municipioFiscalizadoOptions.map((municipio) => (
            <Select.Option key={municipio.id} value={municipio.id}>
              {`${municipio.nome}-${municipio.uf.sigla}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Email do Fiscalizado" name="emailFiscalizado">
        <Input
          style={{ minWidth: "190px" }}
          disabled={!isValidDocument}
          onKeyDown={(e) => handleKeyPress(e, "telefoneFiscalizado")}
        />
      </Form.Item>

      <Form.Item label="Telefone do Fiscalizado" name="telefoneFiscalizado">
        <Input
          onKeyDown={(e) => handleKeyPress(e, "cepFiscalizado")}
          maxLength={15}
          style={{ minWidth: "190px" }}
          disabled={!isValidDocument}
          onChange={(e) =>
            form.setFieldValue(
              "telefoneFiscalizado",
              telefoneMask(e.target.value || "")
            )
          }
        />
      </Form.Item>

      <Form.Item label="CEP do Fiscalizado" name="cepFiscalizado">
        <Input
          onKeyDown={(e) => handleKeyPress(e, "propriedadeId")}
          maxLength={10}
          style={{ minWidth: "190px" }}
          disabled={!isValidDocument}
          onChange={(e) =>
            form.setFieldValue("cepFiscalizado", cepMask(e.target.value || ""))
          }
        />
      </Form.Item>

      <Form.Item label="Propriedade" name="propriedadeId">
        <Select
          allowClear
          style={{ minWidth: "190px" }}
          onChange={handleChangePropriedade}
          disabled={
            !isValidDocument ||
            !typePropEstab ||
            (typePropEstab !== "propriedade" &&
              typePropEstab !== "transito/profissional")
          }
        >
          {propriedadesOptions?.map((prop) => {
            return (
              <Select.Option key={prop.id} value={prop.id}>
                {`${prop.codigo}-${prop.nome}`}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Propriedade / Estabelecimento" name="propEstab">
        <Input
          onKeyDown={(e) => handleKeyPress(e, "municipioFiscalizacaoId")}
          style={{ minWidth: "190px" }}
          disabled={
            !isValidDocument || isPropOrEstabSelected || !!idEstabelecimento
          }
        />
      </Form.Item>

      <Form.Item
        label={`Municipio da Fiscalização`}
        name="municipioFiscalizacaoId"
        rules={[{ required: true, message: "Selecione um municipio" }]}
      >
        <Select
          style={{ minWidth: "190px" }}
          disabled={
            !isValidDocument ||
            atividadesTif.some((atv) => Boolean(atv.armadilha))
          }
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          onChange={(e) => setMunicipioFiscalizacaoId(e)}
        >
          {municipiosMt.map((municipio) => (
            <Select.Option key={municipio.id} value={municipio.id}>
              {`${municipio.nome}-${municipio.uf.sigla}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {labelPropOrEstab !== "transito/profissional" && (
        <Form.Item
          label={`CEP  ${
            labelPropOrEstab === "Estabelecimento" ? "do" : "da"
          }  ${
            labelPropOrEstab === "transito/profissional"
              ? "fiscalização"
              : labelPropOrEstab
          }`}
          name="cepPropriedade"
        >
          <Input
            onKeyDown={(e) => handleKeyPress(e, "enderecoPropriedade")}
            maxLength={10}
            style={{ minWidth: "190px" }}
            disabled={!isValidDocument}
            onChange={(e) =>
              form.setFieldValue(
                "cepPropriedade",
                cepMask(e.target.value || "")
              )
            }
          />
        </Form.Item>
      )}

      <Form.Item
        label={`Endereço  ${
          labelPropOrEstab === "Estabelecimento" ? "do" : "da"
        } ${
          labelPropOrEstab === "transito/profissional"
            ? "fiscalização"
            : labelPropOrEstab
        }`}
        name="enderecoPropriedade"
      >
        <Input
          style={{ minWidth: "190px" }}
          disabled={!isValidDocument}
          onKeyDown={(e) => handleKeyPress(e, "button-localizacao-atual")}
        />
      </Form.Item>

      <Tooltip title="Usar localização atual">
        <Button
          id="button-localizacao-atual"
          disabled={!isValidDocument}
          onClick={handleCompleteGeoLocation}
          onKeyDown={(e) => handleKeyPress(e, "latGrauPropriedade")}
        >
          <EnvironmentOutlined />
        </Button>
      </Tooltip>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
        >
          <Typography
            style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
          >
            Latitude:
          </Typography>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
          <Form.Item
            label="Grau"
            name="latGrauPropriedade"
            labelAlign="left"
            style={{ maxWidth: "150px" }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "latMinPropriedade")}
              maxLength={5}
              onChange={(e) => {
                form.setFieldValue(
                  "latGrauPropriedade",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>

          <Form.Item
            label="Minutos"
            name="latMinPropriedade"
            // rules={[{ type: "number", min: 0, max: 60 }]}
            style={{ maxWidth: "150px" }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "latSegPropriedade")}
              maxLength={5}
              onChange={(e) => {
                form.setFieldValue(
                  "latMinPropriedade",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>

          <Form.Item
            label="Segundos"
            name="latSegPropriedade"
            // rules={[{ type: "number", min: 0, max: 60 }]}
            style={{ maxWidth: "150px" }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "longGrauPropriedade")}
              maxLength={5}
              onChange={(e) => {
                form.setFieldValue(
                  "latSegPropriedade",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
        >
          <Typography
            style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
          >
            Longitude:
          </Typography>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
          <Form.Item
            label="Grau"
            name="longGrauPropriedade"
            labelAlign="left"
            style={{ maxWidth: "150px" }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "longMinPropriedade")}
              maxLength={5}
              onChange={(e) => {
                form.setFieldValue(
                  "longGrauPropriedade",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>

          <Form.Item
            label="Minutos"
            name="longMinPropriedade"
            labelAlign="left"
            style={{ maxWidth: "150px" }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "longSegPropriedade")}
              maxLength={5}
              onChange={(e) => {
                form.setFieldValue(
                  "longMinPropriedade",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>

          <Form.Item
            label="Segundos"
            name="longSegPropriedade"
            labelAlign="left"
            style={{ maxWidth: "150px" }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "dataFiscalizar")}
              maxLength={5}
              onChange={(e) => {
                form.setFieldValue(
                  "longSegPropriedade",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>
        </div>
      </div>

      <Form.Item label="Data de Fiscalização: " name="dataFiscalizar">
        <DatePicker
          disabledDate={(date) => {
            if (date.valueOf() > Date.now()) {
              return true;
            }
            return false;
          }}
          placeholder="data da fiscalização"
          format={"DD/MM/YYYY"}
          locale={locale}
        />
      </Form.Item>
    </Fragment>
  );
}

import { useDispatch, useSelector } from "react-redux";
import { menuAction } from "@store/actions/menuAction";
import { getMenuOptions, getMenuSelectedKey } from "@store/feature/menuSlice";

export const useMenu = () => {
  const appDispatch = useDispatch();
  const { updateKey } = menuAction;
  const menuSelected = useSelector(getMenuSelectedKey);
  const listaMenus = useSelector(getMenuOptions);

  const atualizarMenuSelected = (key: string[]) => {
    appDispatch(updateKey(key));
  };

  return { atualizarMenuSelected, menuSelected, listaMenus };
};

import {
  Button,
  Col,
  Empty,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { Fragment, useEffect, useState } from "react";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { cnpj, cpf } from "cpf-cnpj-validator";
import confirm from "antd/lib/modal/confirm";
import { useServidores } from "@hooks/useServidores";
import { IColumnsServidores } from "@interfaces/IColumnsServidores";
import { openNotification } from "../Notifications";
import { apiService } from "@services/api";

export function TabelaServidoresForm({ form }: { form: FormInstance }) {
  const {
    servidoresSelecionados,
    selecionarServidor,
    removerServidorSelecionado,
    getServidorPorMatricula,
  } = useServidores();

  const [isErrorInput, setIsErrorInput] = useState(false);

  const [servidoresNaGrid, setServidoresNaGrid] = useState<
    IColumnsServidores[]
  >([]);

  const columns: ColumnsType<IColumnsServidores> = [
    {
      title: "Matrícula",
      dataIndex: "key",
      // responsive: ['sm']
    },
    {
      title: "CPF / CNPJ",
      dataIndex: "cpfCnpj",
      // responsive: ['md'],
      render: (document: string) => (
        <p>
          {document.length > 14 ? cnpj.format(document) : cpf.format(document)}
        </p>
      ),
    },
    {
      title: "Nome",
      dataIndex: "nome",
      // responsive: ['lg']
    },
    {
      title: "Ações",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button danger onClick={(e) => modalDeleteConfirm(e, record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handleSearchServidor = async ({ matricula }: { matricula: string }) => {
    const servidorOff = getServidorPorMatricula(matricula);

    //validdar se o documento esta valido
    const hasCpfInServidorGrid = servidoresSelecionados.some(
      (serv) => serv.servidor.matricula === matricula
    );

    if (hasCpfInServidorGrid) {
      openNotification({
        type: "error",
        message: "Erro ao buscar Servidor",
        description: "Servidor ja foi adicionado na tabela",
      });
      setIsErrorInput(true);
      return;
    }

    setIsErrorInput(false);

    if (servidorOff) {
      selecionarServidor({
        assinatura: null,
        servidor: {
          cpfCnpj: servidorOff.pessoa.cpfCnpj,
          id: servidorOff.id,
          inscricaoEstadual: servidorOff.pessoa.inscricaoEstadual,
          matricula: servidorOff.matricula,
          nome: servidorOff.pessoa.nome,
        },
      });

      form.resetFields();
      return;
    }

    if (navigator.onLine) {
      const servidorOnline = await apiService.servidores.byMatricula(matricula);
      if (servidorOnline) {
        selecionarServidor({
          assinatura: null,
          servidor: {
            cpfCnpj: servidorOnline.pessoa.cpfCnpj,
            id: servidorOnline.id,
            inscricaoEstadual: servidorOnline.pessoa.inscricaoEstadual,
            matricula: servidorOnline.matricula,
            nome: servidorOnline.pessoa.nome,
          },
        });
      }
    }

    openNotification({
      type: "error",
      message: "Erro ao buscar Servidor",
      description: "Servidor não encontrado.",
    });
    return;
  };

  const handleChangeMatricula = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const servidorOff = getServidorPorMatricula(value);

    form.setFieldValue("matricula", value.replace(/\D/g, ""));

    if (servidorOff) {
      form.setFieldValue("servidorNome", servidorOff.pessoa.nome);
      return;
    }

    if (navigator.onLine) {
      const servidorOnline = await apiService.servidores.byMatricula(value);

      if (servidorOnline) {
        form.setFieldValue("servidorNome", servidorOnline.pessoa.nome);
      } else {
        form.setFieldValue("servidorNome", "");
      }

      if (form.getFieldValue("servidorNome")) return;
    }

    form.setFieldValue("servidorNome", "");
    return;
  };

  const modalDeleteConfirm = (
    e: React.MouseEvent<HTMLElement>,
    record: IColumnsServidores
  ) => {
    e.preventDefault();
    confirm({
      title: `Tem certeza que deseja remover o servidor ${record.nome} ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        removerServidorSelecionado(record.key);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    const servs = servidoresSelecionados.map((serv) => {
      return {
        key: serv.servidor.matricula,
        nome: serv.servidor.nome,
        cpfCnpj: serv.servidor.cpfCnpj,
      };
    });

    setServidoresNaGrid(servs);
  }, [servidoresSelecionados]);

  return (
    <Fragment>
      <Form
        labelCol={{ span: 20, flex: "150px" }}
        wrapperCol={{ flex: 1, span: 14 }}
        labelWrap
        labelAlign="left"
        form={form}
        onFinish={handleSearchServidor}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Typography.Title level={3}>Servidores</Typography.Title>
        <Form.Item
          label="Matricula"
          name="matricula"
          rules={[
            {
              required: true,
              message: "Digite o código de matricula do servidor",
            },
          ]}
        >
          <Input
            status={isErrorInput ? "error" : ""}
            maxLength={14}
            name="matricula"
            style={{ minWidth: "190px" }}
            onChange={handleChangeMatricula}
          />
        </Form.Item>

        <Form.Item label="Nome" name="servidorNome">
          <Input disabled={true} style={{ minWidth: "190px" }} />
        </Form.Item>

        <Row justify="center">
          <Space direction="vertical" size={10}>
            <Button type="primary" htmlType="submit">
              Adicionar Servidor
            </Button>
            <Col />
          </Space>
        </Row>

        <Col span={24}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty
                  description={"Sem dados"}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
            }}
            dataSource={servidoresNaGrid}
            columns={columns}
            style={{
              overflowX: "auto",
              paddingBottom: "20px",
            }}
          />
        </Col>
      </Form>
    </Fragment>
  );
}

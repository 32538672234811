import { Fragment, useEffect, useRef, useState } from "react";
import { Form, Button, Divider, Modal, message } from "antd";
import { openNotification } from "@components/Notifications";
import { apiService } from "@services/api";
import moment from "moment";
import { generateId } from "@functions/generateId";
import { DadosGeraisTCA } from "./components/DadosGeraisTCA";
import { AmostrasTermo } from "./components/AmostrasTermo";
import { OrigemTCA } from "./components/OrigemTCA";
import { ICultivares } from "@interfaces/ICultivares";
import { AssinaturasTermo } from "./components/AssinaturasTermo";
import { Amostrador } from "./components/Amostrador";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { PreviaTermoColeta } from "./components/PreviaTermoColeta";
import { useQueriesTermoColeta } from "./hooks/useQueriesTermoColeta";
import fs from "file-saver";
import { TitleTermo } from "@styles/TitleTermo";
import { checkDevice } from "@functions/checkDevice";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { useTermoColeta } from "./hooks/useTermoColeta";
import { IFormValuesTermoColeta } from "@interfaces/IFormValuesTermoColeta";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import { useForms } from "@hooks/useForms";
import { useUle } from "@hooks/useUle";
import { useDadosTermoColeta } from "@hooks/useDadosTermoColeta";
import { useActionsTermoColeta } from "./hooks/useActionsTermoColeta";
import { AvaliacaoTermoAditivoTCA } from "./components/AvaliacaoTermoAditivoTCA";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { INITIAL_VALUES_ASSINATURA } from "@constants/INITIAL_VALUES_ASSINATURA";

export function TermoColeta() {
  const [formColeta] = Form.useForm<IFormValuesTermoColeta>();
  const [formDadosAmostra] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const inputRefTipoAmostra = useRef<any>(null);
  const refAmostrador = useRef<any>(null);
  const { categorias, estadios, materialColetado } = useQueriesTermoColeta();
  const { messageSubmitFailed } = useForms();
  const { removerTodasAmostras, listaAmostras } = useDadosTermoColeta();
  const { listaUles } = useUle();
  const { adicionarTermoColeta, adicionarTermoColetaByFiltro } =
    useActionsTermoColeta();
  const {
    getAmostrador,
    getEspecieSelected,
    getTermoExistente,
    getFormSubmit,
  } = useTermoColeta();
  const [isOpenViewAmostras, setIsOpenViewAmostras] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [cultivaresOptions, setCultivaresOptions] = useState<ICultivares[]>([]);
  const [assinaturaAmostrador, setAssinaturaAmostrador] = useState("");
  const [statusAssinaturaColeta, setStatusAssinaturaColeta] = useState<
    CheckboxValueType[]
  >([]);
  const [assinaturaFiscalizado, setAssinaturaFiscalizado] = useState(
    INITIAL_VALUES_ASSINATURA
  );

  const handleSubmit = async (values: IFormValuesTermoColeta) => {
    const keyMessage = "termo-coleta";
    const amostrador = getAmostrador(
      values.matriculaAmostrador.replace(/\D/g, "") || ""
    );
    const especieSelected = getEspecieSelected(values.especieId);
    const uleSelect = listaUles.filter(
      (ule) =>
        ule.municipio.id === values.municipioColetaId &&
        ule.tipoUnidade === "UL"
    )[0];
    const numTermoColeta = `${generateId()}/${uleSelect.codigo.slice(
      uleSelect.codigo.length - 3
    )}/${moment().year()}`;

    const tifExiste = await getTermoExistente(values.numeroTIF);

    //VALIDACAO
    if (!amostrador) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description: "Preencha os dados do amostrador.",
      });

      return;
    }

    if (
      (!assinaturaFiscalizado.nomeAssinante ||
        !assinaturaFiscalizado.cpfAssinante ||
        !assinaturaFiscalizado.funcaoAssinante) &&
      statusAssinaturaColeta.length === 0
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description:
          "Preencha os dados do assinante para salvar o Termo de coleta",
      });

      return;
    }

    if (
      statusAssinaturaColeta[0] === ASSINATURA_FISICA &&
      (!assinaturaFiscalizado.nomeAssinante ||
        !assinaturaFiscalizado.cpfAssinante ||
        !assinaturaFiscalizado.funcaoAssinante)
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description: "Preencha os dados do assinante",
      });
      return;
    }

    if (
      getStatusAssinatura(statusAssinaturaColeta) === "ASSINADO_DIGITALMENTE" &&
      (!assinaturaAmostrador || !assinaturaFiscalizado.assinatura)
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description: "Preencha as assinaturas pendentes",
      });

      return;
    }

    if (
      (getStatusAssinatura(statusAssinaturaColeta) === "AUSENTE" ||
        getStatusAssinatura(statusAssinaturaColeta) === "RECUSADO") &&
      !assinaturaAmostrador
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description: "Preencha a assinatura do amostrador",
      });

      return;
    }

    if (listaAmostras.length === 0) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Coleta",
        description: "Adicione ao menos uma amostra ao Termo.",
      });
      return;
    }

    if (!tifExiste && values.numeroTIF) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Coleta",
        description:
          "Selecione um Termo de Fiscalização válido para concluir o Termo.",
      });

      return;
    }

    const formValues = await getFormSubmit({
      assinaturaFiscalizado,
      statusAssinaturaColeta,
      assinaturaAmostrador,
      values,
      especieSelected,
      numTermoColeta,
      amostrador,
    });

    if (navigator.onLine) {
      setIsSaving(true);
      message.loading({
        key: keyMessage,
        duration: 0,
        content: "Salvando Termo de Coleta...",
      });
      await apiService.termoColeta
        .enviar(formValues)
        .then((res) => {
          adicionarTermoColetaByFiltro({ ...formValues, id: res.data.id });
          setIsSaving(false);
          message.destroy(keyMessage);
          openNotification({
            type: "success",
            message: "Termo de Coleta criado com sucesso!",
            description: "Termo salvo na base de dados",
          });
        })
        .catch((erro) => {
          console.log(erro);
          setIsSaving(false);
          message.destroy(keyMessage);
          openNotification({
            type: "error",
            message: "Não foi possivel criar Termo de Coleta",
            description: erro.response.data.detail,
          });
        });
    } else {
      adicionarTermoColeta(formValues);
      // MODAL DE CONFIRMAÇÃO SE O USUARIO QUISER BAIXAR UM BACKUP
      modal.info({
        title: `Gostaria de baixar um backup do Termo de Coleta?`,
        icon: <ExclamationCircleOutlined />,
        okText: "Sim",
        onOk: () => {
          let blob = new Blob([JSON.stringify(formValues)], {
            type: "text/plain;charset=utf-8",
          });
          fs.saveAs(
            blob,
            `Termo de Coleta ${formValues.termoColetaAmostra}.txt`,
            {
              autoBom: true,
            }
          );
          openNotification({
            type: "success",
            message: "Termo de Coleta criado com sucesso!",
            description:
              "Salvamento do Termo de Coleta pendente, para quando aplicativo houver conexão com a internet.",
          });
        },
      });
    }

    //RESETANDO CAMPOS DO FORMULARIO
    formColeta.resetFields();
    setIsOpenViewAmostras(false);
    formDadosAmostra.resetFields();
    removerTodasAmostras();
    refAmostrador.current?.clear();
    setAssinaturaFiscalizado(INITIAL_VALUES_ASSINATURA);
    setAssinaturaAmostrador("");
  };

  const cancelarAlteracoes = () => {
    confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        //RESETANDO CAMPOS DO FORMULARIO
        formColeta.resetFields();
        setIsOpenViewAmostras(false);
        formDadosAmostra.resetFields();
        removerTodasAmostras();
        refAmostrador.current?.clear();
        setAssinaturaFiscalizado(INITIAL_VALUES_ASSINATURA);
        setAssinaturaAmostrador("");
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  useEffect(() => {
    removerTodasAmostras();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {contextHolder}
      <TitleTermo>Termo de Coleta de Amostras</TitleTermo>
      <Divider />
      <Form
        initialValues={{ dataColeta: moment(), isTermoAditivo: false }}
        form={formColeta}
        id="form-coleta-amostras"
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "200px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        onFinish={handleSubmit}
        onFinishFailed={messageSubmitFailed}
        onReset={cancelarAlteracoes}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        {/* <AvaliacaoTermoAditivoTCA form={formColeta} /> */}

        <Amostrador formColeta={formColeta} />

        <Divider />
        <OrigemTCA
          formColeta={formColeta}
          assinaturaFiscalizado={assinaturaFiscalizado}
          setAssinaturaFiscalizado={setAssinaturaFiscalizado}
        />

        <Divider />

        <DadosGeraisTCA
          formColeta={formColeta}
          inputRefTipoAmostra={inputRefTipoAmostra}
          setCultivares={setCultivaresOptions}
          setIsOpenViewAmostras={setIsOpenViewAmostras}
          formDadosAmostra={formDadosAmostra}
        />
      </Form>

      {isOpenViewAmostras && (
        <Fragment>
          <AmostrasTermo
            formDadosAmostra={formDadosAmostra}
            tipoAmostra={formColeta.getFieldValue("tipoAmostra")}
            cultivares={cultivaresOptions}
            materialColetadoOptions={materialColetado.data}
            estadioFenologicoOptions={estadios.data}
            categoriaOptions={categorias.data}
          />

          <AssinaturasTermo
            assinaturaFiscalizado={assinaturaFiscalizado}
            assinaturaAmostrador={assinaturaAmostrador}
            setAssinaturaFiscalizado={setAssinaturaFiscalizado}
            setAssinaturaAmostrador={setAssinaturaAmostrador}
            statusAssinatura={statusAssinaturaColeta}
            setStatusAssinatura={setStatusAssinaturaColeta}
            tipoColeta={formColeta.getFieldValue("tipoAmostra")}
            formRegistro={formColeta}
            refAmostrador={refAmostrador}
          />
          <ButtonFooterContainer>
            <Button
              form="form-coleta-amostras"
              htmlType="reset"
              disabled={isSaving}
            >
              Cancelar
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              form="form-coleta-amostras"
              disabled={isSaving}
            >
              Salvar
            </Button>

            {!checkDevice() && (
              <PreviaTermoColeta
                formColeta={formColeta}
                getAmostrador={getAmostrador}
                assinaturaFiscalizado={assinaturaFiscalizado}
              />
            )}
          </ButtonFooterContainer>
        </Fragment>
      )}
    </Fragment>
  );
}

import { AuthProviderProps } from "react-oidc-context";
import { api } from "./api";
import { getPayloadUser } from "@functions/getPayloadUser";
import { openNotification } from "@components/Notifications";
import { WebStorageStateStore } from "oidc-client-ts";

export const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_URL_LOGIN || "",
  client_id: process.env.REACT_APP_CLIENT_ID || "",
  redirect_uri: process.env.REACT_APP_REDIRECT_URI || "",
  loadUserInfo: true,
  accessTokenExpiringNotificationTimeInSeconds: 10,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_REDIRECT_URI + "/home",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: (user) => {
    if (user) {
      const infoUser = getPayloadUser(user.access_token || "");

      api
        .get(`/vegetal/permissoes/${infoUser?.cpf}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        })
        .then((res) => {
          localStorage.setItem("access_token", user.access_token || "");
          localStorage.setItem("push-notification", "true");
          localStorage.setItem("notification-adequar-tif", "true");
          window.location.href = process.env.REACT_APP_REDIRECT_URI + "/home";
        })
        .catch((erro) => {
          console.log(erro);

          if (!erro.response.data) {
            openNotification({
              type: "error",
              message: "SISDEV Mobile está offline",
            });

            return;
          }

          openNotification({
            type: "error",
            message: erro.response.data.detail,
          });

          localStorage.removeItem("access_token");
          localStorage.setItem("error_autenticacao", "true");
        });
      return;
    }
  },
};

import { Button, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { IPerfil } from "@interfaces/IPerfil";
import styles from "./style.module.css";
import { apiService } from "@services/api";
import { openNotification } from "@components/Notifications";
import { perfilAction } from "@store/actions/perfilAction";
import { getPerfis } from "@store/feature/perfilSlice";

interface IModalEditarPerfilProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  perfil: IPerfil | null;
}

export function ModalEditarPerfil({
  isOpen,
  setIsOpen,
  perfil,
}: IModalEditarPerfilProps) {
  const [nomeNovo, setNomeNovo] = useState("");
  const appDispatch = useDispatch();
  const { updateNome } = perfilAction;
  const listaPerfis = useSelector(getPerfis);

  const handleClose = () => {
    setIsOpen(false);
    setNomeNovo("");
  };

  const handleSubmit = async () => {
    if (!perfil) return;
    const perfilEditado = { nome: nomeNovo, permissoes: perfil.permissoes };

    //VALIDACOES
    if (!nomeNovo) {
      openNotification({
        type: "error",
        message: "Não foi possivel editar Perfil.",
        description: "Digite um nome de Perfil válido.",
      });
      return;
    }

    if (listaPerfis.some((perfil) => perfil.nome === nomeNovo)) {
      openNotification({
        type: "error",
        message: "Não foi possivel editar Perfil.",
        description: "Nome de Perfil já existente.",
      });
      return;
    }

    await apiService.perfis.editar(perfil.id, perfilEditado).then((res) => {
      appDispatch(updateNome(perfil.id, nomeNovo));
      openNotification({
        type: "success",
        message: "Perfil Editado com Sucesso!",
      });
    });

    handleClose();
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNomeNovo(value);
  };

  return (
    <Modal
      open={isOpen}
      centered={false}
      onCancel={handleClose}
      keyboard={true}
      maskClosable={false}
      title={<div className={styles.title}>Editar Perfil {perfil?.nome}</div>}
      footer={[
        <Button key="NAO" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button key="SIM" type="primary" onClick={handleSubmit}>
          Salvar
        </Button>,
      ]}
    >
      <div className={styles.content}>
        <label>Nome:</label>
        <Input
          placeholder="Digite o novo nome do Perfil"
          value={nomeNovo}
          onChange={handleChangeName}
        />
      </div>
    </Modal>
  );
}

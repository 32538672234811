import { useDispatch, useSelector } from "react-redux";
import { perfilAction } from "@store/actions/perfilAction";
import { IPerfil } from "@interfaces/IPerfil";
import { getPerfis } from "@store/feature/perfilSlice";

export const usePerfil = () => {
  const appDispatch = useDispatch();
  const { addItem } = perfilAction;
  const listaPerfis = useSelector(getPerfis);

  const adicionarPerfil = (perfil: IPerfil) => {
    appDispatch(addItem(perfil));
  };

  return { adicionarPerfil, listaPerfis };
};

import { useQuery } from "react-query";
import { apiService } from "@services/api";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactSignatureCanvas from "react-signature-canvas";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useRef, useState } from "react";
import { IAssinante } from "@interfaces/IAssinante";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Result,
  Select,
  Spin,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TitleTermo } from "@styles/TitleTermo";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { masks } from "@functions/mascaras";
import styles from "./style.module.css";
import { openNotification } from "@components/Notifications";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { termoColetaAction } from "@store/actions/termoColetaAction";
import { ModalAssinatura } from "@components/ModalAssinatura";
import { useActionsTermoColeta } from "../../hooks/useActionsTermoColeta";

type Destino = "" | "AMBOS" | "LASGAL";

export function EdicaoPosEnvioTermoColeta() {
  const { id } = useParams();
  const navigate = useNavigate();
  const appDispatch = useDispatch();
  const { cpfCnpjMask } = masks;
  const { updateAssinatura } = termoColetaAction;
  const refFiscalizado = useRef<any>(null);
  const { listaTermos, atualizarTermoColeta } = useActionsTermoColeta();

  const [isOpenModalFiscalizado, setIsOpenModalFiscalizado] = useState(false);
  const [motivoNaoAssinatura, setMotivoNaoAssinatura] = useState<
    CheckboxValueType[]
  >([]);
  const [fiscalizadoSignature, setFiscalizadoSignature] = useState<IAssinante>({
    assinatura: null,
    cpfAssinante: "",
    funcaoAssinante: "",
    nomeAssinante: "",
  });
  const [destino, setDestino] = useState<Destino>("");

  const { data, status } = useQuery({
    queryKey: "EDICAO_POS_ENVIO_TERMO_COLETA",
    queryFn: () => apiService.termoColeta.byId(parseInt(id || "")),
    initialData: listaTermos.find((termo) => termo.id === parseInt(id || "")),
    onSuccess: (data) => {
      if (data.destino === "AMBOS" || data.destino === "LASGAL") {
        setDestino(data.destino);
      }
    },
  });

  const handleBack = () => {
    navigate("/termo-coleta/listagem");
  };

  const cleanSignatureFields = (
    signature: ReactSignatureCanvas | null | undefined
  ) => {
    if (signature !== null && signature !== undefined) {
      signature.clear();
    }
  };

  const mudarStatusAssinaturaFiscalizado = (
    checkedValue: CheckboxValueType[]
  ) => {
    setMotivoNaoAssinatura(checkedValue);

    if (checkedValue[0] === "RECUSADO" || checkedValue[0] === "AUSENTE") {
      cleanSignatureFields(fiscalizadoSignature.assinatura);
      setFiscalizadoSignature({
        assinatura: null,
        cpfAssinante: "",
        funcaoAssinante: "",
        nomeAssinante: "",
      });
      return;
    }
  };

  const handleCloseModal = async () => {
    setIsOpenModalFiscalizado(false);
    setFiscalizadoSignature({
      ...fiscalizadoSignature,
      assinatura: refFiscalizado.current,
    });
  };

  const handleSubmit = async () => {
    if (!data) return;

    let dadosAux = {
      statusAssinatura: data.statusAssinatura,
      assinaturaFiscalizado: data.assinaturaFiscalizado,
      nomeAssinante: data.nomeAssinante,
      funcaoAssinante: data.funcaoAssinante,
      cpfAssinante: data.cpfAssinante,
      destino: data.destino,
    };

    if (
      data.statusAssinatura !== "ASSINADO_DIGITALMENTE" &&
      data.statusAssinatura !== "ASSINADO_FISICAMENTE"
    ) {
      if (
        (!fiscalizadoSignature.nomeAssinante ||
          !fiscalizadoSignature.cpfAssinante ||
          !fiscalizadoSignature.funcaoAssinante) &&
        motivoNaoAssinatura.length === 0
      ) {
        openNotification({
          type: "error",
          message: "Erro ao Atualizar Termo de Coleta",
          description:
            "Preencha os dados do assinante para atualizar o Termo de Coleta",
        });

        return;
      }

      if (motivoNaoAssinatura.length > 1) {
        openNotification({
          type: "error",
          message: "Erro ao Atualizar Termo de Coleta",
          description:
            "Preencha somente um motivo para não assinatura do fiscalizado",
        });
        return;
      }

      if (
        motivoNaoAssinatura.length === 0 &&
        (!fiscalizadoSignature.assinatura ||
          fiscalizadoSignature.assinatura?.toData().length === 0)
      ) {
        openNotification({
          type: "error",
          message: "Erro ao Atualizar Termo de Coleta",
          description:
            "Preencha a Assinatura do Fiscalizado para atualizar Termo de Coleta.",
        });
        return;
      }

      dadosAux = {
        ...dadosAux,
        assinaturaFiscalizado:
          motivoNaoAssinatura.length === 0
            ? fiscalizadoSignature?.assinatura
                ?.getTrimmedCanvas()
                .toDataURL() || ""
            : "",
        statusAssinatura: getStatusAssinatura(motivoNaoAssinatura),
        nomeAssinante: fiscalizadoSignature.nomeAssinante,
        cpfAssinante: fiscalizadoSignature.cpfAssinante.replace(/[^\d]+/g, ""),
        funcaoAssinante: fiscalizadoSignature.funcaoAssinante,
      };
    }

    if (destino) {
      dadosAux = { ...dadosAux, destino: destino };
    }

    if (navigator.onLine) {
      await apiService.termoColeta
        .update(dadosAux, parseInt(id || ""))
        .then((res) => {
          // console.log(res);
          atualizarTermoColeta({
            ...res,
            ...dadosAux,
          });
          openNotification({
            type: "success",
            message: `Termo de Coleta atualizado com sucesso!`,
          });

          handleBack();
        })
        .catch((error: any) => {
          console.log(error);
          openNotification({
            type: "error",
            message: `Não foi possivel atualizar Termo de Coleta`,
            description: error.response.data.detail,
          });
        });
    } else {
      appDispatch(updateAssinatura(dadosAux, parseInt(id || ""), data));
      openNotification({
        type: "success",
        message: "Termo de Coleta atualizado com sucesso!",
      });

      handleBack();
    }
  };

  if (status === "error") {
    return (
      <Fragment>
        <Result
          status="error"
          title="Erro ao Editar Termo de Coleta"
          subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
          extra={
            <Button onClick={() => navigate("/termo-coleta/listagem")}>
              Voltar
            </Button>
          }
        />
      </Fragment>
    );
  } else if (status === "idle" || status === "loading") {
    return (
      <Fragment>
        <div
          style={{
            minHeight: "80vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <TitleTermo>
          Editar Termo de Coleta {data.termoColetaAmostra}
        </TitleTermo>

        <Divider />

        {!["EXTERNO", "LSV"].includes(data.destino) && (
          <>
            <div className={styles.container}>
              <label className={styles["label-destino"]}>Destino:</label>
              <Select
                className={styles["select-destino"]}
                onChange={(e) => setDestino(e)}
                value={destino}
                options={[
                  {
                    label: "LASGAL",
                    value: "LASGAL",
                  },
                  {
                    label: "AMBOS",
                    value: "AMBOS",
                  },
                ]}
              />
            </div>
          </>
        )}

        {data.statusAssinatura !== "ASSINADO_DIGITALMENTE" &&
          data.statusAssinatura !== "ASSINADO_FISICAMENTE" && (
            <AssinaturaContainer>
              <div className="fiscalizado-signature-form">
                <div>
                  <div className="container-img-assinatura">
                    {fiscalizadoSignature.assinatura && (
                      <img
                        src={fiscalizadoSignature.assinatura
                          .getTrimmedCanvas()
                          .toDataURL("image/png")}
                        alt="assinatura Fiscalizado"
                        style={{
                          width: "90%",
                          height: "90%",
                        }}
                      />
                    )}
                  </div>

                  <Typography style={{ textAlign: "center" }}>
                    Assinatura Fiscalizado
                  </Typography>
                  <Button
                    disabled={motivoNaoAssinatura.length > 0}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setIsOpenModalFiscalizado(true);
                    }}
                  >
                    Clique aqui para assinar
                  </Button>

                  <div style={{ display: "flex", paddingTop: 10 }}>
                    <Checkbox.Group
                      onChange={mudarStatusAssinaturaFiscalizado}
                      value={motivoNaoAssinatura}
                    >
                      <Checkbox value="AUSENTE">Ausente</Checkbox>
                    </Checkbox.Group>

                    <Checkbox.Group
                      value={motivoNaoAssinatura}
                      onChange={mudarStatusAssinaturaFiscalizado}
                    >
                      <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                    </Checkbox.Group>
                  </div>

                  <div
                    className="dados-assinante-tif"
                    style={{
                      display: "flex",
                      gap: 10,
                      maxWidth: 600,
                    }}
                  >
                    <div>
                      <span>Nome:</span>
                      <Input
                        disabled={
                          motivoNaoAssinatura.length > 0 &&
                          motivoNaoAssinatura[0] !== "ASSINADO_FISICAMENTE"
                        }
                        name="nome_assinante"
                        value={fiscalizadoSignature?.nomeAssinante}
                        onChange={(e) =>
                          setFiscalizadoSignature({
                            ...fiscalizadoSignature,
                            nomeAssinante: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div>
                      <span>CPF:</span>
                      <Input
                        disabled={
                          motivoNaoAssinatura.length > 0 &&
                          motivoNaoAssinatura[0] !== "ASSINADO_FISICAMENTE"
                        }
                        name="cpf_assinante"
                        value={fiscalizadoSignature?.cpfAssinante}
                        onChange={(e) => {
                          setFiscalizadoSignature({
                            ...fiscalizadoSignature,
                            cpfAssinante: cpfCnpjMask(e.target.value),
                          });
                        }}
                      />
                    </div>

                    <div>
                      <span>Função:</span>
                      <Input
                        disabled={
                          motivoNaoAssinatura.length > 0 &&
                          motivoNaoAssinatura[0] !== "ASSINADO_FISICAMENTE"
                        }
                        name="funcao_assinante"
                        value={fiscalizadoSignature?.funcaoAssinante}
                        onChange={(e) =>
                          setFiscalizadoSignature({
                            ...fiscalizadoSignature,
                            funcaoAssinante: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AssinaturaContainer>
          )}

        <ButtonFooterContainer style={{ marginTop: 40 }}>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleSubmit} type="primary">
            Atualizar Termo
          </Button>
        </ButtonFooterContainer>

        <ModalAssinatura
          cleanSignature={cleanSignatureFields}
          handleClose={handleCloseModal}
          isOpen={isOpenModalFiscalizado}
          refAssinante={refFiscalizado}
          nomeAssinante="Fiscalizado"
        />
      </Fragment>
    );
  }
}

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { Button, Checkbox, Form } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { getPerfis } from "@store/feature/perfilSlice";
import { TitleTermo } from "@styles/TitleTermo";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import styles from "./style.module.css";
import { apiService } from "@services/api";
import { perfilAction } from "@store/actions/perfilAction";
import { openNotification } from "@components/Notifications";
import { useMenu } from "@hooks/useMenu";

export function GerenciarPerfil() {
  const { idPerfil } = useParams();
  const { listaMenus } = useMenu();
  const navigate = useNavigate();
  const appDispatch = useDispatch();
  const { updatePermissoes } = perfilAction;
  const [formPermissoes] = Form.useForm();
  const perfil = useSelector(getPerfis).filter(
    (auxPerf) => auxPerf.id === parseInt(idPerfil || "")
  )[0];

  const handleBack = () => {
    navigate("/perfis");
    formPermissoes.resetFields();
  };

  const handleSubmit = async (values: any) => {
    const permissoes = listaMenus.map((menu) => {
      const aux = {
        adicionar: isAllowed(values[`permissoes_${menu.id}`], "ADICIONAR"),
        editar: isAllowed(values[`permissoes_${menu.id}`], "EDITAR"),
        excluir: isAllowed(values[`permissoes_${menu.id}`], "EXCLUIR"),
        visualizar: isAllowed(values[`permissoes_${menu.id}`], "VISUALIZAR"),
        log: isAllowed(values[`permissoes_${menu.id}`], "LOG"),
      };

      if (
        !aux.adicionar &&
        !aux.excluir &&
        !aux.editar &&
        !aux.visualizar &&
        !aux.log
      ) {
        return undefined;
      }

      return {
        menu: {
          ativo: menu.ativo,
          codigo: menu.codigo,
          id: menu.id,
          nome: menu.nome,
          titulo: menu.titulo,
          url: menu.url,
        },
        ...aux,
      };
    });

    const data = {
      nome: perfil.nome,
      permissoes: permissoes.filter((perm) => perm),
    };

    await apiService.perfis.editar(perfil.id, data).then((res) => {
      appDispatch(updatePermissoes(perfil.id, data.permissoes));
      openNotification({
        type: "success",
        message: "Perfil atualizado com sucesso!",
      });
    });

    handleBack();
    window.location.reload();
  };

  const isAllowed = (permissoes: CheckboxValueType[], type: string) => {
    if (!permissoes) return false;

    if (permissoes.includes(type)) return true;

    return false;
  };

  useEffect(() => {
    listaMenus.forEach((menu) => {
      const checkbox: CheckboxValueType[] = [];
      const permissaoMenu = perfil.permissoes.filter(
        (perm) => perm.menu.id === menu.id
      )[0];

      if (!permissaoMenu) return;

      if (permissaoMenu.adicionar) checkbox.push("ADICIONAR");
      if (permissaoMenu.editar) checkbox.push("EDITAR");
      if (permissaoMenu.excluir) checkbox.push("EXCLUIR");
      if (permissaoMenu.visualizar) checkbox.push("VISUALIZAR");
      if (permissaoMenu.log) checkbox.push("LOG");

      formPermissoes.setFieldValue(`permissoes_${menu.id}`, checkbox);
    });

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <TitleTermo> Permissões {perfil.nome}</TitleTermo>

      <Form
        form={formPermissoes}
        className={styles["container-permissoes"]}
        onFinish={handleSubmit}
      >
        {listaMenus.map((menu) => {
          if (
            ["offlineMode", "inicio", "perfil", "config"].includes(menu.codigo)
          ) {
            return <Fragment key={menu.id}></Fragment>;
          }

          return (
            <div className={styles.content} key={menu.id}>
              <h1 className={styles["nome-menu"]}>{menu.nome}</h1>
              <Form.Item name={`permissoes_${menu.id}`}>
                <Checkbox.Group className={styles.checkbox}>
                  <Checkbox value="ADICIONAR" className={styles.options}>
                    Adicionar
                  </Checkbox>
                  <Checkbox value="EDITAR" className={styles.options}>
                    Editar
                  </Checkbox>
                  <Checkbox value="EXCLUIR" className={styles.options}>
                    Excluir
                  </Checkbox>
                  <Checkbox value="VISUALIZAR" className={styles.options}>
                    Visualizar
                  </Checkbox>
                  <Checkbox value="LOG" className={styles.options}>
                    Log
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </div>
          );
        })}

        <ButtonFooterContainer>
          <Button htmlType="reset" onClick={handleBack}>
            Cancelar
          </Button>
          <Button htmlType="submit" type="primary">
            Salvar
          </Button>
        </ButtonFooterContainer>
      </Form>
    </>
  );
}

import { Form, Select } from "antd";
import { Fragment } from "react";
import { useMunicipio } from "@hooks/useMunicipio";

interface IDadosFiscalizacaoProps {
  isTifExisting: boolean;
}

export function DadosFiscalizacaoTermoApreensaoLiberacao({
  isTifExisting,
}: IDadosFiscalizacaoProps) {
  const { municipiosMt } = useMunicipio();

  return (
    <Fragment>
      <Form.Item
        label="Municipio da Fiscalização"
        name="municipioFiscalizacaoId"
        rules={[{ required: true }]}
      >
        <Select
          disabled={!isTifExisting}
          style={{ minWidth: "190px" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          // disabled={!isValidDocument}
          allowClear
        >
          {municipiosMt.map((municipio) => (
            <Select.Option key={municipio.id} value={municipio.id}>
              {`${municipio.nome}-${municipio.uf.sigla}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Fragment>
  );
}

import { openNotification } from "@components/Notifications";

export const tifAtividadeError = () => {
  openNotification({
    type: "info",
    message: "Não foi possivel salvar TIF",
    description:
      "Responda todas as perguntas pendentes do questionário de atividades.",
  });
};

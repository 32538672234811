import { useDispatch, useSelector } from "react-redux";
import { getPersistedAtTif } from "@store/feature/tifSlice";
import { tifAction } from "@store/actions/tifAction";
import {
  getAtividades,
  getRespostasAtividades,
} from "@store/feature/atividadeSlice";
import { atividadeAction } from "@store/actions/atividadeAction";
import { IFormAtividades } from "@interfaces/IFormAtividades";
import { IRespostaAtividade } from "@interfaces/IRespostaAtividade";

export const useDadosTif = () => {
  const tifPersistedAtItems = useSelector(getPersistedAtTif);
  const { addTimePersistedAtItems, removeTimePersistedAtItems } = tifAction;
  const appDispatch = useDispatch();
  const atividadesTif = useSelector(getAtividades);
  const respostasAtividades = useSelector(getRespostasAtividades);
  const {
    addAtividades,
    addRespostasAtividade,
    removeAllAtividades,
    removeAtividades,
    updateAtividade,
  } = atividadeAction;

  const adicionarPersistedAtTif = (time: number) => {
    appDispatch(addTimePersistedAtItems(time));
  };

  const removerPersistedAtTif = () => {
    appDispatch(removeTimePersistedAtItems());
  };

  const adicionarAtividade = (atividade: IFormAtividades) => {
    appDispatch(addAtividades(atividade));
  };

  const adicionarRespostaAtividade = (respostas: IRespostaAtividade[]) => {
    appDispatch(addRespostasAtividade(respostas));
  };

  const atualizarAtividade = (atividade: IFormAtividades) => {
    appDispatch(updateAtividade(atividade));
  };

  const removerTodasAtividades = () => {
    appDispatch(removeAllAtividades());
  };

  const removerAtividade = (key: number, atividade: IFormAtividades) => {
    appDispatch(removeAtividades(key, atividade));
  };

  return {
    tifPersistedAtItems,
    adicionarPersistedAtTif,
    removerPersistedAtTif,
    atividadesTif,
    adicionarAtividade,
    adicionarRespostaAtividade,
    removerTodasAtividades,
    removerAtividade,
    atualizarAtividade,
    respostasAtividades,
  };
};

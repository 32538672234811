import moment from "moment";
import { openNotification } from "@components/Notifications";
import { generateId } from "@functions/generateId";
import { useUle } from "./useUle";

export const useForms = () => {
  const { listaUles } = useUle();

  const messageSubmitFailed = () => {
    openNotification({
      type: "error",
      message: "Campos em branco",
      description: "Termine de preencher todos os campos obrigatórios",
    });
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const gerarNumeroTermo = (municipioId: number | "" | undefined) => {
    const ule = listaUles.find(
      (ule) => ule.municipio.id === municipioId && ule.tipoUnidade === "UL"
    );

    if (!ule) return `${generateId()}/000/${moment().year()}`;

    const numTermo = `${generateId()}/${ule.codigo.slice(
      ule.codigo.length - 3
    )}/${moment().year()}`;

    return numTermo;
  };

  return { messageSubmitFailed, gerarNumeroTermo };
};

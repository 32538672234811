import { Button, List, Modal } from "antd";
import { saveAs } from "file-saver";
import styles from "./styles.module.css";
import { IAnexos } from "@interfaces/IAnexos";
import { openNotification } from "../Notifications";

interface Props {
  isOpenModal: boolean;
  anexos: IAnexos[];
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalVisualizarAnexos({
  isOpenModal,
  setIsOpenModal,
  anexos,
}: Props) {
  return (
    <Modal
      title="Anexos"
      open={isOpenModal}
      onCancel={() => setIsOpenModal(false)}
      footer={[
        <Button
          key="fechar"
          type="primary"
          onClick={() => setIsOpenModal(false)}
        >
          Fechar
        </Button>,
      ]}
    >
      <List
        bordered
        dataSource={anexos}
        renderItem={(item, ind) => (
          <List.Item key={ind}>
            <button
              className={styles["button-anexos"]}
              onClick={() => {
                try {
                  saveAs(item.arquivo, item.nomeArquivo);
                } catch (error) {
                  console.error(error);
                  openNotification({
                    type: "error",
                    message: "Não é possivel baixar anexos no momento.",
                  });
                }
              }}
            >
              {item.nomeArquivo}
            </button>
          </List.Item>
        )}
      />
    </Modal>
  );
}

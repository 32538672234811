import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";

export const ordenarPerguntasAtividade = (
  arrayPerguntas: IPerguntaAtividade[]
) => {
  const auxPergunta: IPerguntaAtividade[] = arrayPerguntas.sort((a, b) => {
    if (a.order > b.order) return 1;
    return -1;
  });

  return auxPergunta;
};

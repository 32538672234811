import {
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Typography,
} from "antd";
import { IListagemFormularioPerguntas } from "@interfaces/IListagemFormularioPerguntas";
import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import styles from "./style.module.css";

interface IPerguntasVistoriaProps {
  formVistoria: FormInstance<any>;
  perguntas: IPerguntaTermoVistoria[];
  setPerguntas: React.Dispatch<React.SetStateAction<IPerguntaTermoVistoria[]>>;
  listaPerguntas: IListagemFormularioPerguntas[];
}

export function PerguntasVistoria({
  formVistoria,
  perguntas,
  setPerguntas,
  listaPerguntas,
}: IPerguntasVistoriaProps) {
  const handleChangeForm = (value: number | undefined) => {
    for (let i = 0; i < perguntas.length; i++) {
      formVistoria.setFieldValue(
        `resposta_alternativa_${perguntas[i].pergunta.id}`,
        ""
      );

      perguntas[i].pergunta.campos.forEach((campo, ind) => {
        formVistoria.setFieldValue(
          `resposta_campo_${ind}_${perguntas[i].pergunta.id}`,
          ""
        );
      });

      formVistoria.setFieldValue(
        `resposta_descritiva_${perguntas[i].pergunta.id}`,
        ""
      );
    }

    if (!value) {
      setPerguntas([]);
      return;
    }
    listaPerguntas.forEach((form) => {
      if (form.id === value) {
        let auxPerg = form.formularioPerguntas.filter(
          (perg) => perg.pergunta.antecessora === null
        );

        // console.log(auxPerg);
        auxPerg.sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }

          if (a.order > b.order) {
            return 1;
          }

          return 0;
        });

        setPerguntas(
          auxPerg.map((perg, ind) => {
            return { ...perg, indPergunta: `${ind + 1}` };
          })
        );
      }
    });
  };

  const handleChangePerguntaDependente = (
    event: RadioChangeEvent,
    pergunta: IPerguntaTermoVistoria
  ) => {
    const { value } = event.target;
    let auxPerguntas: IPerguntaTermoVistoria[] = [];

    // SEPARAR O FORMULARIO DE PERGUNTAS UTILIZADO
    const formSelected = listaPerguntas.filter(
      (form) => form.id === formVistoria.getFieldValue("tipoEstabelecimento")
    )[0].formularioPerguntas;

    // PEGAR AS PERGUNTAS DEPENDENTES QUE PODEM TER DE ACORDO COM A RESPOSTA DADA
    let perguntasDependentes = formSelected.filter(
      (perg) =>
        perg.pergunta.antecessora?.id === pergunta.pergunta.id &&
        perg.pergunta.respostaCondicional?.id === value
    );

    //verificando se pergunta ja foi mostrada
    perguntasDependentes = perguntasDependentes.filter((pergunta) => {
      return perguntas.findIndex((aux) => aux.id === pergunta.id) === -1;
    });

    let filterPerguntas = perguntas.filter((perg) => {
      if (
        perg.pergunta.antecessora?.id === pergunta.pergunta.id &&
        perg.pergunta.respostaCondicional?.id !== value
      ) {
        formVistoria.setFieldValue(
          `resposta_alternativa_${perg.pergunta.id}`,
          ""
        );

        perg.pergunta.campos.forEach((campo, ind) => {
          formVistoria.setFieldValue(
            `resposta_campo_${ind}_${perg.pergunta.id}`,
            ""
          );
        });

        formVistoria.setFieldValue(
          `resposta_descritiva_${perg.pergunta.id}`,
          ""
        );

        return null;
      }

      return perg;
    });

    if (perguntasDependentes.length > 0) {
      const perguntasOrdenadas = perguntasDependentes.sort((a, b) => {
        if (a.order > b.order) return 1;

        return -1;
      });

      auxPerguntas = filterPerguntas.concat(
        perguntasOrdenadas.map((perg, indice) => {
          return {
            ...perg,
            indPergunta: `${pergunta.indPergunta}.${indice + 1}`,
          };
        })
      );

      auxPerguntas.sort((a, b) => {
        if (a.order > b.order) return 1;

        return -1;
      });
    } else {
      auxPerguntas = filterPerguntas;
    }

    filterPerguntas = auxPerguntas.filter((perg, ind, todasPerguntas) => {
      if (perg.pergunta.antecessora) {
        if (
          todasPerguntas.findIndex(
            (aux) => aux.pergunta.id === perg.pergunta.antecessora?.id
          ) === -1
        ) {
          return null;
        }

        return perg;
      }

      return perg;
    });

    setPerguntas(filterPerguntas);
  };

  return (
    <>
      <Form.Item
        label="Tipo Estabelecimento"
        name="tipoEstabelecimento"
        rules={[{ required: true }]}
      >
        <Select
          style={{ minWidth: "190px" }}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          placeholder="Selecione um formulário"
          onChange={handleChangeForm}
        >
          {listaPerguntas.map((form, ind) => {
            if (form.ativo) {
              return (
                <Select.Option value={form.id} key={ind}>
                  {form.nome}
                </Select.Option>
              );
            } else {
              return <></>;
            }
          })}
        </Select>
      </Form.Item>

      {perguntas.length > 0 && (
        <>
          <Typography.Title level={3}>Perguntas</Typography.Title>

          <div style={{ display: "flex", flexDirection: "column", gap: 25 }}>
            {perguntas.map((perg, ind) => {
              return (
                <div key={ind}>
                  <div
                    style={{
                      gap: 10,
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography.Title level={5}>
                      {perg.indPergunta}. {perg.pergunta.nome}
                    </Typography.Title>
                  </div>

                  {["ALTERNATIVA", "AMBOS"].includes(
                    perg.pergunta.tipoResposta
                  ) && (
                    <>
                      <Form.Item
                        label="Alternativa"
                        name={`resposta_alternativa_${perg.pergunta.id}`}
                        rules={[{ required: true }]}
                        labelCol={{ flex: "100px", span: 20 }}
                      >
                        <Radio.Group
                          style={{
                            display: "flex",
                            gap: 20,
                            flexWrap: "wrap",
                          }}
                          onChange={(e) =>
                            handleChangePerguntaDependente(e, perg)
                          }
                        >
                          {perg.pergunta.alternativas.map(
                            (alt: {
                              id: number;
                              nome: string;
                              ativo: boolean;
                            }) => {
                              if (alt.ativo) {
                                return (
                                  <Radio value={alt.id} key={alt.id}>
                                    {alt.nome}
                                  </Radio>
                                );
                              }

                              return null;
                            }
                          )}
                        </Radio.Group>
                      </Form.Item>
                    </>
                  )}

                  {perg.pergunta.campos.map((campo, ind) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          gap: 5,
                          flexDirection: "column",
                        }}
                      >
                        <label className={styles["label-campo-vistoria"]}>
                          {campo}
                        </label>
                        <Form.Item
                          key={ind}
                          name={`resposta_campo_${ind}_${perg.pergunta.id}`}
                          rules={[
                            {
                              required: true,
                              message: "Pro favor, insira uma resposta",
                            },
                          ]}
                          noStyle={false}
                        >
                          <Input style={{ maxWidth: 400 }} />
                        </Form.Item>
                      </div>
                    );
                  })}

                  {["DESCRITIVA"].includes(perg.pergunta.tipoResposta) && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          gap: 5,
                          flexDirection: "column",
                        }}
                      >
                        <label className={styles["label-campo-vistoria"]}>
                          ESPECIFICAR:
                        </label>
                        <Form.Item
                          name={`resposta_descritiva_${perg.pergunta.id}`}
                          rules={[
                            {
                              required: true,
                              message: "Pro favor, insira uma resposta",
                            },
                          ]}
                          noStyle={true}
                        >
                          <Input
                            placeholder={perg.pergunta.orientacao}
                            style={{ maxWidth: 400 }}
                          />
                        </Form.Item>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Tooltip,
} from "antd";
import { openNotification } from "@components/Notifications";
import { useEffect, useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { TERMO_VISTORIA_OPTIONS_FILTER } from "../../constants";
import { apiService } from "@services/api";
import { ButtonAplicarFiltro } from "@components/ButtonAplicarFiltro";
import { useActionsTermoVistoria } from "../../../hooks/useActionsTermoVistoria";

export function FiltroTermoVistoria({
  formFiltro,
  setPageControl,
}: {
  formFiltro: FormInstance;
  setPageControl: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filtroSelect, setFiltroSelect] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { aplicarFiltro, listaFiltroTermos, removerFiltro } =
    useActionsTermoVistoria();

  const showDrawer = () => {
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
    setFiltroSelect([]);
  };

  const handleChangeFiltro = (values: any[]) => {
    setFiltroSelect(values);
  };

  const handleSubmit = async (values: any) => {
    const params = new URLSearchParams();

    params.append("size", "20");
    for (const filtro in values) {
      params.append(filtro, values[filtro].toUpperCase().trim());
    }
    setIsLoading(true);
    await apiService.termoVistoria
      .listar(params.toString())
      .then((data) => {
        removerFiltro();

        aplicarFiltro([
          {
            filtros: filtroSelect,
            params: params.toString(),
            termos: data.content,
            numberPages: data.totalPages,
          },
        ]);

        setPageControl(1);
        if (data.content.length > 0) {
          openNotification({
            type: "success",
            message: "Termo(s) de Vistoria encontrado(s) com sucesso!",
          });
        } else {
          openNotification({
            type: "info",
            message:
              "Não foi possivel encontrar Termo(s) de Vistoria não encontrado(s)",
            description: "Nenhum Termo foi encontrado na base de dados.",
          });
        }

        onClose();
      })
      .catch((erro) => {
        console.log(erro.response);
        openNotification({
          type: "error",
          message: "Não foi possivel encontrar Termo(s) de Vistoria",
          description: erro.response?.data?.detail,
        });
        // window.location.reload();
      });
    setIsLoading(false);
    return;
  };

  useEffect(() => {
    if (listaFiltroTermos.length > 0) {
      setFiltroSelect(listaFiltroTermos[0].filtros);

      const params = new URLSearchParams(listaFiltroTermos[0].params);

      listaFiltroTermos[0].filtros.map((filtro) => {
        formFiltro.setFieldValue(filtro, params.get(filtro));
        return filtro;
      });
    } else {
      setFiltroSelect([]);
      formFiltro.resetFields();
    }
  }, [isModalOpen, listaFiltroTermos, formFiltro]);

  return (
    <>
      <div>
        <Tooltip title="Filtro">
          <Button
            onClick={showDrawer}
            disabled={!navigator.onLine}
            style={{ boxShadow: "5px 5px 5px rgba(0,0,0,0.2)" }}
          >
            <FilterOutlined />
          </Button>
        </Tooltip>

        <Form
          id="form-filtro"
          layout="vertical"
          labelCol={{ span: 18 }}
          labelWrap
          labelAlign="left"
          form={formFiltro}
          onFinish={handleSubmit}
          onFinishFailed={(e) => {
            openNotification({
              type: "error",
              message: "Campos em branco",
              description: "Termine de preencher todos os campos obrigatórios",
            });
          }}
        >
          <Drawer
            title="Filtro de Termo(s) de Vistoria"
            placement="right"
            closable={false}
            onClose={onClose}
            open={isModalOpen}
            key={"right"}
            width={350}
            extra={
              <Space>
                <Button onClick={onClose}>Fechar</Button>
              </Space>
            }
          >
            <Checkbox.Group onChange={handleChangeFiltro} value={filtroSelect}>
              <Space direction="vertical">
                {TERMO_VISTORIA_OPTIONS_FILTER.map((filtro) => {
                  return (
                    <Checkbox key={filtro.value} value={filtro.value}>
                      {filtro.label}
                    </Checkbox>
                  );
                })}
              </Space>
            </Checkbox.Group>

            <Divider />

            {filtroSelect.includes("municipioFiscalizado") && (
              <Form.Item
                label="Nome do Município"
                name="municipioFiscalizado"
                rules={[
                  {
                    required: filtroSelect.includes("municipioFiscalizado"),
                    message: "Campo vazio",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {filtroSelect.includes("nomeFiscalizado") && (
              <Form.Item
                label="Nome do Fiscalizado"
                name="nomeFiscalizado"
                rules={[
                  {
                    required: filtroSelect.includes("nomeFiscalizado"),
                    message: "Campo vazio",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {filtroSelect.includes("cpfCnpjFiscalizado") && (
              <Form.Item
                label="CNPJ do Fiscalizado"
                name="cpfCnpjFiscalizado"
                rules={[
                  {
                    required: filtroSelect.includes("cpfCnpjFiscalizado"),
                    message: "Campo vazio",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {filtroSelect.includes("nomeFormulario") && (
              <Form.Item
                label="Nome do Formulário"
                name="nomeFormulario"
                rules={[
                  {
                    required: filtroSelect.includes("nomeFormulario"),
                    message: "Campo vazio",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            <Row justify="center">
              <Space direction="vertical" size={10}>
                <ButtonAplicarFiltro
                  isDisabled={filtroSelect.length === 0}
                  isLoading={isLoading}
                />
                <Col />
              </Space>
            </Row>
          </Drawer>
        </Form>
      </div>
    </>
  );
}

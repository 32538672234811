import { Button, Modal, Tooltip } from "antd";
import { useNavigate } from "react-router";
import { EyeOutlined } from "@ant-design/icons";
import styles from "./style.module.css";
import { IContentListagemTIF } from "@interfaces/IListagemTIF";

interface IModalPrazoAdequarTIF {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  listaTif: IContentListagemTIF[];
}

export function ModalPrazoAdequarTIF({
  setIsOpen,
  isOpen,
  listaTif = [],
}: IModalPrazoAdequarTIF) {
  const navigate = useNavigate();

  const handleViewTif = (tifId: number) => {
    navigate(`/TIF/listagem/visualizar/${tifId}`);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      width={500}
      onCancel={() => setIsOpen(false)}
      title={
        <span>
          TIFs com prazos para adequação terminando
          <br /> nos próximos dias
        </span>
      }
      footer={null}
    >
      <div className={styles["container-modal"]}>
        {listaTif.map((tif, ind) => {
          return (
            <div key={ind} className={styles["content-items"]}>
              <span>
                {ind + 1}- TIF: {tif.numero}
              </span>
              <Tooltip title="Visualizar" destroyTooltipOnHide>
                <Button onClick={() => handleViewTif(tif.id)}>
                  <EyeOutlined />
                </Button>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </Modal>
  );
}

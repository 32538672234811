import styled from "styled-components";
import { ITif } from "@interfaces/ITif";
import moment from "moment";
import { masks } from "@functions/mascaras";

export const ContainerAssinaturas = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  height: 2vh;
  display: flex;
  align-items: center;
`;

const CONECTOR_NAMES = ["da", "de", "do", "dos", "das"];

export function FooterImpressaoTIF({ tif }: { tif: ITif }) {
  const { cpfCnpjMask, telefoneMask } = masks;

  const formataNome = (str: string) => {
    const names = str.toLowerCase().split(" ");
    let aux = "";

    names.map((name) => {
      if (!name) return "";

      if (CONECTOR_NAMES.includes(name)) {
        aux = aux + " " + name;
      } else {
        aux = aux + " " + name[0].toUpperCase() + name.substring(1);
      }
      return name;
    });

    return aux;
  };

  return (
    <tfoot>
      <tr>
        <td>
          <div style={{ border: "1px solid black" }}>
            <Container>
              <div
                style={{
                  width: "50%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Municipio: {tif.municipioFiscalizacao?.nome}
              </div>
              <div
                style={{
                  width: "25%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                <span>
                  Data: {moment(tif.dataFiscalizacao).format("DD/MM/YYYY")}
                </span>
              </div>
              <div style={{ width: "25%", paddingLeft: 5 }}>
                <span>
                  Horário: {tif.dataCadastro && tif.dataCadastro.slice(11, 16)}
                </span>
              </div>
            </Container>

            <ContainerAssinaturas style={{ gap: "20px" }}>
              {tif.servidores.map((serv, ind: number) => {
                return (
                  <div
                    key={ind}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingBottom: 10,
                      paddingTop: 5,
                      maxWidth: tif.servidores.length > 1 ? "15vh" : "none",
                      width: tif.servidores.length > 3 ? "12vh" : "auto",
                      alignItems: "center",
                      fontSize: tif.servidores.length > 3 ? 10 : 12,
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width={tif.servidores.length > 3 ? "3cm" : "4cm"}
                      height={tif.servidores.length > 3 ? "1.5cm" : "2.5cm"}
                      viewBox="0 0 645 600"
                      enableBackground="new 0 0 645 600"
                      xmlSpace="preserve"
                      style={{ borderBottom: "1px solid black" }}
                    >
                      <image
                        id="image0"
                        width="17cm"
                        height="15.875cm"
                        x="0"
                        y="0"
                        href={serv.assinatura}
                      />
                    </svg>

                    <span style={{ textAlign: "center" }}>
                      Fiscal/Agente Fiscal
                    </span>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <span>Nome:{formataNome(serv.servidor.nome || "")}</span>

                      <span>Matricula:{serv.servidor.matricula}</span>
                    </div>
                  </div>
                );
              })}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: 10,
                    paddingTop: 5,
                    maxWidth: tif.servidores.length > 1 ? "15vh" : "none",
                    width: tif.servidores.length > 3 ? "12vh" : "auto",
                    alignItems: "center",
                    fontSize: tif.servidores.length > 3 ? 10 : 12,
                  }}
                >
                  {tif.statusAssinatura === "ASSINADO_DIGITALMENTE" && (
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width={tif.servidores.length > 3 ? "3cm" : "4cm"}
                      height={tif.servidores.length > 3 ? "1.5cm" : "2.5cm"}
                      viewBox="0 0 645 600"
                      enableBackground="new 0 0 645 600"
                      xmlSpace="preserve"
                      style={{ borderBottom: "1px solid black" }}
                    >
                      <image
                        id="image0"
                        width="17cm"
                        height="15.875cm"
                        x="0"
                        y="0"
                        href={tif.assinatura}
                      />
                    </svg>
                  )}

                  {(tif.statusAssinatura === "AUSENTE" ||
                    tif.statusAssinatura === "RECUSADO") && (
                    <p
                      style={{
                        borderBottom: "1px solid black",
                        width: tif.servidores.length > 3 ? "3cm" : "4cm",
                        height: tif.servidores.length > 3 ? "1.5cm" : "2.5cm",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                        marginBottom: 0,
                      }}
                    >
                      {tif.statusAssinatura}
                    </p>
                  )}

                  {tif.statusAssinatura === "ASSINADO_FISICAMENTE" && (
                    <div
                      style={{
                        borderBottom: "1px solid black",
                        width: tif.servidores.length > 3 ? "3cm" : "4cm",
                        height: tif.servidores.length > 3 ? "1.5cm" : "2.5cm",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    />
                  )}

                  <span>Inspecionado/Fiscalizado</span>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                    }}
                  >
                    <span>Nome:{formataNome(tif.nomeAssinante || "")}</span>
                    <span>CPF:{cpfCnpjMask(tif.cpfAssinante || "")}</span>
                    <span>Função:{tif.funcaoAssinante || ""}</span>
                  </div>
                </div>
              </div>
            </ContainerAssinaturas>
          </div>
          <div style={{ fontSize: 12 }}>
            <span>Dados para contato:</span>
            <div style={{ display: "flex", gap: 10 }}>
              <span>ULE:{tif.ule?.nome}</span>
              <span>
                Telefone:
                {tif.ule?.telefones
                  ? telefoneMask(tif.ule?.telefones[0].telefone || "")
                  : ""}
              </span>
              <span>Email:{tif.ule?.email}</span>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  );
}

import { Form, FormInstance, Radio, RadioChangeEvent } from "antd";
import { IFormValuesTID } from "@interfaces/IFormValuesTID";

export function SelectTipoTermoTID({
  form,
  resetFields,
  setIsInterdicao,
}: {
  form: FormInstance<IFormValuesTID>;
  resetFields: () => void;
  setIsInterdicao: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleChangeTipoTermo = (e: RadioChangeEvent) => {
    const { value: tipoTermo } = e.target;

    setIsInterdicao(tipoTermo === "INTERDICAO");
    resetFields();
    form.setFieldValue("tipoTermo", tipoTermo);
  };

  return (
    <>
      <Form.Item label="Termo" name="tipoTermo" rules={[{ required: true }]}>
        <Radio.Group onChange={handleChangeTipoTermo}>
          <Radio value="INTERDICAO">Interdição</Radio>
          <Radio value="DESINTERDICAO">Desinterdição</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
}

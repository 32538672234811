import {
  Button,
  Form,
  Typography,
  Divider,
  Modal,
  Input,
  message,
  UploadFile,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import { RegistroTIF } from "./components/RegistroTIF";
import { AssinaturasTIF } from "./components/AssinaturasTIF";
import { openNotification } from "@components/Notifications";
import { AtividadesTIF } from "./components/AtividadesTIF";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { ITif } from "@interfaces/ITif";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { PreviaTIF } from "./components/PreviaTIF";
import fs from "file-saver";
import { IServidorForm } from "@interfaces/IServidorForm";
import { checkDevice } from "@functions/checkDevice";
import { TitleTermo } from "@styles/TitleTermo";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { organizarListaAtividade } from "./functions/organizarListaAtividade";
import { IFormValuesTif } from "@interfaces/IFormValuesTif";
import { useForms } from "@hooks/useForms";
import { useMunicipio } from "@hooks/useMunicipio";
import { useServidores } from "@hooks/useServidores";
import { useDadosTif } from "@hooks/useDadosTif";
import { useUle } from "@hooks/useUle";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { ModalInformarNumeroTermo } from "@components/ModalInformarNumeroTermo";
import { useActionsTif } from "./hooks/useActionsTif";
import { tifNaoEncontradoFiscalizadoError } from "./errors/tifNaoEncontradoFiscalizadoError";
import { tifServidorGridError } from "./errors/tifServidorGridError";
import { tifDadosAssinanteError } from "./errors/tifDadosAssinanteError";
import { tifAtividadeError } from "./errors/tifAtividadeError";
import { tifAssinaturaError } from "./errors/tifAssinaturaError";
import { apiService } from "@services/api";
import { transformStringToNumber } from "@utils/transformStringToNumber";
import { usePropriedade } from "@hooks/usePropriedade";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { converterTIFtoFormData } from "./functions/converterTIFtoFormData";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { TabelaServidoresForm } from "@components/TabelaServidoresForm";
import { UploadFilesForm } from "@components/UploadFilesForm";
import { INITIAL_VALUES_ASSINATURA } from "@constants/INITIAL_VALUES_ASSINATURA";
import { tifAtividadesEmptyError } from "./errors/tifAtividadesEmptyError";
import { ModalGerarTID } from "./components/ModalGerarTID";
import { useAtividadeInterditada } from "@hooks/useAtividadeInterditada";
import { tifSemPropriedadeError } from "./errors/tifSemPropriedadeError";

type InfoForm = {
  constatacao?: string;
  providencias?: string;
};

export function FormTif() {
  const [registroForm] = Form.useForm<IFormValuesTif>();
  const [infoForm] = Form.useForm<InfoForm>();
  const [atividadeForm] = Form.useForm();
  const [servidorForm] = Form.useForm();
  const [modal, contextHolderModal] = Modal.useModal();
  const { buscarPropriedadePeloCpfCnpj } = usePropriedade();
  const { buscarEstabelecimentoPeloCnpj } = useEstabelecimento();
  const { messageSubmitFailed, gerarNumeroTermo } = useForms();
  const { getUle } = useUle();
  const { getMunicipioPorId } = useMunicipio();
  const { adicionarTif, adicionarTifByFiltro } = useActionsTif();
  const { servidoresSelecionados, removerTodosServidoresSelecionados } =
    useServidores();
  const { atividadesTif, respostasAtividades, removerTodasAtividades } =
    useDadosTif();
  const { listaAtividadesInterditadas } = useAtividadeInterditada();
  const [isOpenModalInformarNumTif, setIsOpenModalInformarNumTif] =
    useState(false);
  const [numTif, setNumTif] = useState("");
  const [idEstabelecimentoSelected, setIdEstabelecimentoSelected] =
    useState<number>();
  const [typePropEstab, setTypePropEstab] = useState("");
  const [isSavingTif, setIsSavingTif] = useState(false);
  const [isValidDocument, setIsValidDocument] = useState(false);
  const [typeDocument, setTypeDocument] = useState("");
  const [anexos, setAnexos] = useState<UploadFile[]>([]);
  const [servidores, setServidores] = useState<IServidorForm[]>([]);
  const [motivoNaoAssinaturaFiscalizado, setMotivoNaoAssinaturaFiscalizado] =
    useState<CheckboxValueType[]>([]);
  const [fiscalizadoSignature, setFiscalizadoSignature] = useState(
    INITIAL_VALUES_ASSINATURA
  );
  const [municipioFiscalizacaoId, setMunicipioFiscalizacaoId] =
    useState<number>();
  const [tifToTid, setTifToTid] = useState<ITif | null>(null);

  const handleSubmit = async (values: IFormValuesTif) => {
    const keyMessage = "tif";
    const cpfCnpjFiscalizado = values.fiscalizadoCpf.replace(/[^\d]+/g, "");

    const numeroTIF =
      numTif || gerarNumeroTermo(values.municipioFiscalizacaoId);

    const atvQuestionsEmpty = atividadesTif.filter((atv) => {
      if (atv.atividade.questoes.length > 0) {
        const { id } = atv.atividade;

        if (atv.armadilha) {
          if (
            respostasAtividades.some(
              (res) => res.armadilha?.id === atv.armadilha?.id
            )
          ) {
            return null;
          }
          return atv;
        } else {
          if (respostasAtividades.some((res) => res.atividade.id === id)) {
            return null;
          }
          return atv;
        }
      }

      return null;
    });

    const dadosAssinante = Object.entries(fiscalizadoSignature).filter(
      (item) => item[0] !== "assinatura"
    );

    //VALIDACOES
    if (!isValidDocument) {
      tifNaoEncontradoFiscalizadoError();
      return;
    }

    if (
      !idEstabelecimentoSelected &&
      !values.propriedadeId &&
      !values.propEstab
    ) {
      tifSemPropriedadeError();
      return;
    }

    if (atividadesTif.length === 0) {
      tifAtividadesEmptyError();
      return;
    }

    if (servidoresSelecionados.length === 0) {
      tifServidorGridError();
      return;
    }

    if (!isSignaturesFilled()) {
      tifAssinaturaError();
      return;
    }

    if (
      dadosAssinante.some((assinante) => !assinante[1]) &&
      (motivoNaoAssinaturaFiscalizado.length === 0 ||
        motivoNaoAssinaturaFiscalizado[0] === ASSINATURA_FISICA)
    ) {
      tifDadosAssinanteError();
      return;
    }

    if (atvQuestionsEmpty.length > 0) {
      tifAtividadeError();
      return;
    }

    try {
      let formValues: ITif = {
        statusAssinatura: getStatusAssinatura(motivoNaoAssinaturaFiscalizado),
        amparoLegal: infoForm.getFieldValue("providencias") || "",
        assinatura:
          motivoNaoAssinaturaFiscalizado[0] === "ASSINADO_FISICAMENTE"
            ? ""
            : fiscalizadoSignature?.assinatura
                ?.getTrimmedCanvas()
                .toDataURL() || "",

        atividades: organizarListaAtividade(atividadesTif),
        caracteristicaFiscalizado: values.atuado.toUpperCase(),
        cepFiscalizado: values.cepFiscalizado || "",
        cepPropriedade: values.cepPropriedade || "",
        constatacao: infoForm.getFieldValue("constatacao") || "",
        cpfCnpjFiscalizado: cpfCnpjFiscalizado,
        dataFiscalizacao: moment(values.dataFiscalizar)
          .locale("pt-br")
          .format()
          .slice(0, 10),
        emailFiscalizado: values.emailFiscalizado || "",
        enderecoFiscalizado: values.enderecoFiscalizado || "",
        enderecoPropriedade: values.enderecoPropriedade || "",
        fiscalizacao: values.termo.includes("fiscalizacao"),
        fiscalizacaoFronteira: values.fiscalizacaoFronteira === "SIM",
        inscricaoEstadualFiscalizado: values.inscricaoEstadualFiscalizado || "",
        inspecao: values.termo.includes("inspecao"),
        latGrauPropriedade:
          transformStringToNumber(values.latGrauPropriedade || "") || 0,
        latMinPropriedade:
          transformStringToNumber(values.latMinPropriedade || "") || 0,
        latSegPropriedade:
          transformStringToNumber(values.latSegPropriedade || "") || 0,
        longGrauPropriedade:
          transformStringToNumber(values.longGrauPropriedade || "") || 0,
        longMinPropriedade:
          transformStringToNumber(values.longMinPropriedade || "") || 0,
        longSegPropriedade:
          transformStringToNumber(values.longSegPropriedade || "") || 0,
        municipioFiscalizado: getMunicipioPorId(
          values.municipioFiscalizadoId || undefined
        ),
        municipioFiscalizacao: getMunicipioPorId(
          values.municipioFiscalizacaoId || undefined
        ),
        nomeArquivo: numeroTIF,
        nomeFiscalizado: values.fiscalizadoNome,
        nomePropriedade: values.propEstab || "",
        nomeUpload: `TIF ${numeroTIF}`,
        notificacao: values.termo.includes("notificacao"),
        numero: numeroTIF,
        orientacaoLatitudePropriedade: "S",
        orientacaoLongitudePropriedade: "O",
        produtor: null,
        propriedade: null,
        renasemFiscalizado: values.renasemFiscalizado || "",
        estabelecimento: null,
        servidores: [],
        telefoneFiscalizado:
          values.telefoneFiscalizado?.replace(/[^\d]+/g, "") || "",
        tipoRepresentante: values.tipoRepresentante || "",
        ule: getUle(values.municipioFiscalizacaoId),
        anexosTIF: [],
        dataCadastro: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
        nomeAssinante: fiscalizadoSignature.nomeAssinante,
        cpfAssinante: fiscalizadoSignature.cpfAssinante.replace(/[^\d]+/g, ""),
        funcaoAssinante: fiscalizadoSignature.funcaoAssinante,
        respostasAtividades: respostasAtividades,
      };

      const servidoresFormTif = servidores.map((serv) => {
        return {
          ...serv,
          assinatura:
            getStatusAssinatura(motivoNaoAssinaturaFiscalizado) ===
            ASSINATURA_FISICA
              ? ""
              : serv.assinatura,
        };
      });

      formValues = {
        ...formValues,
        servidores: servidoresFormTif,
      };

      if (values.propriedadeId && !values.propEstab) {
        const propriedade = (
          await buscarPropriedadePeloCpfCnpj(cpfCnpjFiscalizado)
        ).find((prop) => prop.id === values.propriedadeId);

        if (propriedade) {
          formValues = {
            ...formValues,
            nomePropriedade: propriedade.nome,
            propriedade: propriedade,
            produtor: propriedade.produtores[0],
          };
        }
      }

      if (idEstabelecimentoSelected) {
        const estabelecimentoSelected = (
          await buscarEstabelecimentoPeloCnpj(cpfCnpjFiscalizado)
        ).find((estab) => estab.id === idEstabelecimentoSelected);

        if (estabelecimentoSelected) {
          formValues = {
            ...formValues,
            nomePropriedade: estabelecimentoSelected.nome,
            estabelecimento: estabelecimentoSelected,
          };
        }
      }

      let anexosSubmitToTermo: (File | undefined)[] = [];

      if (anexos.length > 0) {
        await getAnexosFormatadosBase64(anexos).then((res) => {
          formValues = { ...formValues, anexosTIF: res };

          const aux = res.map((anexo) => {
            return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
          });

          anexosSubmitToTermo = aux.filter((anexo) => anexo !== undefined);
        });
      }

      const formData = converterTIFtoFormData(formValues, anexosSubmitToTermo);

      const atividadeInterditadaSelected = atividadesTif.find((atv) => {
        if (
          listaAtividadesInterditadas?.find(
            (itemInterditado) =>
              itemInterditado.atividade.id === atv.atividade.id
          ) &&
          listaAtividadesInterditadas?.find(
            (itemInterditado) =>
              itemInterditado.areaAtividade.id === atv.programa.id
          )
        ) {
          return atv;
        }

        return null;
      });

      if (navigator.onLine) {
        message.loading({
          key: keyMessage,
          duration: 0,
          content: "Salvando Termo...",
        });

        setIsSavingTif(true);
        await apiService.tif
          .enviar(formData)
          .then((res) => {
            adicionarTifByFiltro({
              ...formValues,
              id: res.data.id,
              ativo: res.data.ativo,
            });
            setIsSavingTif(false);
            message.destroy(keyMessage);
            openNotification({
              type: "success",
              message: "TIF criado com sucesso!",
            });

            if (atividadeInterditadaSelected) {
              setTifToTid(formValues);
            }

            handleResetForm();
          })
          .catch((erro) => {
            setIsSavingTif(false);
            message.destroy(keyMessage);
            openNotification({
              type: "error",
              message: "Não foi possivel salvar TIF",
              description: erro.response.data.detail,
            });
          });
      } else {
        adicionarTif(formValues);

        // MODAL DE CONFIRMAÇÃO SE O USUARIO QUISER BAIXAR UM BACKUP
        modal.info({
          title: `Gostaria de baixar um backup do TIF?`,
          icon: <ExclamationCircleOutlined />,
          okText: "Sim",
          onOk: () => {
            let blob = new Blob([JSON.stringify(formValues)], {
              type: "text/plain;charset=utf-8",
            });
            fs.saveAs(blob, `TIF ${formValues.numero}.txt`, { autoBom: true });
            openNotification({
              type: "success",
              message: "TIF criado com sucesso!",
              description:
                "Salvamento do TIF pendente, para quando aplicativo houver conexão com a internet.",
            });

            if (atividadeInterditadaSelected) {
              setTifToTid(formValues);
            }

            handleResetForm();
          },
        });
      }
    } catch (error: any) {
      console.log(error);
      setIsSavingTif(false);
      message.destroy(keyMessage);
      openNotification({
        type: "error",
        message: "Não foi possivel enviar TIF",
        description: error.response.data.detail,
      });
    }
  };

  const isSignaturesFilled = () => {
    if (motivoNaoAssinaturaFiscalizado[0] === ASSINATURA_FISICA) {
      return true;
    }

    if (
      (fiscalizadoSignature?.assinatura?.toData().length === 0 ||
        !fiscalizadoSignature.assinatura) &&
      motivoNaoAssinaturaFiscalizado.length === 0
    )
      return false;

    const isSignatureServEmpty = servidores.some(
      (serv) => serv.assinatura.length === 0
    );

    if (isSignatureServEmpty) {
      return false;
    }

    return true;
  };

  const handleResetForm = () => {
    fiscalizadoSignature?.assinatura?.clear();
    setFiscalizadoSignature(INITIAL_VALUES_ASSINATURA);

    setIsValidDocument(false);
    setTypeDocument("");

    registroForm.resetFields();
    atividadeForm.resetFields();
    servidorForm.resetFields();
    infoForm.resetFields();

    setAnexos([]);
    setMotivoNaoAssinaturaFiscalizado([]);

    removerTodasAtividades();
    removerTodosServidoresSelecionados();
  };

  const modalConfirm = () => {
    confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        handleResetForm();
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  useEffect(() => {
    removerTodasAtividades();
    removerTodosServidoresSelecionados();

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {contextHolderModal}
      <TitleTermo>Termo de Inspeção, Fiscalização e Notificação</TitleTermo>

      <Divider />

      <Form
        initialValues={{ dataFiscalizar: moment() }}
        form={registroForm}
        id="form-register"
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "150px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        onFinish={handleSubmit}
        onFinishFailed={messageSubmitFailed}
        onReset={modalConfirm}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <RegistroTIF
          form={registroForm}
          isValidDocument={isValidDocument}
          setIsValidDocument={setIsValidDocument}
          typeDocument={typeDocument}
          setTypeDocument={setTypeDocument}
          fiscalizadoSignature={fiscalizadoSignature}
          setFiscalizadoSignature={setFiscalizadoSignature}
          typePropEstab={typePropEstab}
          setTypePropEstab={setTypePropEstab}
          setIdEstabelecimento={setIdEstabelecimentoSelected}
          idEstabelecimento={idEstabelecimentoSelected}
          setMunicipioFiscalizacaoId={setMunicipioFiscalizacaoId}
        />

        <UploadFilesForm setAnexos={setAnexos} anexos={anexos} />
      </Form>
      <Typography.Title level={3}>Atividades</Typography.Title>
      <AtividadesTIF
        atividadeForm={atividadeForm}
        municipioFiscalizacaoId={municipioFiscalizacaoId}
      />

      <Form
        form={infoForm}
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "150px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        style={{ marginTop: 40 }}
      >
        <Form.Item label="Constatação" name="constatacao">
          <Input.TextArea rows={4} maxLength={10000} showCount />
        </Form.Item>

        <Form.Item label="Providências" name="providencias">
          <Input.TextArea rows={4} maxLength={10000} showCount />
        </Form.Item>
      </Form>

      <Divider />

      <TabelaServidoresForm form={servidorForm} />

      <AssinaturasTIF
        ativarModalInformarNumTif={() => setIsOpenModalInformarNumTif(true)}
        fiscalizado={fiscalizadoSignature}
        setFiscalizado={setFiscalizadoSignature}
        motivoNaoAssinatura={motivoNaoAssinaturaFiscalizado}
        setMotivoNaoAssinatura={setMotivoNaoAssinaturaFiscalizado}
        servidores={servidores}
        setServidores={setServidores}
        form={registroForm}
      />

      <ButtonFooterContainer>
        <Button form="form-register" htmlType="reset" disabled={isSavingTif}>
          Cancelar
        </Button>
        <Button
          type="primary"
          form="form-register"
          htmlType="submit"
          disabled={isSavingTif}
        >
          Salvar
        </Button>
        {!checkDevice() && (
          <PreviaTIF
            anexos={anexos}
            infoForm={infoForm}
            form={registroForm}
            motivoNaoAssinaturaFiscalizado={motivoNaoAssinaturaFiscalizado}
            dadosAssinaturaFiscalizado={fiscalizadoSignature}
            idEstabelecimento={idEstabelecimentoSelected}
          />
        )}
      </ButtonFooterContainer>

      <ModalInformarNumeroTermo
        isOpen={isOpenModalInformarNumTif}
        onClose={() => setIsOpenModalInformarNumTif(false)}
        numTermo={numTif}
        setNumTermo={setNumTif}
      />

      {tifToTid && <ModalGerarTID tif={tifToTid} setTif={setTifToTid} />}
    </Fragment>
  );
}

import { masks } from "@functions/mascaras";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";

export function FooterFitoImpressao({
  termo,
}: {
  termo: ITermoColetaAmostras;
}) {
  const { cpfCnpjMask } = masks;

  return (
    <>
      <tfoot>
        <tr>
          <td>
            <div style={{ border: "1px solid black" }}>
              <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                  }}
                >
                  <span>Local: {termo?.municipioColeta?.nome}</span>
                </div>

                <div
                  style={{
                    width: "50%",
                    paddingLeft: 5,
                  }}
                >
                  <span>Data: {termo.dataColeta}</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 40px",
                }}
              >
                <div>
                  {termo?.statusAssinatura === "ASSINADO_FISICAMENTE" ? (
                    <div
                      style={{
                        width: 500,
                        height: "2.5cm",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                        borderBottom: "1px solid black",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: 500,
                        borderBottom: "1px solid black",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="4cm"
                        height="2.5cm"
                        viewBox="0 0 645 600"
                        enableBackground="new 0 0 645 600"
                        xmlSpace="preserve"
                      >
                        <image
                          id="image0"
                          width="17cm"
                          height="15.875cm"
                          x="0"
                          y="0"
                          href={termo?.assinaturaAmostrador}
                        />
                      </svg>
                    </div>
                  )}

                  <div
                    style={{
                      width: 500,
                      textAlign: "center",
                    }}
                  >
                    Assinatura do Responsável pela Amostragem Fitossanitária
                  </div>

                  <div
                    style={{ border: "1px solid black", padding: "0px 4px" }}
                  >
                    <div style={{ borderBottom: "1px solid black" }}>
                      Nome: {termo?.amostrador?.pessoa.nome}
                    </div>
                    <div style={{ borderBottom: "1px solid black" }}>
                      Função: Amostrador
                    </div>
                    <div style={{ borderBottom: "1px solid black" }}>
                      Matrícula: {termo.renasemMatriculaAmostrador}
                    </div>
                  </div>
                </div>

                <div>
                  {termo?.statusAssinatura === "ASSINADO_DIGITALMENTE" && (
                    <div
                      style={{
                        width: 500,
                        borderBottom: "1px solid black",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="4cm"
                        height="2.5cm"
                        viewBox="0 0 645 600"
                        enableBackground="new 0 0 645 600"
                        xmlSpace="preserve"
                      >
                        <image
                          id="image0"
                          width="17cm"
                          height="15.875cm"
                          x="0"
                          y="0"
                          href={termo?.assinaturaFiscalizado}
                        />
                      </svg>
                    </div>
                  )}

                  {(termo?.statusAssinatura === "AUSENTE" ||
                    termo?.statusAssinatura === "RECUSADO") && (
                    <p
                      style={{
                        borderBottom: "1px solid black",
                        width: "500px",
                        height: navigator.onLine ? "2.5cm" : "2cm",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                        marginBottom: 0,
                      }}
                    >
                      {termo?.statusAssinatura}
                    </p>
                  )}

                  {termo?.statusAssinatura === "ASSINADO_FISICAMENTE" && (
                    <div
                      style={{
                        borderBottom: "1px solid black",
                        width: "500px",
                        height: "2.5cm",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    />
                  )}
                  <div
                    style={{
                      width: 500,
                      textAlign: "center",
                    }}
                  >
                    Assinatura do Proprietário / Preposto / Responsável Técnico
                  </div>
                  <div
                    style={{ border: "1px solid black", padding: "0px 4px" }}
                  >
                    <div style={{ borderBottom: "1px solid black" }}>
                      Nome:{" "}
                      {termo?.statusAssinatura === "AUSENTE" ||
                      termo?.statusAssinatura === "RECUSADO"
                        ? ""
                        : termo?.nomeAssinante}
                    </div>
                    <div style={{ borderBottom: "1px solid black" }}>
                      CPF:{" "}
                      {termo?.statusAssinatura === "AUSENTE" ||
                      termo?.statusAssinatura === "RECUSADO"
                        ? ""
                        : cpfCnpjMask(termo?.cpfAssinante || "")}
                    </div>

                    <div>Cargo: {termo?.funcaoAssinante}</div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </>
  );
}

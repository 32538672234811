import { useDispatch, useSelector } from "react-redux";
import { getServidoresInGrid } from "@store/feature/gridServidorSlice";
import { getServidores } from "@store/feature/servidorSlice";
import { servidorAction } from "@store/actions/servidorAction";
import { gridServidorAction } from "@store/actions/gridServidorAction";
import { IServidor } from "@interfaces/IServidor";

export const useServidores = () => {
  const servidoresSelecionados = useSelector(getServidoresInGrid);
  const listaServidores = useSelector(getServidores);
  const appDispatch = useDispatch();
  const { addServidores } = servidorAction;
  const { addGridServidor, removeAllServidoresInGrid, removeServidorInGrid } =
    gridServidorAction;

  const adicionarTodosServidores = (servidores: IServidor[]) => {
    appDispatch(addServidores(servidores));
  };

  const selecionarServidor = (servidor: any) => {
    appDispatch(addGridServidor(servidor));
  };

  const removerServidorSelecionado = (matriculaServidor: string) => {
    appDispatch(removeServidorInGrid(matriculaServidor));
  };

  const removerTodosServidoresSelecionados = () => {
    appDispatch(removeAllServidoresInGrid());
  };

  const getServidorPorMatricula = (matricula: string) => {
    return listaServidores.find((serv) => serv.matricula === matricula);
  };

  return {
    servidoresSelecionados,
    listaServidores,
    adicionarTodosServidores,
    selecionarServidor,
    removerServidorSelecionado,
    removerTodosServidoresSelecionados,
    getServidorPorMatricula,
  };
};

import styled from "styled-components";
import { masks } from "@functions/mascaras";
import { ITif } from "@interfaces/ITif";

const termo = [
  { label: "Inspeção", value: "inspecao" },
  { label: "Fiscalização", value: "fiscalizacao" },
  { label: "Notificação", value: "notificacao" },
];

const atuado = [
  { label: "Propriedade", value: "propriedade" },
  { label: "Estabelecimento", value: "estabelecimento" },
  { label: "Transito", value: "transito" },
  { label: "Profissional", value: "profissional" },
];

const TitleCSS = styled.div`
  font-weight: bold;
  background-color: #d9d9d9;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

export function BodyImpressaoTIF({ tif }: { tif: ITif }) {
  const { cpfCnpjMask, coordMask, telefoneMask } = masks;

  return (
    <>
      <tbody>
        <tr>
          <td
            style={{
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "25px",
                  padding: 5,
                }}
              >
                <span>Termo:</span>
                <div style={{ display: "flex", gap: 100 }}>
                  {termo.map((term, ind) => {
                    return (
                      <div style={{ display: "flex", gap: 2 }} key={ind}>
                        <input
                          type="checkbox"
                          readOnly
                          checked={
                            term.value === "inspecao"
                              ? tif["inspecao"]
                              : term.value === "fiscalizacao"
                              ? tif["fiscalizacao"]
                              : term.value === "notificacao"
                              ? tif["notificacao"]
                              : false
                          }
                        />
                        <label>{term.label}</label>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div>
                <div
                  style={{
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                    borderTop: "1px solid black",
                    backgroundColor: "#D9D9D9",
                  }}
                >
                  Tipo de Relação: {tif.tipoRepresentante}
                </div>
              </div>

              <div>
                <div
                  style={{
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  Nome/Razao social: {tif.nomeFiscalizado}
                </div>
              </div>

              <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                <div
                  style={{
                    borderRight: "1px solid black",
                    width: "40%",
                    paddingLeft: 5,
                  }}
                >
                  CPF/CNPJ: {cpfCnpjMask(tif.cpfCnpjFiscalizado)}
                </div>

                <div
                  style={{
                    borderRight: "1px solid black",
                    width: "20%",
                    paddingLeft: 5,
                  }}
                >
                  RG:{tif.inscricaoEstadualFiscalizado}
                </div>

                <div
                  style={{
                    width: "40%",
                    paddingLeft: 5,
                  }}
                >
                  RENASEM: {tif.renasemFiscalizado}
                </div>
              </div>

              <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                <div
                  style={{
                    width: "60%",
                    paddingLeft: 5,
                    borderRight: "1px solid black",
                  }}
                >
                  Email: {tif.emailFiscalizado}
                </div>

                <div
                  style={{
                    width: "40%",
                    paddingLeft: 5,
                  }}
                >
                  Telefone: {telefoneMask(tif.telefoneFiscalizado)}
                </div>
              </div>

              <div
                style={{
                  paddingLeft: 5,
                  borderBottom: "1px solid black",
                }}
              >
                Endereço: {tif.enderecoFiscalizado}
              </div>

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderRight: "1px solid black",
                    width: "50%",
                    paddingLeft: 5,
                  }}
                >
                  Municipio: {tif.municipioFiscalizado?.nome}
                </div>

                <div
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    paddingLeft: 5,
                  }}
                >
                  UF: {tif.municipioFiscalizado?.uf.sigla}
                </div>

                <div
                  style={{
                    width: "35%",
                    paddingLeft: 5,
                  }}
                >
                  CEP: {tif.cepFiscalizado}
                </div>
              </div>

              <TitleCSS>LOCAL DA FISCALIZAÇÃO</TitleCSS>

              <div style={{ borderBottom: "1px solid black" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "0px 20px",
                  }}
                >
                  {atuado.map((atuad, ind) => {
                    return (
                      <div style={{ display: "flex", gap: 2 }} key={ind}>
                        <input
                          type="checkbox"
                          readOnly
                          value={atuad.value}
                          checked={
                            atuad.value.toUpperCase() ===
                            tif.caracteristicaFiscalizado.toUpperCase()
                          }
                        />
                        <label>{atuad.label}</label>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  paddingLeft: 5,
                  borderBottom: "1px solid black",
                }}
              >
                Nome: {tif.nomePropriedade}
              </div>

              <div
                style={{
                  paddingLeft: 5,
                  borderBottom: "1px solid black",
                }}
              >
                Endereço: {tif.enderecoPropriedade}
              </div>

              <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                <div
                  style={{
                    borderRight: "1px solid black",
                    width: "50%",
                    paddingLeft: 5,
                  }}
                >
                  Municipio: {tif.municipioFiscalizacao?.nome}
                </div>

                <div
                  style={{
                    borderRight: "1px solid black",
                    width: "15%",
                    paddingLeft: 5,
                  }}
                >
                  UF: {tif.municipioFiscalizacao?.uf.sigla}
                </div>

                <div
                  style={{
                    width: "35%",
                    paddingLeft: 5,
                  }}
                >
                  CEP: {tif.cepPropriedade}
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 15,
                    paddingLeft: 5,
                  }}
                >
                  <span>Localização Geografica:</span>

                  <div
                    style={{
                      display: "flex",
                      gap: 5,
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>S</span>
                    <span>
                      {`${coordMask(
                        tif.latGrauPropriedade.toString()
                      )}°${coordMask(
                        tif.latMinPropriedade.toString()
                      )}'${coordMask(tif.latSegPropriedade.toString())}"`}
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: 5,
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>O</span>
                    <span>
                      {`${coordMask(
                        tif.longGrauPropriedade.toString()
                      )}°${coordMask(
                        tif.longMinPropriedade.toString()
                      )}'${coordMask(tif.longSegPropriedade.toString())}"`}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ minHeight: tif.servidores.length > 4 ? "none" : "35vh" }}
            >
              <div>
                <TitleCSS>CONSTATAÇÃO(ÕES)</TitleCSS>

                <div
                  style={{
                    padding: "5px",
                    textAlign: "justify",
                    minHeight: tif.servidores.length > 4 ? "none" : "20vh",
                  }}
                >
                  {tif.constatacao.includes("\n") ? (
                    tif.constatacao.split("\n").map((text, ind) => (
                      <p key={ind} style={{ textIndent: 40, marginBottom: 5 }}>
                        {text}
                      </p>
                    ))
                  ) : (
                    <span>{tif.constatacao}</span>
                  )}
                </div>
              </div>

              {tif.amparoLegal && (
                <div>
                  <TitleCSS>PROVIDÊNCIAS / AMPARO LEGAL / PRAZO</TitleCSS>

                  <div
                    style={{
                      padding: "5px",
                      textAlign: "justify",
                      display: "block",
                      minHeight: "5vh",
                    }}
                  >
                    {tif.amparoLegal.includes("\n") ? (
                      tif.amparoLegal.split("\n").map((text, ind) => (
                        <p
                          key={ind}
                          style={{ textIndent: 40, marginBottom: 5 }}
                        >
                          {text}
                        </p>
                      ))
                    ) : (
                      <span>{tif.amparoLegal}</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}

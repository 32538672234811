import { IFormAtividades } from "@interfaces/IFormAtividades";

export const organizarListaAtividade = (listaAtividades: IFormAtividades[]) => {
  //setando atividades
  const auxAtividades = listaAtividades.map((atv) => {
    const listaEspecificacoes = atv.especificacoes.split(", ");

    return {
      areaAtividade: {
        id: atv.programa.id,
        nome: atv.programa.value,
      },
      atividade: {
        id: atv.atividade.id,
        nome: atv.atividade.nome,
        questoes: atv.atividade.questoes,
      },
      coleta: listaEspecificacoes.some(
        (especificacao) => especificacao === "Coleta"
      ),
      denuncia: listaEspecificacoes.some(
        (especificacao) => especificacao === "Atendimento à denuncia"
      ),
      desinterdicao: listaEspecificacoes.some(
        (especificacao) => especificacao === "Desinterdição"
      ),
      interdicao: false,
      notificacao: listaEspecificacoes.some(
        (especificacao) => especificacao === "Notificação"
      ),
      prazoAdequacao: atv.prazo,
      id: atv.key,
      armadilha: atv.armadilha,
    };
  });

  return auxAtividades;
};

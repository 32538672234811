import { Button, Modal, Typography } from "antd";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import ReactSignatureCanvas from "react-signature-canvas";

interface IModalAssinaturaProps {
  isOpen: boolean;
  handleClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
  cleanSignature: (signature: ReactSignatureCanvas | null | undefined) => void;
  refAssinante: React.MutableRefObject<any>;
  nomeAssinante?: string;
}

export function ModalAssinatura({
  isOpen,
  handleClose,
  cleanSignature,
  refAssinante,
  nomeAssinante,
}: IModalAssinaturaProps) {
  return (
    <Modal
      title={
        <h1 style={{ textAlign: "center", fontSize: 21 }}>
          Assinar no campo abaixo
        </h1>
      }
      centered
      maskClosable={false}
      open={isOpen}
      width={1400}
      keyboard={true}
      onCancel={handleClose}
      footer={null}
    >
      <AssinaturaContainer>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            width: " 90%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              style={{ width: "48%" }}
              onClick={(e) => {
                cleanSignature(refAssinante.current);
              }}
            >
              Limpar
            </Button>

            <Button style={{ width: "48%" }} onClick={handleClose}>
              Voltar
            </Button>
          </div>
          <div style={{ width: "100%" }}>
            <ReactSignatureCanvas
              minWidth={2}
              penColor="black"
              ref={refAssinante}
              canvasProps={{
                className: "sigCanvas",
              }}
              clearOnResize={false}
            />

            <Typography style={{ textAlign: "center" }}>
              Assinatura {nomeAssinante || "Fiscalizado"}
            </Typography>
          </div>
        </div>
      </AssinaturaContainer>
    </Modal>
  );
}

import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  message,
  Progress,
  Select,
  Typography,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { apiService } from "@services/api";
import { openNotification } from "@components/Notifications";
import { TitleTermo } from "@styles/TitleTermo";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { TitleCardMunicipio } from "./components/TitleCardMunicipio";
import styles from "./style.module.css";
import moment from "moment";
import "moment/locale/pt-br";
import { useMunicipio } from "@hooks/useMunicipio";
import { usePropriedade } from "@hooks/usePropriedade";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { SyncDadosTermoApreensaoLiberacao } from "./components/SyncDadosTermoApreensaoLiberacao";
import { SyncDadosTermoColeta } from "./components/SyncDadosTermoColeta";
import { SyncDadosTif } from "./components/SyncDadosTif";
import { SyncDadosTermoVistoria } from "./components/SyncDadosTermoVistoria";
import { useArmadilha } from "@hooks/useArmadilha";

moment.locale("pt-br");
const LIMITE_DOWNLOAD_MUNICIPIOS = 3;

export function OfflineMode() {
  const {
    municipiosMt,
    adicionarMunicipiosSincronizados,
    timeExpireMunicipioSincronizados,
    municipioSincronizados,
  } = useMunicipio();
  const { listaPropriedadeSincronizadas, sincronizarPropriedades } =
    usePropriedade();
  const { listaEstabelecimentoSincronizados, sincronizarEstabelecimentos } =
    useEstabelecimento();
  const { adicionarArmadilhas, listaArmadilhasSincronizadas } = useArmadilha();

  const [formOffline] = Form.useForm();
  const [isDownloading, setIsDownloading] = useState(false);
  const [loadingUpdateMunicipio, setLoadingUpdateMunicipio] = useState({
    loading: false,
    municipioId: 0,
  });
  const [memorySpace, setMemorySpace] = useState(0);

  const handleSubmit = async ({ municipioId }: { municipioId: number }) => {
    const dateTimeCreate = Date.now();
    const errors: string[] = [];

    if (!navigator.onLine) {
      openNotification({
        type: "error",
        message: "Erro ao baixar informações",
        description:
          "Sem accesso a internet para baixar dados. Tente novamente mais tarde.",
      });
      return;
    }

    if (
      listaPropriedadeSincronizadas.some(
        (prop) => prop.municipioId === municipioId
      )
    ) {
      openNotification({
        type: "error",
        message: "Municipio já sincronizado",
      });

      return;
    }

    if (listaPropriedadeSincronizadas.length >= LIMITE_DOWNLOAD_MUNICIPIOS) {
      openNotification({
        type: "error",
        message: "Limite de municipios excedido",
      });

      return;
    }

    //COLOCANDO MUNICIPIO NA LISTA DE MUNICIPIOS BAIXADOS
    const municipiosBaixados = [
      ...municipioSincronizados,
      municipiosMt.filter((municipio) => municipio.id === municipioId)[0],
    ];
    adicionarMunicipiosSincronizados(municipiosBaixados, dateTimeCreate);

    //SINCRONIZANDO ARMADILHAS DE CADA MUNICIPIO
    const paramArmadilhas = new URLSearchParams();
    paramArmadilhas.append("codigoIbge", municipioId.toString());

    setIsDownloading(true);
    message.open({
      key: "downloadMunicipio",
      type: "loading",
      content: "Sincronizando dados do municipio...",
      duration: 0,
    });

    await Promise.allSettled([
      apiService.propriedades.byMunicipio(municipioId),
      apiService.estabelecimentos.byMunicipio(municipioId),
      apiService.armadilha(municipioId),
    ]).then((res) => {
      const error: string[] = [];

      if (res[0].status === "rejected") {
        error.push("Propriedades");
      } else {
        sincronizarPropriedades(
          {
            municipioId: municipioId,
            propriedades: res[0].value,
          },
          dateTimeCreate
        );
      }

      if (res[1].status === "rejected") {
        error.push("Estabelecimentos");
      } else {
        sincronizarEstabelecimentos(
          {
            municipioId: municipioId,
            estabelecimentos: res[1].value.data,
          },
          dateTimeCreate
        );
      }

      if (res[2].status === "fulfilled") {
        adicionarArmadilhas(res[2].value, municipioId, dateTimeCreate);
      }

      if (error.length > 0) {
        openNotification({
          type: "error",
          message: "Erro ao sincronizar informações",
          description: `Não foi possivel sincronizar as seguintes informações do municipio de ${
            municipiosMt.filter((municipio) => municipio.id === municipioId)[0]
              .nome
          }: ${errors.join(", ")}`,
        });
      } else {
        openNotification({
          type: "success",
          message: `Sincronização feita com sucesso!`,
          description: `Dados do municipio de ${
            municipiosMt.filter((municipio) => municipio.id === municipioId)[0]
              .nome
          } agora podem ser utilizados no modo offline`,
        });
      }
    });

    message.destroy("downloadMunicipio");
    setIsDownloading(false);

    //LIMPANDO CAMPOS DO FORM
    formOffline.resetFields();
  };

  useEffect(() => {
    navigator.storage
      .estimate()
      .then((estimate) => {
        if (!estimate.usage || !estimate.quota) return;
        // console.log(estimate);
        setMemorySpace(
          parseFloat(((estimate.usage / estimate.quota) * 100).toFixed(2)) || 0
        );
      })
      .catch((erro) => {
        console.log(erro);
      });
  }, []);

  return (
    <Fragment>
      <TitleTermo>Sincronizar Dados</TitleTermo>

      <Divider />

      <div style={{ paddingBottom: 20 }}>
        <Typography style={{ paddingBottom: "5px", opacity: 0.7 }}>
          Após 3 dias desde a ultima atualização os dados sincronizados serão
          apagados
        </Typography>
      </div>

      <div>
        <SyncDadosTif />
        <SyncDadosTermoColeta />
        <SyncDadosTermoApreensaoLiberacao />
        <SyncDadosTermoVistoria />

        <Divider />

        <Form
          initialValues={{ idUf: 51 }}
          onFinish={handleSubmit}
          labelWrap
          labelAlign="left"
          labelCol={{ flex: "80px", span: 10, offset: 4 }}
          wrapperCol={{ span: 14 }}
          form={formOffline}
          style={{ paddingBottom: 20 }}
        >
          <Form.Item
            label="Município"
            name="municipioId"
            rules={[
              {
                required: true,
                message:
                  "Por favor,especifique o Município a ser sincronizado ",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              allowClear
              className={styles["select-municipio"]}
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input) ||
                (option!.children as unknown as string).includes(
                  input.toUpperCase()
                )
              }
              placeholder="Selecione um Municípo para sincronizar os dados"
              disabled={isDownloading}
            >
              {municipiosMt.map((municipio) => (
                <Select.Option
                  key={municipio.id}
                  value={municipio.id}
                >{`${municipio.nome}-${municipio.uf.sigla}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <ButtonFooterContainer>
            <Button type="primary" htmlType="submit" disabled={isDownloading}>
              Baixar
            </Button>
          </ButtonFooterContainer>
        </Form>

        <Typography.Title level={4}>
          Municípios Sincronizados {municipioSincronizados.length}/
          {LIMITE_DOWNLOAD_MUNICIPIOS}
        </Typography.Title>

        {municipioSincronizados.length > 0 && (
          <Typography style={{ opacity: 0.7, paddingBottom: 20, fontSize: 13 }}>
            <strong>Atualizado em:</strong>{" "}
            {moment(timeExpireMunicipioSincronizados).format("llll")}
          </Typography>
        )}

        <div className={styles["container-card"]}>
          {municipioSincronizados.map((municipio, ind) => {
            const [propriedadesMunicipio] =
              listaPropriedadeSincronizadas.filter(
                (prop) => prop.municipioId === municipio.id
              );

            const [estabelecimentosMunicipio] =
              listaEstabelecimentoSincronizados.filter(
                (estab) => estab.municipioId === municipio.id
              );

            const armadilasMunicipio = listaArmadilhasSincronizadas.find(
              (arm) => arm.municipioId === municipio.id
            );

            return (
              <Card
                key={ind}
                title={
                  <TitleCardMunicipio
                    municipio={municipio}
                    isDownloading={isDownloading}
                    loadingUpdateMunicipio={loadingUpdateMunicipio}
                    setLoadingUpdateMunicipio={setLoadingUpdateMunicipio}
                  />
                }
                style={{
                  width: 500,
                  boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                }}
                bordered
              >
                <p>
                  propriedades...
                  {propriedadesMunicipio === undefined ||
                  (loadingUpdateMunicipio.loading &&
                    loadingUpdateMunicipio.municipioId === municipio.id) ? (
                    <LoadingOutlined />
                  ) : propriedadesMunicipio.error ? (
                    <CloseOutlined />
                  ) : (
                    <CheckOutlined />
                  )}
                </p>

                <p>
                  Estabelecimentos...
                  {estabelecimentosMunicipio === undefined ||
                  (loadingUpdateMunicipio.loading &&
                    loadingUpdateMunicipio.municipioId === municipio.id) ? (
                    <LoadingOutlined />
                  ) : estabelecimentosMunicipio.error ? (
                    <CloseOutlined />
                  ) : (
                    <CheckOutlined />
                  )}
                </p>

                <p>
                  Armadilhas...
                  {!armadilasMunicipio ||
                  (loadingUpdateMunicipio.loading &&
                    loadingUpdateMunicipio.municipioId === municipio.id) ? (
                    <LoadingOutlined />
                  ) : (
                    <CheckOutlined />
                  )}
                </p>
              </Card>
            );
          })}
        </div>

        <Divider>
          <Typography.Title level={4}>Uso do espaço em disco</Typography.Title>
        </Divider>
        <div className={styles["container-space-memory-used"]}>
          <Progress
            type="circle"
            percent={memorySpace}
            size="default"
            status="normal"
          />
        </div>
      </div>
    </Fragment>
  );
}

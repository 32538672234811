import {
  Checkbox,
  Divider,
  Form,
  List,
  Pagination,
  Spin,
  Typography,
  message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import styles from "./style.module.css";
import { openNotification } from "@components/Notifications";
import { checkDevice } from "@functions/checkDevice";
import { apiService } from "@services/api";
import { TitleTermo } from "@styles/TitleTermo";
import { TERMO_APREENSAO_LIBERACAO_OPTIONS_FILTER } from "./constants";
import { FiltroTermoApreensaoLiberacao } from "./components/FiltroTermoApreensaoLiberacao";
import { ItemListaTermoApreensaoLiberacao } from "./components/ItemListaTermoApreensaoLiberacao";
import { ItemListaFiltroTermoApreensaoLiberacao } from "./components/ItemListaFiltroTermoApreensaoLiberacao";
import { ButtonEnviarBackupTermoApreensaoLiberacao } from "./components/ButtonEnviarBackupTermoApreensaoLiberacao";
import { useActionsTAL } from "../hooks/useActionsTAL";

const PAGE_SIZE = 20;

export function ListagemTermoApreensaoLiberacao() {
  const [formFiltro] = Form.useForm();
  const { aplicarFiltro, removerFiltro, listaFiltroTermos, listaTermos } =
    useActionsTAL();

  const [pageControl, setPageControl] = useState(1);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [optionsFiltroSelected, setOptionsFiltroSelected] = useState<
    CheckboxOptionType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(PAGE_SIZE);

  /**FUNCTIONS */

  //PAGINAÇÃO DA LISTAGEM DE TERMOS
  const handleChangePagination = async (page: number, filtro?: boolean) => {
    const keyMessage = "tal-pagination";
    if (filtro && !navigator.onLine) return;

    // localStorage.setItem("termoApreensaoLiberacao:pageSelect", page.toString());

    setPageControl(page);

    if (filtro) {
      const params = new URLSearchParams(listaFiltroTermos[0].params);
      params.append("page", (page - 1).toString());
      params.append("size", PAGE_SIZE.toString());

      setLoading(true);
      message.loading({
        key: keyMessage,
        content: "Carregando termos...",
        duration: 0,
      });
      await apiService.termoApreensaoLiberacao
        .listar(params.toString())
        .then((data) => {
          aplicarFiltro([
            {
              ...listaFiltroTermos[0],
              termos: data.content,
            },
          ]);
        })
        .catch((erro) => console.log(erro));

      message.destroy(keyMessage);
      setLoading(false);
      return;
    }
    setMinIndex((page - 1) * PAGE_SIZE);
    setMaxIndex(page * PAGE_SIZE);
  };

  const handleChangeFilterSelect = async (values: CheckboxValueType[]) => {
    const keyMessage = "tal-reload-filter";
    const params = new URLSearchParams(listaFiltroTermos[0].params);
    const newParams = new URLSearchParams();
    formFiltro.resetFields();

    if (values.length === 0) {
      removerFiltro();
      openNotification({
        type: "info",
        message: "Filtro Removido",
      });

      handleChangePagination(1);

      return;
    }

    values.forEach((value: any) => {
      newParams.append(value, params.get(value)?.toString() || "");
      formFiltro.setFieldValue(value, params.get(value)?.toString());
    });

    newParams.append("size", PAGE_SIZE.toString());

    setCheckedList(values);
    setLoading(true);
    message.loading({
      key: keyMessage,
      content: "Carregando termos...",
      duration: 0,
    });
    await apiService.termoApreensaoLiberacao
      .listar(newParams.toString())
      .then((data) => {
        aplicarFiltro([
          {
            filtros: values,
            params: newParams.toString(),
            termos: data.content,
            numberPages: data.totalPages,
          },
        ]);

        setPageControl(1);
        message.destroy(keyMessage);
        setLoading(false);
        openNotification({
          type: "success",
          message: `Filtro Atualizado`,
        });
      })
      .catch((erro) => {
        console.log(erro);
        message.destroy(keyMessage);
        setLoading(false);
        openNotification({
          type: "error",
          message: `Não foi possivel buscar Termo(s) de Apreensão/Liberação.`,
          description: erro.response.data.detail,
        });
        // window.location.reload();
      });
  };

  useEffect(() => {
    if (listaFiltroTermos.length > 0) {
      setCheckedList(listaFiltroTermos[0].filtros);

      const optionsFiltro = TERMO_APREENSAO_LIBERACAO_OPTIONS_FILTER.filter(
        (option) => {
          if (listaFiltroTermos[0].filtros.includes(option.value)) {
            return option;
          }

          return null;
        }
      );

      setOptionsFiltroSelected(optionsFiltro);
    }

    //eslint-disable-next-line
  }, [listaFiltroTermos]);

  useEffect(() => {
    let pageSelected = pageControl;

    if (localStorage.getItem("termoApreensaoLiberacao:pageSelect")) {
      pageSelected = parseInt(
        localStorage.getItem("termoApreensaoLiberacao:pageSelect") || ""
      );

      localStorage.removeItem("termoApreensaoLiberacao:pageSelect");
    }

    handleChangePagination(pageSelected, listaFiltroTermos.length > 0);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <TitleTermo>Termo(s) de Apreensão/Liberação</TitleTermo>
      <div className={styles["container-button-listagem"]}>
        <FiltroTermoApreensaoLiberacao
          formFiltro={formFiltro}
          setPageControl={setPageControl}
        />

        <ButtonEnviarBackupTermoApreensaoLiberacao />
      </div>

      <Divider />

      {listaFiltroTermos.length > 0 ? (
        <>
          <div style={{ display: "flex", gap: 10, paddingBottom: 10 }}>
            <Typography>Filtro Aplicado:</Typography>
            <Checkbox.Group
              onChange={handleChangeFilterSelect}
              options={optionsFiltroSelected}
              value={checkedList}
              disabled={loading}
            />
          </div>

          {loading ? (
            <div className={styles["container-loading"]}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </div>
          ) : (
            <div className={styles["heigth-list"]}>
              <List
                style={{
                  boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                }}
                bordered
                dataSource={listaFiltroTermos[0].termos}
                renderItem={(item, ind) => {
                  return (
                    <ItemListaFiltroTermoApreensaoLiberacao
                      key={ind}
                      item={item}
                      pageControl={pageControl}
                    />
                  );
                }}
              />
            </div>
          )}
          <Pagination
            size={checkDevice() ? "small" : "default"}
            showSizeChanger={false}
            current={pageControl}
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
            pageSize={PAGE_SIZE}
            total={listaFiltroTermos[0].numberPages * PAGE_SIZE}
            onChange={(page) => handleChangePagination(page, true)}
          />
        </>
      ) : (
        <>
          <div className={styles["heigth-list"]}>
            <List
              style={{
                boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
              }}
              bordered
              dataSource={listaTermos.slice(minIndex, maxIndex)}
              renderItem={(item, ind) => {
                return (
                  <ItemListaTermoApreensaoLiberacao
                    key={ind}
                    termo={item}
                    pageControl={pageControl}
                  />
                );
              }}
            />
          </div>

          <Pagination
            size={checkDevice() ? "small" : "default"}
            showSizeChanger={false}
            current={pageControl}
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
            pageSize={PAGE_SIZE}
            total={listaTermos.length}
            onChange={(page) => handleChangePagination(page)}
          />
        </>
      )}
    </Fragment>
  );
}

import { useSelector } from "react-redux";
import { getPermissoes, getUser } from "@store/feature/userControllerSlice";
import { getPayloadUser } from "@functions/getPayloadUser";

export const usePermission = () => {
  const permissoes = useSelector(getPermissoes);
  const userController = useSelector(getUser);

  const isAllowed = (
    action: "visualizar" | "editar" | "excluir" | "log" | "adicionar",
    codigo:
      | "TIF"
      | "TermoColeta"
      | "TermoApreensaoLiberacao"
      | "TermoVistoria"
      | "TID"
      | "LASGAL"
  ) => {
    const permissao = permissoes?.find((perm) => perm.menu.codigo === codigo);

    if (!permissao) return;

    if (permissao[action]) {
      return true;
    }

    return false;
  };

  /**
   * especialRoutes sao rotas em que so é verificado se o usuario esta logado para poder acessa-las
   * pathnameControl é a rota que domina as subrotas com a finalidade de facilitar o controle de acesso
   * termoRoutes são as pathnameControls especificamente dos termos p/ facilitar acesso a rotas de edicao/adicao
   */
  const isRouteEnabled = (rota: string) => {
    const pathnameControl = rota.split("/")[0];
    const especialRoutes = ["offline-mode", "home", "LASGAL"];
    const termoRoutes = [
      "TIF",
      "termo-coleta",
      "termo-apreensao-liberacao",
      "termo-vistoria",
      "TID",
    ];
    const payloadUser = getPayloadUser(localStorage.getItem("access_token"));
    if (!payloadUser) {
      return false;
    }

    if (especialRoutes.includes(pathnameControl)) {
      return true;
    }

    const ADMIN_ID = 1;
    //TELA DE CONFIGURACAO SOMENTE PARA ADMIN NO TIF
    if (
      rota.includes("config") &&
      userController?.perfisId.includes(ADMIN_ID)
    ) {
      return true;
    }

    //PERMISSAO PARA CRIAR TERMO
    if (termoRoutes.includes(pathnameControl) && rota.includes("novo")) {
      return permissoes?.find((perm) => perm.menu.url.includes(pathnameControl))
        ?.adicionar;
    }

    //PERMISSAO PARA VISUALIZAR TERMO
    if (termoRoutes.includes(pathnameControl) && rota.includes("visualizar")) {
      return permissoes?.find((perm) => perm.menu.url.includes(pathnameControl))
        ?.visualizar;
    }

    //PERMISSAO PARA EDITAR TERMO
    if (termoRoutes.includes(pathnameControl) && rota.includes("assinatura")) {
      return permissoes?.find((perm) => perm.menu.url.includes(pathnameControl))
        ?.editar;
    }

    if (permissoes?.some((perm) => perm.menu.url.includes(pathnameControl))) {
      return true;
    }

    return false;
  };

  return { isAllowed, isRouteEnabled, permissoes, userController };
};

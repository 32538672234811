import { Layout, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import logo from "../../assets/logo.png";
import { MenuSystem } from "../MenuSystem";
import styles from "./style.module.css";
import { usePermission } from "@hooks/usePermission";

export function SideBar({ collapsed }: { collapsed: boolean }) {
  const { userController } = usePermission();

  return (
    <Layout.Sider
      className={styles.sideBar}
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={window.screen.width > 600 ? "100" : "0"}
      breakpoint={window.screen.width > 600 ? "lg" : "sm"}
    >
      <img
        src={logo}
        alt="Logo do indea"
        className={styles.logo}
        draggable="false"
        style={{
          transition: "margin-left 0.2s",
          marginLeft: collapsed ? "-6px" : "50px",
        }}
      />
      <Tooltip
        title={userController?.nome}
        destroyTooltipOnHide
        placement={collapsed ? "right" : "top"}
      >
        <div className={styles["content-name-user"]}>
          <UserOutlined /> {userController?.nome.split(" ")[0]}
        </div>
      </Tooltip>

      <MenuSystem isSidebar />
    </Layout.Sider>
  );
}

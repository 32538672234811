import React from "react";
import { HeaderFitoImpressao } from "./components/HeaderFitoImpressao";
import { BodyFitoImpressao } from "./components/BodyFitoImpressao";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { IFitoAmostrasPDF } from "@interfaces/IFitoAmostrasPDF";
import { FooterFitoImpressao } from "./components/FooterFitoImpressao";
import { FitoTabelaAmostrasImpressao } from "./components/FitoTabelaAmostrasImpressao";

interface IFitoImpressaoProps {
  termo: ITermoColetaAmostras;
  listaAmostras: IFitoAmostrasPDF[];
  marcaDagua: string;
}

export const FitoImpressao = React.forwardRef(
  (props: IFitoImpressaoProps, ref: any) => {
    return (
      <>
        <div ref={ref}>
          <div className="marcadagua">{props.marcaDagua}</div>
          <table
            style={{ width: "100vw" }}
            className="termo-coleta-impressao-font"
          >
            <HeaderFitoImpressao />

            <BodyFitoImpressao
              listaAmostras={props.listaAmostras.filter(
                (amostra, ind) => ind < 10
              )}
              termo={props.termo}
            />

            <FooterFitoImpressao termo={props.termo} />
          </table>

          {props.listaAmostras.length > 10 && (
            <>
              <table
                style={{ width: "100vw", pageBreakBefore: "always" }}
                className="termo-coleta-impressao-font"
              >
                <HeaderFitoImpressao />
                <FitoTabelaAmostrasImpressao
                  listaAmostras={props.listaAmostras.filter(
                    (amostra, ind) => ind >= 10
                  )}
                  termo={props.termo}
                />
                <FooterFitoImpressao termo={props.termo} />
              </table>
            </>
          )}
        </div>
      </>
    );
  }
);
